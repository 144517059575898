import { Box, Rating } from "@mui/material";
import React from "react";
import { IoIosMore } from "react-icons/io";
import { IoFlagOutline } from "react-icons/io5";
import { MdMoreHoriz } from "react-icons/md";
import TextInputField from "./Input/TextInput";
import ButtonComponent from "./Button";
import MenuComp from "./MenuComp";
import moment from "moment";
import { useSelector } from "react-redux";

const UserMenuOptions = [
  {
    lable: "Edit Review",
    onClick: () => {},
  },
  {
    lable: "Hide Review",
    onClick: () => {
      //   setResetPasswordModal(true);
    },
  },
];

const ReviewSection = ({ data, isRedFlag }) => {
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  return (
    <Box className="mb-3 w-full review">
      <Box className="flex justify-between items-center">
        <Box className="flex items-center mb-1">
          <Box className="flex items-end">
            <img
              src={data?.reviewBy?.profileUrl || "/images/dummy-user.jpg"}
              alt=""
              width={"50px"}
              height={"50px"}
              style={{ borderRadius: "50%" }}
            />
            {!isRedFlag && (
              <span
                className="m-0 p-1 rounded-full"
                style={{ marginLeft: "-15px", backgroundColor: "#FFD7D7" }}
              >
                <IoFlagOutline
                  className="w-4 h-4"
                  color="red"
                  fontWeight={"bold"}
                />
              </span>
            )}
          </Box>
          <Box className="ms-2 flex flex-col">
            <h1 className="mb-0 xl:text-lg md:text-md">{`${
              data?.reviewBy?.fname || ""
            } ${data?.reviewBy?.lname || ""}`}</h1>
            <Rating
              name="read-only"
              value={data?.star || 0}
              readOnly
              className="md:!text-lg xl:!text-2xl"
            />
            <h1 className="text-zinc-400 md:!text-xs xl:!text-sm">
              {data?.createdAt
                ? moment(data?.createdAt).format(
                    generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                  )
                : "-"}
            </h1>
          </Box>
        </Box>
        <Box
          className="cursor-pointer rounded-full"
          style={{ backgroundColor: "#F7F8FA" }}
        >
          {/* <IoIosMore
            className="border-0 rounded-full"
            style={{ fontSize: "18px" }}
          /> */}
          <MenuComp
            btn={<MdMoreHoriz className="sm:text-lg xl:text-xl" />}
            menuList={UserMenuOptions}
          />
        </Box>
      </Box>
      <Box>
        <p className="review-des">{data?.description || ""}</p>
        {!isRedFlag && (
          <>
            <TextInputField
              multiline={true}
              minRows={2}
              maxRows={5}
              // wrapperClassName="rounded-full"
              inputClassName="rounded-xl"
              fullWidth={true}
              sx={{
                backgroundColor: "#F7F8FA",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
            />

            <ButtonComponent
              btnText="Take Action"
              className="action-btn"
              style={{
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
                borderRadius: "10px",
                // padding: "6px 30px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReviewSection;
