import { Box, Divider, Grid, colors } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { MdMoreHoriz } from "react-icons/md";
import MenuComp from "../../../common/MenuComp";
// import CreateArticleModal from "./CreateArticleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppArticle,
  getAppArticles,
} from "../../../../store/settings/apparticle.slice";
import { toast } from "react-toastify";
import CreateNewTaxModal from "./CreateNewTaxModal";
import ButtonComponent from "../../../common/Button";
import { FaPlus } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import { MdDone } from "react-icons/md";
import { MdPercent } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import TextInputField from "../../../common/Input/TextInput";
import { useFormik } from "formik";
import {
  addCommissionDetails,
  addTaxCommission,
  deleteTax,
  getCommissions,
  getTaxCommissions,
} from "../../../../store/settings/taxcommission.slice";
import RemoveTaxConfirmation from "./RemoveTaxConfirmation";
import EditCommissionConfirmation from "./EditCommissionConfirmation";

const TaxCommission = () => {
  const [createTaxCommission, setCreateTaxCommission] = useState({
    showModal: false,
    id: undefined,
  });
  const [removeTaxConfirmation, setRemoveTaxConfirmation] = useState({
    showModal: false,
    id: undefined,
  });
  const [editCommissionConfirmation, setEditCommissionConfirmation] = useState({
    showModal: false,
    submitHandler: () => {},
  });
  const [editStates, setEditStates] = useState({
    editSeeker: false,
    editSharer: false,
    editSupply: false,
  });
  const [commissionRates, setCommissionRates] = useState({
    seekerCommission: 0,
    sharerCommission: 0,
    supplyCommission: 0,
  });
  const UserMenuOptions = [
    {
      lable: "Edit",
      onClick: (item) => {
        setCreateTaxCommission((prev) => ({
          showModal: !prev?.showModal,
          id: item?._id,
        }));
      },
    },
    {
      lable: "Disable",
      onClick: (item) => {
        dispatch(addTaxCommission({ _id: item?._id, status: "disabled" })).then(
          (data) => {
            if (data?.payload?.success) {
              toast.success(`Tax is Disabled Successfully !`);
              dispatch(getTaxCommissions());
              // formik.resetForm();
              // toggleHandler();
            } else {
              toast.error(data?.message);
            }
          }
        );
      },
    },
    {
      lable: "Enable",
      onClick: (item) => {
        dispatch(addTaxCommission({ _id: item?._id, status: "enabled" })).then(
          (data) => {
            if (data?.payload?.success) {
              toast.success(`Tax is Enabled Successfully !`);
              dispatch(getTaxCommissions());
              // formik.resetForm();
              // toggleHandler();
            } else {
              toast.error(data?.message);
            }
          }
        );
      },
    },
    {
      lable: "Remove",
      onClick: (item) => {
        setRemoveTaxConfirmation({ showModal: true, id: item._id });
      },
    },
  ];

  const dispatch = useDispatch();

  const { taxCommissions, commissions } = useSelector(
    (state) => state.settings.taxCommissions
  );
  console.log(commissions, "commissions");
  const toggleHandler = () => {
    setCreateTaxCommission((prev) => ({
      showModal: !prev?.showModal,
      id: undefined,
    }));
  };
  const toggleConfirmationModal = (id) => {
    setRemoveTaxConfirmation((prev) => ({
      showModal: !prev.showModal,
      id: id,
    }));
  };
  const toggleEditCommissionConfirmationModal = (id) => {
    setEditCommissionConfirmation((prev) => ({
      showModal: !prev.showModal,
      submitHandler: () => {},
    }));
  };

  const removeTaxHandler = () => {
    dispatch(deleteTax({ id: removeTaxConfirmation?.id })).then((res) => {
      if (res?.payload?.success) {
        toast.success("Tax Removed Successfully!");
        dispatch(getTaxCommissions());
        setRemoveTaxConfirmation({ showModal: false, id: undefined });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getTaxCommissions());
    dispatch(getCommissions()).then((res) => {
      if (res.payload?.success) {
        setCommissionRates((prev) => ({ ...prev, ...res?.payload?.data }));
      }
    });
  }, []);

  return (
    <>
      <Box className="p-4 text-start bg-white rounded-lg app-article-wraper">
        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
          Tax & Commissions
        </h1>
        <Box style={{ height: "600px", overflowY: "scroll" }}>
          <Box className="flex justify-between items-center mb-2 md:text-md xl:text-lg">
            <h3>Tax</h3>
            <ButtonComponent
              btnText="Create Tax Rate"
              startIcon={<FaPlus className="w-4 h-4" />}
              style={{
                // height: "33px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
              onClick={toggleHandler}
            />
          </Box>
          <Grid container spacing={2} className="mb-3">
            {taxCommissions?.map((tax, index) => {
              return (
                <Grid item md={12} xl={6} key={index}>
                  <Box
                    className="flex p-4 rounded-lg"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    {/* <Box className="flex items-center"> */}
                    {/* <span>
                        <img
                          src={
                            article?.articleImage || "/images/ArticlePic.png"
                          }
                          alt=""
                          className="app-article-img"
                        />
                      </span> */}
                    <Box className="ms-3 flex-1 text-medium flex-wrap">
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <p>Tax Name</p>
                        </Grid>
                        <Grid item xs={8}>
                          <p>{tax?.taxName}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>Tax Code</p>
                        </Grid>
                        <Grid item xs={8}>
                          <p className="flex items-center">{tax?.taxCode}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>Tax Rate</p>
                        </Grid>
                        <Grid item xs={8}>
                          <p>{tax?.taxRate} %</p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>Country</p>
                        </Grid>
                        <Grid item xs={8}>
                          <p>{tax?.country}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>States</p>
                        </Grid>
                        <Grid item xs={8}>
                          <p>{tax?.state?.join(",")}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* </Box> */}
                    <span>
                      <MenuComp
                        selectedItem={tax}
                        btn={<MdMoreHoriz className="text-xl" />}
                        menuList={UserMenuOptions?.filter((menu) => {
                          if (menu?.lable === "Disable") {
                            if (tax?.status === "enabled") {
                              return menu;
                            }
                          } else if (menu?.lable === "Enable") {
                            if (tax?.status === "disabled") {
                              return menu;
                            }
                          } else {
                            return menu;
                          }
                        })}
                        btnBackgroundColor="white"
                        menuBackgroundColor="white"
                      />
                    </span>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Divider />
          <Box className="flex justify-between items-center mb-2 md:text-md xl:text-lg mt-3">
            <h3>Commissions</h3>
          </Box>
          <Grid container className="mb-2">
            <Grid
              item
              sm={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Seeker Commission</h3>
            </Grid>
            <Grid item sm={4}>
              <Box className="flex items-center">
                <TextInputField
                  wrapperClassName="w-full"
                  name={`seekerCommission`}
                  // formik={formik}
                  value={commissionRates.seekerCommission}
                  onChange={(event) => {
                    setCommissionRates((prev) => ({
                      ...prev,
                      seekerCommission: +event.target.value,
                    }));
                    console.log(event.target.value, "details");
                  }}
                  inputClassName="rounded-xl text-medium"
                  fullWidth={true}
                  endAdornment={<MdPercent className="w-5 h-6" />}
                  disabled={!editStates?.editSeeker}
                  sx={{
                    backgroundColor: "#F7F8FA",
                  }}
                />
                {!editStates?.editSeeker && (
                  <Box
                    className={"p-2 rounded-md ms-2"}
                    style={{ backgroundColor: "#263238", color: "white" }}
                    onClick={() => {
                      setEditStates((prev) => ({
                        ...prev,
                        editSeeker: true,
                      }));
                    }}
                  >
                    <GoPencil className="w-4 h-4" />
                  </Box>
                )}
                {editStates?.editSeeker && (
                  <Box className="flex">
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#263238", color: "white" }}
                      onClick={() => {
                        setEditCommissionConfirmation({
                          showModal: true,
                          submitHandler: () => {
                            dispatch(
                              addCommissionDetails({
                                seekerCommission:
                                  commissionRates?.seekerCommission,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                setEditStates((prev) => ({
                                  ...prev,
                                  editSeeker: false,
                                }));
                                toggleEditCommissionConfirmationModal();
                                toast.success(
                                  "Seeker Commission Updated Successfully!"
                                );
                              }
                            });
                          },
                        });
                      }}
                    >
                      <MdDone className="w-4 h-4" />
                    </Box>
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#F7F8FA", color: "black" }}
                      onClick={() => {
                        setEditStates((prev) => ({
                          ...prev,
                          editSeeker: false,
                        }));
                      }}
                    >
                      <RxCross2 className="w-4 h-4" />
                    </Box>
                  </Box>

                  // <Box
                  //   className={"px-2 py-1 rounded-md ms-2"}
                  //   style={{ backgroundColor: "#263238", color: "white" }}
                  //   onClick={() => {
                  //     dispatch(
                  //       addCommissionDetails({
                  //         seekerCommission: commissionRates?.seekerCommission,
                  //       })
                  //     ).then((res) => {
                  //       if (res?.payload?.success) {
                  //         toast.success(
                  //           "Seeker Commission Updated Successfully!"
                  //         );
                  //       }
                  //     });
                  //   }}
                  // >
                  //   Save
                  // </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="mb-2">
            <Grid
              item
              sm={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Sharer Commission</h3>
            </Grid>
            <Grid item sm={4}>
              <Box className="flex items-center">
                <TextInputField
                  wrapperClassName="w-full"
                  name={`sharerCommission`}
                  // formik={formik}
                  value={commissionRates.sharerCommission}
                  onChange={(event) => {
                    setCommissionRates((prev) => ({
                      ...prev,
                      sharerCommission: +event.target.value,
                    }));
                    console.log(event.target.value, "details");
                  }}
                  inputClassName="rounded-xl text-medium"
                  fullWidth={true}
                  endAdornment={<MdPercent className="w-5 h-6" />}
                  disabled={!editStates?.editSharer}
                  sx={{
                    backgroundColor: "#F7F8FA",
                  }}
                />
                {!editStates?.editSharer && (
                  <Box
                    className={"p-2 rounded-md ms-2"}
                    style={{ backgroundColor: "#263238", color: "white" }}
                    onClick={() => {
                      setEditStates((prev) => ({
                        ...prev,
                        editSharer: true,
                      }));
                    }}
                  >
                    <GoPencil className="w-4 h-4" />
                  </Box>
                )}
                {editStates?.editSharer && (
                  <Box className="flex">
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#263238", color: "white" }}
                      onClick={() => {
                        setEditCommissionConfirmation({
                          showModal: true,
                          submitHandler: () => {
                            dispatch(
                              addCommissionDetails({
                                sharerCommission:
                                  commissionRates?.sharerCommission,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                setEditStates((prev) => ({
                                  ...prev,
                                  editSharer: false,
                                }));
                                toggleEditCommissionConfirmationModal();
                                toast.success(
                                  "Sharer Commission Updated Successfully!"
                                );
                              }
                            });
                          },
                        });
                      }}
                    >
                      <MdDone className="w-4 h-4" />
                    </Box>
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#F7F8FA", color: "black" }}
                      onClick={() => {
                        setEditStates((prev) => ({
                          ...prev,
                          editSharer: false,
                        }));
                      }}
                    >
                      <RxCross2 className="w-4 h-4" />
                    </Box>
                  </Box>

                  // <Box
                  //   className={"px-2 py-1 rounded-md ms-2"}
                  //   style={{ backgroundColor: "#263238", color: "white" }}
                  //   onClick={() => {
                  //     dispatch(
                  //       addCommissionDetails({
                  //         sharerCommission: commissionRates?.sharerCommission,
                  //       })
                  //     ).then((res) => {
                  //       if (res?.payload?.success) {
                  //         toast.success(
                  //           "Sharer Commission Updated Successfully!"
                  //         );
                  //       }
                  //     });
                  //   }}
                  // >
                  //   Save
                  // </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="mb-2">
            <Grid
              item
              sm={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Supplier Commission</h3>
            </Grid>
            <Grid item sm={4}>
              <Box className="flex items-center">
                <TextInputField
                  wrapperClassName="w-full"
                  name={`supplierCommission`}
                  // formik={formik}
                  value={commissionRates.supplyCommission}
                  onChange={(event) => {
                    setCommissionRates((prev) => ({
                      ...prev,
                      supplyCommission: +event.target.value,
                    }));
                    console.log(event.target.value, "details");
                  }}
                  inputClassName="rounded-xl text-medium"
                  fullWidth={true}
                  endAdornment={<MdPercent className="w-5 h-6" />}
                  disabled={!editStates?.editSupply}
                  sx={{
                    backgroundColor: "#F7F8FA",
                  }}
                />
                {!editStates?.editSupply && (
                  <Box
                    className={"p-2 rounded-md ms-2"}
                    style={{ backgroundColor: "#263238", color: "white" }}
                    onClick={() => {
                      setEditStates((prev) => ({
                        ...prev,
                        editSupply: true,
                      }));
                    }}
                  >
                    <GoPencil className="w-4 h-4" />
                  </Box>
                )}
                {editStates?.editSupply && (
                  <Box className="flex">
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#263238", color: "white" }}
                      onClick={() => {
                        setEditCommissionConfirmation({
                          showModal: true,
                          submitHandler: () => {
                            dispatch(
                              addCommissionDetails({
                                supplyCommission:
                                  commissionRates?.supplyCommission,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                setEditStates((prev) => ({
                                  ...prev,
                                  editSupply: false,
                                }));
                                toggleEditCommissionConfirmationModal();
                                toast.success(
                                  "Supply Commission Updated Successfully!"
                                );
                              }
                            });
                          },
                        });
                      }}
                    >
                      <MdDone className="w-4 h-4" />
                    </Box>
                    <Box
                      className={"p-2 rounded-md ms-2"}
                      style={{ backgroundColor: "#F7F8FA", color: "black" }}
                      onClick={() => {
                        setEditStates((prev) => ({
                          ...prev,
                          editSupply: false,
                        }));
                      }}
                    >
                      <RxCross2 className="w-4 h-4" />
                    </Box>
                  </Box>

                  // <Box
                  //   className={"px-2 py-1 rounded-md ms-2"}
                  //   style={{ backgroundColor: "#263238", color: "white" }}
                  //   onClick={() => {
                  //     dispatch(
                  //       addCommissionDetails({
                  //         supplyCommission: commissionRates?.supplyCommission,
                  //       })
                  //     ).then((res) => {
                  //       if (res?.payload?.success) {
                  //         toast.success(
                  //           "Supply Commission Updated Successfully!"
                  //         );
                  //       }
                  //     });
                  //   }}
                  // >
                  //   Save
                  // </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CreateNewTaxModal
        // width={1000}
        title={`${createTaxCommission.id ? "Edit" : "Create"} Tax Type`}
        open={createTaxCommission.showModal}
        id={createTaxCommission.id}
        toggleHandler={toggleHandler}
      />
      <RemoveTaxConfirmation
        open={removeTaxConfirmation?.showModal}
        toggleHandler={() => toggleConfirmationModal()}
        submitHandler={removeTaxHandler}
      />
      <EditCommissionConfirmation
        open={editCommissionConfirmation?.showModal}
        toggleHandler={() => toggleEditCommissionConfirmationModal()}
        submitHandler={editCommissionConfirmation.submitHandler}
      />
    </>
  );
};

export default TaxCommission;
