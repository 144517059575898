import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getAppArticles = createAsyncThunk(
  "settings/getAppArticles",
  async (params) => {
    const getData = async () => {
      return GET("/apparticle/get", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getAppArticleById = createAsyncThunk(
  "settings/getAppArticleById",
  async (params) => {
    const getData = async () => {
      return GET("/apparticle/getById", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addAppArticle = createAsyncThunk(
  "settings/addAppArticle",
  async (payload) => {
    const postData = async () => {
      return POST("/apparticle/add", payload).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
export const deleteAppArticle = createAsyncThunk(
  "settings/deleteAppArticle",
  async (payload) => {
    const postData = async () => {
      return POST("/apparticle/delete", payload).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

const appArticlesSlice = createSlice({
  name: "appArticles",
  initialState: {
    isLoading: false,
    appArticles: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getAppArticles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAppArticles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.appArticles = action.payload || [];
    });
    builder.addCase(getAppArticles.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default appArticlesSlice.reducer;
