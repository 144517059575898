import React from "react";
import SidebarWithHeader from "../Sidebar";

const Layout = ({ children }) => {
  //   const isMobile = useMediaQuery("(max-width:1024px)");

  return (
    <div className="layout">
      <div className="wrapper">
        <SidebarWithHeader children={children} />
      </div>
    </div>
  );
};

export default Layout;
