import { Box, colors } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { months } from "./helper";

const dataByMonthInitial = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
};
const dataByDayInitial = {
  Mon: 0,
  Tue: 0,
  Wed: 0,
  Thu: 0,
  Fri: 0,
  Sat: 0,
  Sun: 0,
};

const UserCountChart = ({ chartData, filter }) => {
  const [dataByDay, setDataByDay] = useState([]);
  const [dataByMonth, setDataByMonth] = useState([]);
  useEffect(() => {
    if (chartData) {
      console.log(filter, "filter");
      if (filter === "daily") {
        chartData?.userCountByDay?.forEach((d) => {
          dataByDayInitial[d.dayName] = d.userCount;
        });
        setDataByDay(Object.values(dataByDayInitial));
      } else {
        chartData?.userCountBymonth?.forEach((d) => {
          dataByMonthInitial[d.month] = d.userCount;
        });
        setDataByMonth(Object.values(dataByMonthInitial));
      }
    }
  }, [chartData, filter]);

  const series = [
    {
      data: filter === "daily" ? dataByDay || [] : dataByMonth || [],
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
      colors: ["#85ABBD"],
    },
    markers: {
      size: 7,
      colors: ["#85ABBD"],
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#F6CA30",
        opacity: 0.35,
      },
    },
    grid: {
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.1,
      },
    },
    xaxis: {
      categories: filter === "daily" ? Object.keys(dataByDayInitial) : months,
    },
  };
  return (
    <Box>
      <Chart options={options} series={series} type="line" height={250} />
    </Box>
  );
};

export default UserCountChart;
