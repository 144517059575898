import { Box } from "@mui/material";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

export const generateColumns = (dateFormat) => {
  return [
    {
      id: "acCode",
      numeric: false,
      disablePadding: true,
      label: "Ac Code",
    },
    {
      id: "fname",
      numeric: true,
      disablePadding: false,
      label: "Ac Name",
      renderCell: (item) => {
        return `${item.fname || ""} ${item.lname || ""}`;
      },
    },
    {
      id: "profiles",
      numeric: true,
      disablePadding: false,
      label: "Profiles",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <ul>
            {item?.isSeeker && <li>Seeker</li>}
            {item?.isSharer && <li>Sharer</li>}
            {item?.isSupplier && <li>Supplier</li>}
          </ul>
        );
      },
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      disableSorting: true,
    },
    {
      id: "state",
      numeric: true,
      disablePadding: false,
      label: "State",
    },
    {
      id: "country",
      numeric: true,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "address",
      numeric: true,
      disablePadding: false,
      label: "Address",
    },
    {
      id: "zipCode",
      numeric: true,
      disablePadding: false,
      label: "Zip Code",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: true,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Create Date",
      renderCell: (item) => {
        console.log(item, "row");
        return item?.createdAt
          ? moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY")
          : "-";
      },
    },
    {
      id: "lastLoginTime",
      numeric: true,
      disablePadding: false,
      label: "Last Login",
      renderCell: (item) => {
        console.log(item, "row");
        return item?.lastLoginTime
          ? moment(item?.lastLoginTime).format(dateFormat || "DD/MM/YYYY")
          : "";
      },
    },
    {
      id: "lastLoginIP",
      numeric: true,
      disablePadding: false,
      label: "Last Login IP",
      disableSorting: true,
    },
  ];
};

export const AccountData = [
  {
    id: 1,
    ac_code: "AC1001",
    ac_name: "Account 1",
    profiles: "Profile 1",
    status: "Active",
    state: "State 1",
    country: "Country 1",
    address: "Address 1",
    zip_code: "Zip Code 1",
    email: "Email 1",
  },
  {
    id: 2,
    ac_code: "AC1002",
    ac_name: "Account 1",
    profiles: "Profile 1",
    status: "Active",
    state: "State 1",
    country: "Country 1",
    address: "Address 1",
    zip_code: "Zip Code 1",
    email: "Email 1",
  },
];

const orderType = (orderDetails) => {
  if (orderDetails?.isCompletedBySharer) {
    return "Completed";
  } else if (orderDetails?.isSharerRejected) {
    return "Cancelled";
  } else if (
    orderDetails?.isSharerAccepted &&
    !orderDetails?.isStartedBySupplier
  ) {
    return "Upcoming";
  } else if (
    orderDetails?.isSharerAccepted &&
    orderDetails?.isStartedBySupplier &&
    !orderDetails?.isCompletedBySharer
  ) {
    return "Active";
  } else {
    return null;
  }
};

export const orderDetailsColumns = (tab, dateFormat) => {
  return [
    {
      label: "Order No.",
      field: "orderId",
    },
    {
      label: "Order Date",
      field: "createdAt",
      renderCell: (item) => {
        return (
          item?.createdAt &&
          moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY")
        );
      },
    },
    {
      label: "Order Type",
      field: "orderType",
      disableSorting: true,
      renderCell: (item) => {
        const type = orderType(item);
        return type ? (
          <span
            className="px-3 py-2 rounded-md"
            style={{ backgroundColor: "#FCF0C6" }}
          >
            {type}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      label: tab === "supplier" ? "Supply" : "Surface",
      field: tab === "supplier" ? "supply" : "surface",
      width: "250px",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={
                (tab === "supplier"
                  ? item?.supply?.businessProfile?.profileUrl
                  : item?.surface?.businessProfile?.profileUrl) ||
                "/images/Cups.png"
              }
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">
                {(tab === "supplier"
                  ? item?.supply?.name
                  : item?.surface?.name) || ""}
              </p>
              <p className="text-zinc-400 text-sm">
                {(tab === "supplier"
                  ? item?.supply?.businessProfile?.name
                  : item?.surface?.businessProfile?.name) || ""}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      label: tab === "sharer" ? "Seeker" : "Sharer",
      field: tab === "sharer" ? "seeker" : "sharer",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={
                (tab === "sharer"
                  ? item?.user?.profileUrl
                  : item?.sharerUser?.profileUrl) || "/images/Cups.png"
              }
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${
                (tab === "sharer"
                  ? item?.user?.fname
                  : item?.sharerUser?.fname) || ""
              } ${
                (tab === "sharer"
                  ? item?.user?.lname
                  : item?.sharerUser?.lname) || ""
              }`}</p>
              <p className="text-zinc-400 text-sm">
                {/* {item?.surface?.businessProfile?.name} */}
              </p>
            </Box>
          </Box>
        );
      },
    },

    {
      label: "Surface Period",
      field: "prepStartDate",
      // disableSorting: true,
      renderCell: (item) => {
        return (
          <Box>
            <p>
              From:{" "}
              <span>
                {item?.prepStartDate &&
                  moment(item?.prepStartDate).format("DD/MM/YYYY")}
              </span>
            </p>
            <p>
              To:{" "}
              <span>
                {item?.prepEndDate &&
                  moment(item?.prepEndDate).format("DD/MM/YYYY")}
              </span>
            </p>
          </Box>
        );
      },
    },
    {
      label: "Total",
      field: "paymentDetails.orderTotal",
      renderCell: (item) => {
        return `$${item?.paymentDetails?.orderTotal}`;
      },
    },
    {
      label: tab === "supplier" ? "Seeker" : "Supplier",
      field: tab === "supplier" ? "user.fname" : "supplier",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={
                (tab === "supplier"
                  ? item?.user?.profileUrl
                  : item?.supplyUser?.profileUrl) || "/images/dummy-user.jpg"
              }
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${
                (tab === "supplier"
                  ? item?.user?.fname
                  : item?.supplyUser?.fname) || ""
              } ${
                (tab === "supplier"
                  ? item?.user?.lname
                  : item?.supplyUser?.lname) || ""
              }`}</p>
              <p className="text-zinc-400 text-sm">
                {item?.supplier?.supplierCompany || ""}
              </p>
            </Box>
          </Box>
        );
      },
    },
    // {
    //   title: "Action",
    //   renderCell: (item) => (
    //     <div>
    //       <ButtonComponent
    //         btnText="Edit"
    //         btnClass={"btn-warning"}
    //         onClick={() => {}}
    //       />
    //       <ButtonComponent
    //         btnText="Delete"
    //         btnClass={"btn-danger"}
    //         onClick={() => {}}
    //       />
    //     </div>
    //   ),
    // },
  ];
};

export const orderDetailsData = [
  {
    id: 1,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    orderType: "Surface",
    surface: {
      surfaceName: "Paper Cups",
      surfaceCompany: "K Mart",
    },
    sharer: {
      sharerName: "Ben Parker",
      sharerCompany: "Coffee Shop",
    },
    surfacePeriod: {
      fromDate: "Oct 28, 2023",
      toDate: "Oct 28, 2023",
    },
    total: "100",
    supplier: {
      supplierName: "Jeff Anderson",
      supplierCompany: "The Print Shop",
    },
  },
  {
    id: 2,
    orderNo: 10224,
    orderDate: "Oct 28, 2023",
    orderType: "Surface",
    surface: {
      surfaceName: "Paper Cups",
      surfaceCompany: "K Mart",
    },
    sharer: {
      sharerName: "Ben Parker",
      sharerCompany: "Coffee Shop",
    },
    surfacePeriod: {
      fromDate: "Oct 28, 2023",
      toDate: "Oct 28, 2023",
    },
    total: "1000",
    supplier: {
      supplierName: "Jeff Anderson",
      supplierCompany: "The Print Shop",
    },
  },
];

export const ActivitySteps = [
  {
    id: 1,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "1 day ago",
    icon: <FaCalendarAlt />,
  },
  {
    id: 2,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "3 day ago",
    icon: <FaCalendarAlt />,
  },
  {
    id: 3,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "6 day ago",
    icon: <FaCalendarAlt />,
  },
  {
    id: 4,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "1 day ago",
    icon: <FaCalendarAlt />,
  },
  {
    id: 5,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "3 day ago",
    icon: <FaCalendarAlt />,
  },
  {
    id: 6,
    label: "Received order for the surface Paper Cups for 14 days in K Mart.",
    subLabel: "6 day ago",
    icon: <FaCalendarAlt />,
  },
];

export const statusOptions = [
  {
    value: "active",
    label: "Active",
    icon: <GoDotFill color="#00B812" className="w-5 h-5" />,
  },
  {
    value: "inactivce",
    label: "Inactive",
    icon: <GoDotFill color="red" className="w-5 h-5" />,
  },
];
