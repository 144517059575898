import React, { useEffect, useRef, useState } from "react";
import ModalComp from "../../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import { Categories } from "../helper";
import TextInputField from "../../../common/Input/TextInput";
import ButtonComponent from "../../../common/Button";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import SelectInput from "../../../common/Input/SelectInput";
import DateInput from "../../../common/Input/DateInput";
import { Form, Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppArticle,
  getAppArticleById,
  getAppArticles,
} from "../../../../store/settings/apparticle.slice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FaRegImage } from "react-icons/fa6";
import { uploadImage } from "../../../../store/common/common.slice";
import Loading from "../../../common/Loading";

const initialValue = {
  articleName: "",
  articleImage: "",
  orderNo: 0,
  status: "",
  articleLink: "",
  toDate: "",
  fromDate: "",
};

const CreateArticleModal = ({ open, id, toggleHandler, title, width }) => {
  const dispatch = useDispatch();
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const [initialValues, setInitialValues] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if (id) {
      dispatch(getAppArticleById({ id: id })).then((data) => {
        if (data?.payload) {
          const prefilData = {
            articleName: data?.payload?.articleName || "",
            articleImage: data?.payload?.articleImage || "",
            orderNo: data?.payload?.orderNo || 0,
            status: data?.payload?.status || "",
            articleLink: data?.payload?.articleLink || "",
            toDate: data?.payload?.toDate
              ? new Date(data?.payload?.toDate)
              : "",
            fromDate: data?.payload?.fromDate
              ? new Date(data?.payload?.fromDate)
              : "",
          };
          setInitialValues(prefilData);
        }
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [id]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      articleName: Yup.string().required("Enter Article Name"),
      orderNo: Yup.number()
        .required("Enter Order No")
        .positive("Enter Valid Number")
        .integer(),
      status: Yup.string().required("Select Status"),
      articleLink: Yup.string()
        .url("Enter Valid URL")
        .required("Enter Article Link"),
      toDate: Yup.date()
        .min(Yup.ref("fromDate"), "ToDate can't be less than FromDate")
        .required("Enter To Date"),
      fromDate: Yup.date().required("Enter From Date"),
    }),
    onSubmit: (values) => {
      const payload = values;
      if (id) {
        payload._id = id;
      }
      dispatch(addAppArticle(values)).then((data) => {
        console.log(data, "data in submit");
        if (data?.payload?.success) {
          toast.success(
            `App Article is ${id ? "Edited" : "Added"} Successfully !`
          );
          dispatch(getAppArticles());
          formik.resetForm();
          toggleHandler();
        } else {
          toast.error(data?.message);
        }
      });
    },
  });
  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={width}
      >
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <Box className="p-5">
              <Box
                // style={{ width: "100px", height: "100px" }}
                className="rounded-full flex items-center justify-center mb-5"
              >
                <Box>
                  {formik.values?.articleImage ? (
                    loading ? (
                      <Loading isLoading={loading} />
                    ) : (
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src={formik.values?.articleImage}
                        alt=""
                        className="rounded-lg"
                      />
                    )
                  ) : (
                    <Box
                      className="flex flex-col justify-center items-center"
                      style={{
                        width: "150px",
                        height: "150px",
                        backgroundColor: "#EEF9FF",
                      }}
                      onClick={() => imgRef.current.click()}
                    >
                      <FaRegImage className="w-7 h-7" />
                      <p>Add image</p>
                    </Box>
                  )}
                </Box>
                <input
                  type="file"
                  ref={imgRef}
                  hidden={true}
                  onChange={(e) => {
                    // const file = e.target.files[0];
                    setLoading(true);
                    const formData = new FormData();
                    formData.append("photo", e.target.files[0]);
                    dispatch(uploadImage(formData)).then((op) => {
                      setLoading(false);
                      const value = op?.payload?.url[0];
                      formik.setFieldValue("articleImage", value);
                    });
                    // const reader = new FileReader();
                    // reader.onload = () => {
                    //   // Set the image source to the file content
                    //   if (
                    //     imgPreviewRef.current &&
                    //     typeof reader.result === "string"
                    //   ) {
                    //     imgPreviewRef.current.src = reader.result;
                    //   }
                    // };
                    // reader.readAsDataURL(file);
                  }}
                />
                {formik.values?.articleImage && (
                  <Box
                    style={{ marginLeft: "-10px", height: "170px" }}
                    className="flex flex-col justify-between"
                  >
                    <p
                      className="cursor-pointer rounded-full"
                      style={{ backgroundColor: "#F6CA30" }}
                      onClick={() => imgRef.current.click()}
                    >
                      <GoPencil className="w-6 h-6 p-1" color="white" />
                    </p>
                    <p
                      className="cursor-pointer rounded-full"
                      style={{ backgroundColor: "#F3F4F6" }}
                      onClick={() => formik.setFieldValue("articleImage", "")}
                    >
                      <RiDeleteBinLine className="w-6 h-6 p-1" color="red" />
                    </p>
                  </Box>
                )}
              </Box>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box className="flex items-center">
                      <p>Name *</p>
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="articleName"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={true}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="flex items-center">
                      <p>Order No *</p>
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="orderNo"
                          type="number"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={false}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="flex items-center">
                      <p>Status *</p>
                      <span className="flex-1 ms-2">
                        <SelectInput
                          name={"status"}
                          formik={formik}
                          options={[
                            { label: "Active", value: "Active" },
                            { label: "Inactive", value: "Inactive" },
                          ]}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="flex items-center">
                      <p>Link *</p>
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="articleLink"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={false}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="flex items-center">
                      <p>From *</p>
                      <span className="flex-1 ms-2">
                        <DateInput name={"fromDate"} formik={formik} />
                        {/* <TextInputField
                      // wrapperClassName="rounded-full"
                      type="number"
                      inputClassName="rounded-xl"
                      fullWidth={true}
                      isShowError={false}
                      sx={{
                        backgroundColor: "#F7F8FA",
                      }}
                    /> */}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="flex items-center">
                      <p>To *</p>
                      <span className="flex-1 ms-2">
                        <DateInput name={"toDate"} formik={formik} />
                        {/* <TextInputField
                      // wrapperClassName="rounded-full"
                      type="number"
                      inputClassName="rounded-xl"
                      fullWidth={true}
                      isShowError={false}
                      sx={{
                        backgroundColor: "#F7F8FA",
                      }}
                    /> */}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="my-5 flex justify-center">
                      <ButtonComponent
                        btnText="Cancel"
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid black",
                          borderRadius: "10px",
                          color: "black",
                          "&:hover": { backgroundColor: "#FFFFFF" },
                          boxShadow: "none",
                          width: "125px",
                        }}
                        onClick={() => {
                          formik.resetForm();
                          toggleHandler();
                        }}
                      />
                      <ButtonComponent
                        btnText="Save"
                        btnType="submit"
                        style={{
                          backgroundColor: "#263238",
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "125px",
                          "&:hover": { backgroundColor: "#263238" },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        </Formik>
      </ModalComp>
    </div>
  );
};

export default CreateArticleModal;
