import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TbMailPlus } from "react-icons/tb";
import TextInputField from "../common/Input/TextInput";
import RadioInput from "../common/Input/RadioInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonComponent from "../common/Button";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteTemplateConfirmationModal from "./DeleteTemplateConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmailTemplate,
  deleteEmailTemplateById,
  getEmailTemplates,
} from "../../store/settings/emailTemplate.slice";
import { Form, Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const initialValues = {
  _id: undefined,
  title: "",
  status: "",
  subject: "",
  message: "",
};

const ConfigEmail = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [initialValue, setInitialValue] = useState(initialValues);
  const { emailTemplates } = useSelector(
    (state) => state.settings.emailTemplateInfo
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      status: Yup.string().required("Status is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values) => {
      console.log(values, "values in config mail");
      const payload = values;
      if (!payload._id) {
        delete payload._id;
      }
      dispatch(addEmailTemplate(values)).then((data) => {
        console.log(data, "data in submit");
        if (data?.payload?.success) {
          toast.success(
            `Email Template is ${
              payload._id ? "Edited" : "Added"
            } Successfully !`
          );
          dispatch(getEmailTemplates());
          formik.resetForm();
          setIsEditable(false);
          // setInitialValue(initialValues);
        } else {
          toast.error(data?.message);
        }
      });
    },
  });

  const deleteHandler = (id) => {
    dispatch(deleteEmailTemplateById({ id: id })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Email Template Deleted Successfully!");
        setConfirmationModal((prev) => !prev);
        dispatch(getEmailTemplates());
        formik.setValues(initialValues);
        // setInitialValue(initialValues);
      } else {
        toast.error(data?.payload?.error);
      }
    });
  };

  useEffect(() => {
    dispatch(getEmailTemplates());
  }, []);
  return (
    <>
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          <Box className="mt-3">
            <Grid container columnSpacing={2}>
              <Grid item xs={3}>
                <Box
                  className="p-4 text-start bg-white rounded-lg"
                  style={{ height: "100%" }}
                >
                  <Box className="flex justify-between mb-3">
                    <h1 className="md:text-lg xl:text-xl font-semibold">
                      Email Templates
                    </h1>
                    <span className="bg-black p-1 rounded-full">
                      <TbMailPlus
                        color="white"
                        className="md:w-5 md:h-5 xl:w-6 xl:h-6 cursor-pointer"
                        onClick={() => {
                          formik.setValues(initialValue);
                          // setInitialValue(initialValues);
                          setIsEditable(true);
                        }}
                      />
                    </span>
                  </Box>
                  <Box>
                    {emailTemplates?.map((temp) => {
                      return (
                        <h3
                          className="md:text-md xl:text-lg p-2 rounded-lg flex items-center justify-between mb-2 cursor-pointer"
                          style={{
                            backgroundColor:
                              formik?.values?._id === temp?._id
                                ? "#F9DD7B"
                                : "#FFFFFF",
                          }}
                          onClick={() => {
                            formik.setValues(temp);
                            // setInitialValue(temp);
                            setIsEditable(true);
                          }}
                        >
                          {temp?.title}
                          <span
                            onClick={(event) => {
                              event.stopPropagation();
                              formik.setFieldValue("_id", temp?._id);
                              setConfirmationModal((prev) => !prev);
                            }}
                          >
                            <RiDeleteBinLine className="md:w-5 md:h-5 xl:w-6 xl:h-6 cursor-pointer" />
                          </span>
                        </h3>
                      );
                    })}
                    {/* <h3
                  className="md:text-md xl:text-lg p-2 rounded-lg flex items-center justify-between"
                  style={{ backgroundColor: "#F9DD7B" }}
                >
                  Registration Confirmation
                  <span onClick={() => setConfirmationModal((prev) => !prev)}>
                    <RiDeleteBinLine className="md:w-5 md:h-5 xl:w-6 xl:h-6 cursor-pointer" />
                  </span>
                </h3>
                <h3 className="md:text-md xl:text-lg p-2 rounded-lg flex items-center justify-between">
                  Registration Confirmation
                  <span onClick={() => setConfirmationModal((prev) => !prev)}>
                    <RiDeleteBinLine className="md:w-5 md:h-5 xl:w-6 xl:h-6 cursor-pointer" />
                  </span>
                </h3>
                <h3 className="md:text-md xl:text-lg p-2 rounded-lg flex items-center justify-between">
                  Registration Confirmation{" "}
                  <span onClick={() => setConfirmationModal((prev) => !prev)}>
                    <RiDeleteBinLine className="md:w-5 md:h-5 xl:w-6 xl:h-6 cursor-pointer" />
                  </span>
                </h3> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box className="p-4 text-start bg-white rounded-lg">
                  <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                    Registration Confirmation
                  </h1>
                  <Box style={{ padding: "0px 70px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <p className="mb-1 text-medium">Title *</p>
                        <TextInputField
                          name="title"
                          formik={formik}
                          inputClassName="rounded-xl text-medium"
                          fullWidth={true}
                          disabled={!isEditable}
                          sx={{
                            backgroundColor: !isEditable
                              ? "#F7F8FA"
                              : "#FFFFFF",
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p className="mb-1 text-medium">Status *</p>
                        <RadioInput
                          name={"status"}
                          formik={formik}
                          isVertical={true}
                          isDisabled={!isEditable}
                          options={[
                            { label: "Active", value: "Active" },
                            { label: "Inactive", value: "Inactive" },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <p className="mb-1 text-medium">Subject *</p>
                        <TextInputField
                          name="subject"
                          formik={formik}
                          disabled={!isEditable}
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          sx={{
                            backgroundColor: !isEditable
                              ? "#F7F8FA"
                              : "#FFFFFF",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <p className="mb-1 text-medium">Message *</p>
                        <ReactQuill
                          name={"message"}
                          value={formik.values.message}
                          style={{
                            "& .ql-editor": {
                              height: "300px",
                              backgroundColor: "#F7F8FA !important",
                            },
                          }}
                          readOnly={!isEditable}
                          theme="snow"
                          onChange={(e) => formik.setFieldValue("message", e)}
                        />
                        <div className="h-2 text-xs text-red-500">
                          {formik.touched.message
                            ? formik.errors.message || formik.errors.message
                            : ""}
                        </div>
                        {/* <TextInputField
                    // wrapperClassName="rounded-full"
                    inputClassName="rounded-xl"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  /> */}
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonComponent
                          btnText="Cancel"
                          btnClass="text-black"
                          isDisabled={!isEditable}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() => {
                            setIsEditable(false);
                            formik.setValues(initialValues);
                            // setInitialValue(initialValues);
                          }}
                        />
                        <ButtonComponent
                          btnText="Save"
                          btnType="submit"
                          isDisabled={!isEditable}
                          style={{
                            backgroundColor: "#263238",
                            "&:hover": {
                              backgroundColor: "#263238",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
      <DeleteTemplateConfirmationModal
        open={confirmationModal}
        toggleHandler={() => setConfirmationModal((prev) => !prev)}
        submitHandler={() => {
          deleteHandler(formik.values._id);
        }}
      />
    </>
  );
};

export default ConfigEmail;
