import { Box, Checkbox, Grid } from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "../common/Button";
import { ReactComponent as AddUserIcon } from "../../assets/images/AddNewUser.svg";
import { FaPlus } from "react-icons/fa6";
import { PiPencilSimple } from "react-icons/pi";
import { MdMoreHoriz } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { FaRegSquarePlus, FaRegSquareMinus } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import MenuComp from "../common/MenuComp";

const UserMenuOptions = [
  {
    lable: "Disable User",
    onClick: () => {},
  },
  {
    lable: "Reset Password",
    onClick: () => {
      //   setResetPasswordModal(true);
    },
  },
];

const ManageAccess = () => {
  const [expaned, setExpaned] = useState(false);
  return (
    <Box className="mt-3 manage-access-wraper">
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <Box
            className="p-4 text-start bg-white rounded-lg"
            style={{ height: "100%" }}
          >
            <h1 className="md:text-lg xl:text-xl font-semibold md:mb-1 xl:mb-3">
              Users
            </h1>
            <Box className="flex md:flex-col xl:flex-row mb-3">
              <ButtonComponent
                btnText="Create Account"
                btnClass="!text-medium"
                startIcon={
                  <AddUserIcon className="md:w-5 md:h-5 xl:w-5 xl:h-5" />
                }
                style={{
                  // padding: "2px 20px",
                  //   color: "black",
                  backgroundColor: "#263238",
                  border: "1px solid black",
                  "&:hover": { backgroundColor: "#263238" },
                  boxShadow: "none",
                }}
                onClick={() => {}}
              />
              <ButtonComponent
                btnText="Create Role"
                startIcon={<FaPlus className="w-4 h-4" />}
                style={{
                  // padding: "2px 20px",
                  //   color: "black",
                  backgroundColor: "#263238",
                  border: "1px solid black",
                  "&:hover": { backgroundColor: "#263238" },
                  boxShadow: "none",
                }}
                onClick={() => {}}
              />
            </Box>
            <Box>
              <h4 className="mb-2 md:text-md xl:text-lg">Admin User 1</h4>
              <h4 className="mb-2 md:text-md xl:text-lg">Admin User 2</h4>
              <h4 className="mb-2 md:text-md xl:text-lg">Admin User 3</h4>
              <h4 className="mb-2 md:text-md xl:text-lg">Admin User 4</h4>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={9}>
              <Box
                style={{ width: "100%" }}
                className="p-4 text-start bg-white rounded-lg"
              >
                <Box className="flex justify-between mb-3">
                  <h1 className="md:text-lg xl:text-xl font-semibold">
                    User Info
                  </h1>
                  <Box className="flex">
                    <span className="bg-black text-white rounded-full px-2 flex items-center justify-center">
                      <PiPencilSimple className="md:w-4 md:h-4 xl:w-5 xl:h-5" />
                    </span>
                    <span className="ms-3">
                      <MenuComp
                        btn={<MdMoreHoriz className="text-xl" />}
                        menuList={UserMenuOptions}
                      />
                    </span>
                  </Box>
                </Box>

                <Box className="flex items-center mb-3">
                  {/* <Box className="flex">
                    <img
                      src="/images/dummy-user.jpg"
                      alt=""
                      className="me-3"
                    />
                    <span>
                      <PiPencilSimple className="w-5 h-5" />
                    </span>
                  </Box> */}

                  <Box
                    // style={{ width: "100px", height: "100px" }}
                    className="rounded-full flex items-center justify-center me-2"
                  >
                    <img
                      // style={{ width: "100px", height: "100px" }}
                      src="/images/dummy-user.jpg"
                      alt=""
                      className="rounded-full manege-access-profile"
                    />
                    <Box
                      style={{ marginLeft: "-15px" }}
                      className="flex flex-col justify-between"
                    >
                      <span
                        className="cursor-pointer rounded-full mb-10"
                        style={{ backgroundColor: "#F6CA30" }}
                      >
                        <GoPencil className="w-6 h-6 p-1" color="white" />
                      </span>
                      <span
                        className="cursor-pointer rounded-full"
                        style={{ backgroundColor: "#F3F4F6" }}
                      >
                        <RiDeleteBinLine className="w-6 h-6 p-1" color="red" />
                      </span>
                    </Box>
                  </Box>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={3}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          User Code
                        </h1>
                        <h1 className="text-medium">US1001</h1>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          Status
                        </h1>
                        <h1 className="text-medium flex items-center">
                          <GoDotFill className="w-5 h-5" color="#00B812" />
                          Active
                        </h1>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          State
                        </h1>
                        <h1 className="text-medium flex items-center">
                          Los Angeles
                        </h1>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          User Name
                        </h1>
                        <h1 className="text-medium flex items-center">
                          Admin user1
                        </h1>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          Email
                        </h1>
                        <h1 className="text-medium flex items-center text-wrap">
                          adminuser1@surfaceshare.com
                        </h1>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <h1 className="mb-1 text-zinc-500 text-medium">
                          Country
                        </h1>
                        <h1 className="text-medium flex items-center">USA</h1>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} xl={3}>
              <Box className="p-4 w-full text-start bg-white rounded-lg">
                <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                  Roles
                </h1>
                <Box>
                  <Box className="flex items-center text-medium">
                    <Checkbox />
                    <span className="ms-1">Super admin</span>
                  </Box>
                  <Box className="flex items-center text-medium">
                    <Checkbox />
                    <span className="ms-1">Admin 1</span>
                  </Box>
                  <Box className="flex items-center text-medium">
                    <Checkbox defaultChecked />
                    <span className="ms-1">Admin 2</span>
                  </Box>
                  <Box className="flex items-center text-medium">
                    <Checkbox defaultChecked />
                    <span className="ms-1">Admin 3</span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="p-4 text-start bg-white rounded-lg">
                <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                  User Privileges
                </h1>
                <Box
                  className="user-privileges"
                  // sx={{
                  //   display: "grid",
                  //   gridTemplateColumns: "repeat(5,1fr)",
                  //   columnGap: 3,
                  // }}
                >
                  <Box
                    className="p-5 rounded-md"
                    style={{ backgroundColor: "#F7F8FA", height: "100%" }}
                  >
                    <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                      Dashboard
                    </h1>
                    <Box
                      style={{
                        backgroundImage:
                          "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                        backgroundPosition: "left",
                        backgroundSize: "1px 10px",
                        backgroundRepeat: "repeat-y",
                      }}
                    >
                      <p className="mb-3 text-medium">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 1
                      </p>
                      <p className="mb-3 text-medium">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 2
                      </p>
                      <p className="mb-3 text-medium">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 3
                      </p>
                      <p className="mb-3 text-medium">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 4
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="p-4 rounded-md"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                      Accounts
                    </h1>
                    <Box
                      className="text-medium"
                      style={{
                        backgroundImage:
                          "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                        backgroundPosition: "left",
                        backgroundSize: "1px 10px",
                        backgroundRepeat: "repeat-y",
                      }}
                    >
                      <p
                        className={`flex items-center text-medium ${
                          !expaned ? "mb-3" : ""
                        }`}
                      >
                        <span>-</span>
                        <span style={{ padding: "2px" }}>
                          {!expaned ? (
                            <FaRegSquarePlus
                              color="#636364"
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => setExpaned(true)}
                            />
                          ) : (
                            <FaRegSquareMinus
                              color="#636364"
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => setExpaned(false)}
                            />
                          )}
                        </span>
                        Table View
                      </p>
                      {expaned && (
                        <Box
                          style={{
                            backgroundImage:
                              "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                            backgroundPosition: "left 20px bottom 20px",
                            backgroundSize: "1px 10px",
                            backgroundRepeat: "repeat-y",
                            paddingLeft: "20px",
                          }}
                        >
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            Widget 1
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            Edit Profile
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            Change Status
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            View Orders
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            View Usage
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            View Activity
                          </p>
                          <p className="mb-3">
                            <span>-</span>
                            <Checkbox style={{ padding: "0px" }} />
                            View Reviews
                          </p>
                        </Box>
                      )}
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Edit Profile
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Change Status
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Orders
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Usage
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Activity
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Reviews
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="p-4 rounded-md"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                      Surfaces
                    </h1>
                    <Box
                      className="text-medium"
                      style={{
                        backgroundImage:
                          "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                        backgroundPosition: "left",
                        backgroundSize: "1px 10px",
                        backgroundRepeat: "repeat-y",
                      }}
                    >
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 1
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Edit Profile
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Change Status
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Orders
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Usage
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Activity
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Reviews
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="p-4 rounded-md"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                      Reservations
                    </h1>
                    <Box
                      className="text-medium"
                      style={{
                        backgroundImage:
                          "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                        backgroundPosition: "left",
                        backgroundSize: "1px 10px",
                        backgroundRepeat: "repeat-y",
                      }}
                    >
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 1
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Edit Profile
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Change Status
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Orders
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Usage
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Activity
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Reviews
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="p-4 rounded-md"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                      Settings
                    </h1>
                    <Box
                      className="text-medium"
                      style={{
                        backgroundImage:
                          "linear-gradient(black 50%, rgba(255,255,255,0) 0%)",
                        backgroundPosition: "left",
                        backgroundSize: "1px 10px",
                        backgroundRepeat: "repeat-y",
                      }}
                    >
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Widget 1
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Edit Profile
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        Change Status
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Orders
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Usage
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Activity
                      </p>
                      <p className="mb-3">
                        <span>-</span>
                        <Checkbox style={{ padding: "0px" }} />
                        View Reviews
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAccess;
