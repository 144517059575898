import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { generateColumns } from "./helper";
import SearchInput from "../common/Input/TextInput";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import ButtonComponent from "../common/Button";
import BreadCrumb from "../common/BreadCrumb";
import AddNewAccountModal from "./AddNewAccountModal";
import TableContainerComp from "../common/Table/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../store/account/account.slice";

const Account = () => {
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = generateColumns(
    generalInfoDetails?.dateFormat || "DD/MM/YYYY"
  );
  const dispatch = useDispatch();
  const {
    accounts: { data: AccountData, total_data, unverifiedBusinessCount },
    isLoading,
  } = useSelector((state) => state.account);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [searchState, setSearchState] = useState("");
  const [sorting, setSorting] = useState({
    order: "desc",
    orderBy: "createdAt",
  });
  const [addAccountModal, setAddAccountModal] = React.useState(false);

  const getAccountList = (details) => {
    dispatch(
      getAccounts({
        page: details?.page || pagination.page,
        limit: details?.pageSize || pagination.pageSize,
        sortby: details?.orderBy || sorting.orderBy,
        sorttype: details?.order || sorting.order,
        q: details?.q || "",
      })
    );
  };

  const toggleHandler = () => setAddAccountModal((prev) => !prev);

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getAccountList({ page, pageSize });
  };
  const sortingHandler = (orderByCol) => {
    console.log(orderByCol, sorting, "orderBy");
    const isAsc = sorting?.orderBy === orderByCol && sorting?.order === "asc";
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
    getAccountList({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
  };
  const searchHandler = (e) => {
    console.log(e, "value");
    getAccountList({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  useEffect(() => {
    getAccountList();
  }, []);
  console.log(AccountData, "accounts");
  return (
    <>
      {/* <h1 className="text-2xl font-bold text-start mb-5 mx-2">
        Accounts Management
      </h1> */}
      <BreadCrumb title="Accounts Management" />
      <Box className="text-start flex justify-between mx-2 mb-3">
        <Box className="flex items-center">
          <SearchInput
            placeholder="Search by text"
            isShowError={false}
            value={searchState}
            onChange={searchHandler}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <CiSearch />
                </IconButton>
              </InputAdornment>
            }
            inputClassName="rounded-lg"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                border: 0,
              },
            }}
          />
          <Box
            className="ms-4 rounded-md text-md"
            style={{ backgroundColor: "#FCF0C6", border: "1px solid black" }}
          >
            <p className="px-5 py-1 text-medium">
              Verification required{" "}
              <span className="font-semibold">
                {unverifiedBusinessCount || "0"}
              </span>
            </p>
          </Box>
        </Box>
        <Box className="flex items-center">
          {/* <Box className="mx-2">
            <ButtonComponent
              btnText="Create Account"
              startIcon={<FaPlus className="w-4 h-4" />}
              style={{
                // height: "33px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
              onClick={toggleHandler}
            />
          </Box> */}
          {/* <Box className="mx-2">
            <ButtonComponent
              btnText="Clear Filter"
              onClick={() => {
                searchHandler();
              }}
              style={{
                // height: "33px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
            />
          </Box> */}
          <p className="text-md text-zinc-400 mx-2">
            Total Users : <span className="text-black">{total_data}</span>
          </p>
        </Box>
      </Box>
      <Box className="mx-2">
        <TableContainerComp
          columns={columns}
          rows={AccountData}
          pagination={true}
          isLoading={isLoading}
          paginationDetails={{
            ...pagination,
            total_data,
            pageHandler: pageHandler,
          }}
          sortingDetails={sorting}
          sortingHandler={sortingHandler}
          hasLink={true}
          navigateURL={"/account/profile"}
        />
      </Box>
      <AddNewAccountModal
        open={addAccountModal}
        toggleHandler={toggleHandler}
        title={"Create Account"}
        getAccountList={getAccountList}
      />
    </>
  );
};

export default Account;
