import * as React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { GoDotFill } from "react-icons/go";
import InputBase from "@mui/material/InputBase";
import Select from "react-select";
import { get } from "lodash";

export default function SelectInput({
  isShowError = true,
  errorMessage,
  isMulti = false,
  name,
  formik,
  defaultValue,
  className,
  options,
  disabled = false,
  hasIcon = false,
  isSearchable = false,
  ...props
}) {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const { values, touched, errors, setFieldValue, handleBlur } = formik || {};
  const isError =
    !disabled && !!get(touched, name, "") && !!get(errors, name, "");
  return (
    <div>
      <Select
        defaultValue={defaultValue || ""}
        isMulti={isMulti}
        value={
          values
            ? options?.find((op) => op.value === values[name])
            : { label: "", value: "" }
        }
        options={options}
        // isSearchable={false}
        className={"text-medium" + className}
        onChange={(e) => setFieldValue(name, e.value)}
        isDisabled={disabled}
        isSearchable={isSearchable}
        // components={
        //   hasIcon
        //     ? {
        //         Option: CustomSelectOption,
        //         SingleValue: CustomSelectValue,
        //         Input: ({ children, selectProps, innerRef, innerProps }) =>
        //           null,
        //       }
        //     : {}
        // }
        styles={{
          option: (styles) => ({
            ...styles,
            ...props?.optionStyle,
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: "#F7F8FA",
            height: "40px",
            ...props?.valueStyle,
          }),
        }}
        {...props}
      />
      {isShowError && (isError || !!errorMessage) && (
        <div className="h-2 text-xs text-red-500">
          {get(touched, name, "") ? get(errors, name, "") || errorMessage : ""}
        </div>
      )}
    </div>
  );
}
