import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DELETE, GET, POST } from "../../services/methods";

export const getTaxCommissions = createAsyncThunk(
  "settings/getTaxCommissions",
  async (params) => {
    const getData = async () => {
      return GET("/taxcommission/get", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getTaxCommissionById = createAsyncThunk(
  "settings/getAppArticleById",
  async (params) => {
    const getData = async () => {
      return GET("/taxcommission/getById", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addTaxCommission = createAsyncThunk(
  "settings/addTaxCommission",
  async (payload) => {
    const postData = async () => {
      return POST("/taxcommission/add", payload).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
export const deleteTax = createAsyncThunk(
  "settings/deleteTax",
  async (payload) => {
    const deleteData = async () => {
      console.log(payload, "payload in delete");
      return DELETE(`/taxcommission/deleteById?id=${payload?.id || ""}`).then(
        (res) => {
          console.log(res, "response");
          return res;
        }
      );
    };
    try {
      return await deleteData();
    } catch (error) {
      return [];
    }
  }
);
export const addCommissionDetails = createAsyncThunk(
  "settings/commissionDetails",
  async (payload) => {
    const postData = async () => {
      console.log(payload, "payload in delete");
      return POST("/taxcommission/commissionDetails", payload).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

export const getCommissions = createAsyncThunk(
  "settings/getCommissions",
  async () => {
    const getData = async () => {
      return GET("/taxcommission/getCommissionDetails").then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

const taxCommissionsSlice = createSlice({
  name: "taxCommissions",
  initialState: {
    isLoading: false,
    taxCommissions: [],
    commissions: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getTaxCommissions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTaxCommissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.taxCommissions = action.payload || [];
    });
    builder.addCase(getTaxCommissions.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getCommissions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCommissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.commissions = action.payload.data || [];
    });
    builder.addCase(getCommissions.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default taxCommissionsSlice.reducer;
