import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getSupportTickets = createAsyncThunk(
  "settings/getSupportTickets",
  async (params) => {
    const getData = async () => {
      return GET(`/st/admin/get`, { params }).then((res) => {
        if (res.success) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

export const updateSupportTicket = createAsyncThunk(
  "settings/updateSupportTicket",
  async (payload) => {
    const postData = async () => {
      return POST(`/st/create`, payload).then((res) => {
        if (res.success) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

export const getHelpSupport = createAsyncThunk(
  "settings/getHelpSupport",
  async (params) => {
    const getData = async () => {
      return GET(`/faq/get?faqType=${params?.faqType}`).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addFAQ = createAsyncThunk("settings/addFAQ", async (payload) => {
  const postData = async () => {
    return POST(`/faq/create`, payload).then((res) => {
      if (res.success) {
        return res;
      } else {
        return res;
      }
    });
  };
  try {
    return await postData();
  } catch (error) {
    return error;
  }
});
export const deleteFAQ = createAsyncThunk(
  "settings/deleteFAQ",
  async (params) => {
    const postData = async () => {
      return POST(`/faq/delete/${params?.id}`).then((res) => {
        if (res.success) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

const helpSupportSlice = createSlice({
  name: "helpSupport",
  initialState: {
    isLoading: false,
    helpSupportTickets: [],
    supportTickets: {},
    supportTicketCounts: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getHelpSupport.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getHelpSupport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.helpSupportTickets = action.payload || [];
    });
    builder.addCase(getHelpSupport.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getSupportTickets.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSupportTickets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supportTickets = action.payload.data || [];
      state.supportTicketCounts = action.payload.count || [];
    });
    builder.addCase(getSupportTickets.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default helpSupportSlice.reducer;
