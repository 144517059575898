import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { TbMailPlus } from "react-icons/tb";
import TextInputField from "../../common/Input/TextInput";
import RadioInput from "../../common/Input/RadioInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonComponent from "../../common/Button";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPlusCircle } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { ReactComponent as PaperCup } from "../../../assets/images/PaperCups.svg";
import DeleteTemplateConfirmationModal from "../DeleteTemplateConfirmationModal";
import CreateCategoryModal from "./Category/CreateCategoryModal";
import Category from "./Category/index";
import AppArticles from "./AppArticles";
import TermsCondition from "./TermsCondition";
import CancellationTerms from "./CancellationTerms";
import TaxCommission from "./TaxCommission";

const AppSetting = () => {
  const [activeComponent, setActiveComponent] = useState("category");
  const renderComponent = (component) => {
    switch (component) {
      case "category":
        return <Category />;
      case "taxCommissions":
        return <TaxCommission />;
      case "appArticles":
        return <AppArticles />;
      case "termsCondition":
        return <TermsCondition />;
      case "cancelReport":
        return <CancellationTerms />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <Box className="mt-3">
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <Box
              className="p-4 text-start bg-white rounded-lg"
              style={{ height: "100%" }}
            >
              <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                App Settings
              </h1>
              <Box>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "category" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("category")}
                >
                  Categories
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "taxCommissions"
                        ? "#F9DD7B"
                        : "white",
                  }}
                  onClick={() => setActiveComponent("taxCommissions")}
                >
                  Tax & Commissions
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "appArticles" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("appArticles")}
                >
                  App Articles
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "termsCondition"
                        ? "#F9DD7B"
                        : "white",
                  }}
                  onClick={() => setActiveComponent("termsCondition")}
                >
                  Terms & Conditions
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "cancelReport" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("cancelReport")}
                >
                  Cancellation & Reporting
                </h3>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9}>
            {renderComponent(activeComponent)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AppSetting;
