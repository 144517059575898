import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { PiPencilSimpleBold } from "react-icons/pi";
import { ReactComponent as PaperCup } from "../../../../assets/images/PaperCups.svg";
import CreateTermsConditionModal from "./CreateTermsConditionModal";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../../../../store/settings/rulesReporting";
// import CreateCategoryModal from "./CreateCategoryModal";
const TermsConditionCard = ({ data, toggleHandler }) => {
  console.log(data, "data");
  return (
    <Box
      className="p-2 rounded-md mb-2"
      sx={{ backgroundColor: "#F7F8FA", height: "168px" }}
    >
      <Box className="flex justify-between">
        <h1 className="md:text-md xl:text-lg">{data?.title}</h1>
        <span
          className="cursor-pointer bg-black rounded-full md:p-1 xl:p-2"
          onClick={toggleHandler}
        >
          {/* <GoPencil className="w-5 h-5" color="white" /> */}
          <PiPencilSimpleBold
            className="md:w-5 md:h-5 xl:w-6 xl:h-6"
            color="white"
          />
        </span>
      </Box>
      <Box
        className="p-2 bg-white m-3"
        dangerouslySetInnerHTML={{ __html: data?.discription }}

        // value={data?.discription}
      ></Box>
    </Box>
  );
};

const TermsCondition = () => {
  const dispatch = useDispatch();
  const { termsAndConditions } = useSelector(
    (state) => state.settings.rulesAndReportingInfo
  );

  const [createModal, setCreateModal] = useState({
    showModal: false,
    id: undefined,
  });

  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);

  return (
    <>
      <Box className="p-4 text-start bg-white rounded-lg">
        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
          TermsCondition
        </h1>
        <Grid container spacing={3}>
          {termsAndConditions?.map((condition) => {
            return (
              <Grid item xs={12} xl={6}>
                <TermsConditionCard
                  data={{
                    title: condition?.termName,
                    discription: condition?.termDesciption,
                  }}
                  toggleHandler={() =>
                    setCreateModal((prev) => ({
                      ...prev,
                      showModal: !prev?.showModal,
                      id: condition?._id,
                    }))
                  }
                />
              </Grid>
            );
          })}
          <Grid item xs={12} xl={6}>
            <Box
              className="flex p-4 rounded-lg"
              style={{ backgroundColor: "#F7F8FA" }}
              onClick={() =>
                setCreateModal((prev) => ({
                  showModal: !prev?.showModal,
                  id: undefined,
                }))
              }
            >
              <Box className="flex items-center">
                <span>
                  <img src="/images/NewArticlePic.png" alt="" />
                </span>
                <Box className="ms-4 flex-1">
                  <p className="text-xl">Add T&C</p>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
            <TermsConditionCard
              data={{ title: "Website Terms & Policy" }}
              toggleHandler={() => setCreateModal((prev) => !prev)}
            />
          </Grid> */}
        </Grid>
      </Box>
      <CreateTermsConditionModal
        // width={1000}
        title={createModal?.id ? "Edit" : "Add"}
        open={createModal.showModal}
        id={createModal?.id}
        toggleHandler={() =>
          setCreateModal({ showModal: false, id: undefined })
        }
      />
    </>
  );
};

export default TermsCondition;
