import React, { useEffect, useState } from "react";
// import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, useFormik } from "formik";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import TextInputField from "../common/Input/TextInput";
import background from "../../assets/images/LoginPageImage.png";
import { loginUser, userDetails } from "../../store/auth/auth.slice";
import { toast } from "react-toastify";
// import { login, loginWithToken } from "../../store/login/login.slice";
// import TextInputField from "../../common/inputFields/TextInputField";
// import { toastHandler } from "../../common/toast";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   if (token) {
  //     dispatch(userDetails()).then((res) => {
  //       if (res) {
  //         if (res?.payload?._id) {
  //           navigate("/");
  //         } else {
  //           navigate("/login");
  //         }
  //       }
  //       // setIsLoading(false);
  //     });
  //   }
  // }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    // validationSchema: yup.object().shape({
    //   email: yup.string().email().required("Email is required"),
    //   password: yup.string().required("Password is required"),
    // }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        loginUser({ email: values.email, password: values.password })
      ).then((res) => {
        console.log(res, "response");
        if (res?.payload?.success) {
          toast.success("Logged in successfully");
          localStorage.setItem("surfaceShareToken", res?.payload?.data?.token);
          navigate("/");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex" style={{ height: "100vh" }}>
        {/* <Paper elevation={4} className="p-6 !max-w-md !w-full !rounded-2xl"> */}
        <div
          className="flex-1"
          style={{
            background: `url(${background}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          {/* <img
          src={background}
          alt=""
          style={{ width: "auto", height: "auto" }}
        /> */}
        </div>
        <div className="flex-1 flex justify-center items-center">
          <div className="flex flex-col w-3/6">
            <h1 className="text-4xl font-semibold text-start md:mb-3 xl:mb-4">
              Login
            </h1>
            <div className="md:mb-3 xl:mb-4">
              <TextInputField
                name="email"
                formik={formik}
                placeholder="Username"
                inputClassName="rounded-2xl text-medium"
                wrapperClassName="md:mb-2 xl:mb-3"
                fullWidth={true}
                sx={{
                  backgroundColor: "#F7F8FA",
                }}
              />
              <TextInputField
                name="password"
                formik={formik}
                placeholder="Password"
                inputClassName="rounded-xl text-medium"
                fullWidth={true}
                sx={{
                  backgroundColor: "#F7F8FA",
                }}
              />
            </div>
            <div className="flex justify-between items-center md:mb-5 xl:mb-10">
              <div className="flex items-center m-1">
                <Checkbox
                  style={{
                    padding: "0px",
                    // borderColor: "#D2D2D2",
                    borderWidth: "0.5px",
                    borderColor: "#F7F8FA",
                  }}
                />
                <h3>Remember me</h3>
              </div>
              <h3 className="text-red-400">Forgot Password ?</h3>
            </div>
            <div>
              <button
                type="submit"
                className="w-3/4 p-3 rounded-lg font-bold text-white"
                style={{ backgroundColor: "#F9DD7B" }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={"1.5rem"} />
                ) : (
                  "LOGIN"
                )}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="login-form">
          <Avatar className="mx-auto">
            <LockPersonOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="text-center">
            Sign in
          </Typography>

          <Formik>
            <Form onSubmit={handleSubmit}>
              <TextInputField
                type="email"
                wrapperClassName="my-5"
                inputClassName="w-full"
                name="email"
                label="Email"
                formik={formik}
                autoFocus
              />

              <TextInputField
                type="password"
                wrapperClassName="my-5"
                inputClassName="w-full"
                name="password"
                label="Password"
                formik={formik}
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={false}
                className="mb-2"
              >
                Sign In
              </Button>
              <div className="flex justify-center">
                <Link to="/forgot-password">Forgot Password ?</Link>
              </div>
            </Form>
          </Formik>

          <Box mt={2}>
            <Copyright />
          </Box>
        </div> */}
        {/* </Paper> */}
      </div>
    </form>
  );
};

export default Login;
