import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as PaperCup } from "../../../../assets/images/PaperCups.svg";
import { GoPencil } from "react-icons/go";

const CategoryCard = ({ data, toggleHandler }) => {
  return (
    <Box className="flex justify-between bg-white p-2 rounded-md mb-2">
      <Box className="flex items-center">
        <Box className="p-1 rounded-lg" style={{ border: "1px solid #323031" }}>
          <img
            src={data?.logoLink}
            alt=""
            style={{ width: "25px", height: "25px" }}
          />
        </Box>
        <h2 className="md:text-md xl:text-lg ms-2">{data.categoryName}</h2>
      </Box>
      <span className="p-2 bg-black rounded-lg">
        <GoPencil
          color="white"
          className="md:w-5 md:h-5 xl:w-6 xl:h-6"
          onClick={() => toggleHandler(data.categoryFor + " Categories", data)}
        />
      </span>
    </Box>
  );
};

export default CategoryCard;
