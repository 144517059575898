import { Box, Grid, InputAdornment, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchInput from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import TableContainerComp from "../common/Table/TableContainer";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { BusinessWiseData, generateBusinessWiseColumns } from "./helper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSurfacesBusinessWise } from "../../store/surface/surface.slice";

const BusinessWiseComp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    businesses: { data: Businesses, total_data },
    isLoading,
  } = useSelector((state) => state.surface);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = generateBusinessWiseColumns(generalInfoDetails?.dateFormat);
  console.log(Businesses, "Businesses");
  const [searchState, setSearchState] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState({
    sortType: "asc",
    sortBy: "createdAt",
  });

  const getSurfacesBusinessWiseList = (details) => {
    dispatch(
      getSurfacesBusinessWise({
        page: details?.page || pagination.page,
        limit: details?.pageSize || pagination.pageSize,
        sortBy: details?.orderBy || sorting?.orderBy,
        sortType: details?.order || sorting?.order,
        q: details?.q || "",
      })
    );
  };

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getSurfacesBusinessWiseList({
      page,
      pageSize,
    });
  };

  const sortingHandler = (orderByCol) => {
    console.log(orderByCol, sorting, "orderBy");
    const isAsc = sorting?.orderBy === orderByCol && sorting?.order === "asc";
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
    getSurfacesBusinessWiseList({
      order: isAsc ? "desc" : "asc",
      orderBy: orderByCol,
    });
  };

  const searchHandler = (e) => {
    console.log(e, "value");
    getSurfacesBusinessWiseList({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  useEffect(() => {
    getSurfacesBusinessWiseList();
  }, []);
  return (
    <Box className="mt-4">
      <Box className="text-start flex items-center justify-between mx-2">
        <Box className="flex items-center justify-center mb-2">
          <SearchInput
            placeholder="Search by text"
            wrapperClassName="me-4"
            isShowError={false}
            value={searchState}
            onChange={searchHandler}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <CiSearch />
                </IconButton>
              </InputAdornment>
            }
            inputClassName="rounded-lg"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                border: 0,
              },
            }}
          />
          {/* <ButtonComponent
            btnText="Clear Filter"
            style={{
              padding: "7px 20px",
              backgroundColor: "#263238",
              "&:hover": { backgroundColor: "#263238" },
            }}
            onClick={() => {}}
          /> */}
          <p className="text-zinc-400 ms-4 md:!text-md xl:!text-lg">
            Total business : <span className="text-black">{total_data}</span>
          </p>
        </Box>
        <Box className="flex">
          <span className="p-1 bg-white me-3 rounded-md">
            <FiDownload className="w-6 h-6" />
          </span>
          <span className="p-1 bg-white rounded-md">
            <IoPrintOutline className="w-6 h-6" />
          </span>
          {/* <p className="text-sm">Total User</p> */}
        </Box>
      </Box>
      <Box className="mx-2">
        {/* <TableContainerComp
          columns={columns}
          rows={Businesses}
          pagination={true}
          isLoading={isLoading}
          hasLink={true}
          navigateURL={"/supply/businesswise-profile"}
          paginationDetails={{
            ...pagination,
            total_data,
            pageHandler: pageHandler,
          }}
        /> */}
        <TableContainerComp
          columns={columns}
          rows={Businesses}
          pagination={true}
          isLoading={isLoading}
          hasLink={true}
          navigateURL={"/supply/businesswise-profile"}
          sortingDetails={sorting}
          sortingHandler={sortingHandler}
          paginationDetails={{
            ...pagination,
            total_data,
            pageHandler: pageHandler,
          }}
        />
      </Box>
    </Box>
  );
};

export default BusinessWiseComp;
