import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DELETE, GET, POST } from "../../services/methods";

export const getEmailTemplates = createAsyncThunk(
  "emailTemplate/getEmailTemplates",
  async () => {
    const getData = async () => {
      return GET("emailTemplate/get").then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addEmailTemplate = createAsyncThunk(
  "emailTemplate/addEmailTemplate",
  async (payload) => {
    const postData = async () => {
      return POST("/emailTemplate/add", payload).then((res) => {
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

export const deleteEmailTemplateById = createAsyncThunk(
  "emailTemplate/deleteEmailTemplateById",
  async (params) => {
    const deleteData = async () => {
      return DELETE(`/emailTemplate/deleteById?id=${params.id}`).then((res) => {
        return res;
      });
    };
    try {
      return await deleteData();
    } catch (error) {
      return [];
    }
  }
);
// export const addAppArticle = createAsyncThunk(
//   "settings/addAppArticle",
//   async (payload) => {
//     const postData = async () => {
//       return POST("/apparticle/add", payload).then((res) => {
//         console.log(res, "response");
//         return res;
//       });
//     };
//     try {
//       return await postData();
//     } catch (error) {
//       return [];
//     }
//   }
// );

const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState: {
    isLoading: false,
    emailTemplates: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getEmailTemplates.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getEmailTemplates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emailTemplates = action.payload || [];
    });
    builder.addCase(getEmailTemplates.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default emailTemplateSlice.reducer;
