import React, { useEffect, useRef, useState } from "react";
import ModalComp from "../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import { Categories } from "../helper";
import TextInputField from "../../common/Input/TextInput";
import ButtonComponent from "../../common/Button";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import SelectInput from "../../common/Input/SelectInput";
import DateInput from "../../common/Input/DateInput";
import { Form, Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppArticle,
  getAppArticleById,
  getAppArticles,
} from "../../../store/settings/apparticle.slice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ReactQuillComp from "../../common/Input/ReactQuill";
import {
  addFAQ,
  getHelpSupport,
} from "../../../store/settings/helpsupport.slice";
// import { FaRegImage } from "react-icons/fa6";
// import { uploadImage } from "../../../../store/common/common.slice";
// import Loading from "../../../common/Loading";

const initialValue = {
  order: 0,
  topic: "",
  description: "",
};

const AddFAQModal = ({
  open,
  activeComponent,
  data,
  toggleHandler,
  title,
  width,
}) => {
  const dispatch = useDispatch();
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const [initialValues, setInitialValues] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      order: Yup.number()
        .required("Enter Order Number")
        .positive("Enter Valid Number")
        .integer(),
      topic: Yup.string().required("Enter Topic"),
      description: Yup.string().required("Enter Description"),
    }),
    onSubmit: (values) => {
      const payload = values;
      payload.faqType = activeComponent;
      if (data) {
        payload._id = data?._id;
      }
      dispatch(addFAQ(payload)).then((resp) => {
        console.log(resp, "data in submit");
        if (resp?.payload?.success) {
          toast.success(
            `${activeComponent} FAQ is ${
              data ? "Edited" : "Added"
            } Successfully !`
          );
          dispatch(getHelpSupport({ faqType: activeComponent }));
          formik.resetForm();
          toggleHandler();
        } else {
          console.log(resp?.payload, "resp?.payload");
          toast.error(resp?.payload?.message);
        }
      });
    },
  });

  useEffect(() => {
    console.log(data, "data");
    if (open && data) {
      formik.setValues((prev) => ({
        ...prev,
        order: data?.order,
        topic: data?.topic,
        description: data?.description,
      }));
    }
  }, [data, open]);
  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={width}
      >
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <Box className="p-5">
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <p>Order No *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1">
                        <TextInputField
                          formik={formik}
                          type="number"
                          name="order"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={true}
                        />
                      </span>
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={3}>
                    <p>Topic *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1">
                        <TextInputField
                          formik={formik}
                          name="topic"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={false}
                        />
                      </span>
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={3}>
                    <p>Description *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1">
                        <ReactQuillComp
                          name="description"
                          formik={formik}
                          isShowError
                        />
                      </span>
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={12}>
                    <Box className="my-5 flex justify-center">
                      <ButtonComponent
                        btnText="Cancel"
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid black",
                          borderRadius: "10px",
                          color: "black",
                          "&:hover": { backgroundColor: "#FFFFFF" },
                          boxShadow: "none",
                          width: "125px",
                        }}
                        onClick={() => {
                          formik.resetForm();
                          toggleHandler();
                        }}
                      />
                      <ButtonComponent
                        btnText="Save"
                        btnType="submit"
                        style={{
                          backgroundColor: "#263238",
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "125px",
                          "&:hover": { backgroundColor: "#263238" },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        </Formik>
      </ModalComp>
    </div>
  );
};

export default AddFAQModal;
