import Chart from "react-apexcharts";
const TotalEarningChart = () => {
  let data = {
    series: [810, 540],
    horizontalAlign: "center",
    options: {
      chart: {
        type: "donut",
      },
      labels: ["K Mart", "M Mart"],
      dataLabels: {
        enabled: false,
      },
      colors: ["#85ABBD", "#B5D2E0"],
      plotOptions: {
        pie: {
          donut: {
            size: "55%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return `$ ${val}`;
                },
              },
              total: {
                show: true,
                // showAlways: true,
                fontSize: "30px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 700,
                label: `Total`,
                color: "#0065bd",
                formatter: function (w) {
                  return (
                    "$" +
                    w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0)
                  );
                },
              },
            },
          },
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <div id="chart">
        {/* <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>

              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Chart
          options={data.options}
          series={data.series}
          type="donut"
          // height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default TotalEarningChart;
