import { Box, Card, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StatisticCard } from "../common/StatisticCard";
import RevenueChart from "./RevenueChart";
import BreadCrumb from "../common/BreadCrumb";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDetails } from "../../store/dashboard/dashboard.slice";
import UserChart from "./UserChart";
import UserCountChart from "./UserCountChart";
import { convertNumber } from "../../fn";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashboardDetails } = useSelector((state) => state.dashboard);
  const [userCountFilter, setUserCountFilter] = useState("daily");

  useEffect(() => {
    dispatch(getDashboardDetails());
  }, [dispatch]);
  console.log(dashboardDetails, "dashboardDetails");
  return (
    <>
      {/* <h1 className="text-2xl font-bold text-start mb-5 mx-2">Dashboard</h1> */}
      <BreadCrumb title="Dashboard" />
      <Box
        className="container dashboard"
        sx={{
          maxHeight: `calc(100vh - 102px - 1.5rem)`,
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} xl={3} className="p-0">
            <StatisticCard
              title="Total Revenue"
              text={`$ ${
                convertNumber(
                  dashboardDetails?.orderAndRevenueStats?.currentMonthRevenue
                ) || "0"
              }`}
              subText={
                <span
                  className={`flex items-center lg:text-xs xl:text-sm ${
                    dashboardDetails?.orderAndRevenueStats?.revenueChange < 0
                      ? "text-red-400"
                      : "text-green-400"
                  }`}
                >
                  {dashboardDetails?.orderAndRevenueStats?.revenueChange < 0 ? (
                    <BiDownArrowAlt className="w-5 h-5" />
                  ) : (
                    <BiUpArrowAlt className="w-5 h-5" />
                  )}
                  {dashboardDetails?.orderAndRevenueStats?.revenueChange?.toFixed(
                    2
                  ) || "0"}
                  % of last month
                </span>
              }
              // subTitleClass={"text-sm text-green-400"}
              titleClass={"md:text-lg xl:text-xl text-start md:mb-5 xl:mb-7"}
              textClass={"md:text-2xl xl:text-3xl font-bold"}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatisticCard
              title="Total Orders"
              text={
                dashboardDetails?.orderAndRevenueStats?.currentMonthOrders ||
                "0"
              }
              subText={
                <span
                  className={`flex items-center md:text-xs xl:text-sm ${
                    dashboardDetails?.orderAndRevenueStats?.ordersChange < 0
                      ? "text-red-400"
                      : "text-green-400"
                  }`}
                >
                  {dashboardDetails?.orderAndRevenueStats?.ordersChange < 0 ? (
                    <BiDownArrowAlt className="w-5 h-5" />
                  ) : (
                    <BiUpArrowAlt className="w-5 h-5" />
                  )}
                  {dashboardDetails?.orderAndRevenueStats?.ordersChange?.toFixed(
                    2
                  ) || 0}
                  % of last month
                </span>
              }
              // subTitleClass={"text-sm text-green-400"}
              titleClass={"md:text-lg xl:text-xl text-start md:mb-5 xl:mb-7"}
              textClass={"md:text-2xl xl:text-3xl font-bold"}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatisticCard
              title="Surfaces"
              text={dashboardDetails?.monthlyCount?.sharerCurrentCount || "0"}
              subText={
                <span
                  className={`flex items-center md:text-xs xl:text-sm ${
                    dashboardDetails?.monthlyCount?.sharerPercentageChange < 0
                      ? "text-red-400"
                      : "text-green-400"
                  }`}
                >
                  {dashboardDetails?.monthlyCount?.sharerPercentageChange <
                  0 ? (
                    <BiDownArrowAlt className="w-5 h-5" />
                  ) : (
                    <BiUpArrowAlt className="w-5 h-5" />
                  )}
                  {dashboardDetails?.monthlyCount?.sharerPercentageChange?.toFixed(
                    2
                  ) || 0}
                  % of last month
                </span>
              }
              // subTitleClass={"text-sm text-green-400"}
              titleClass={"md:text-lg xl:text-xl text-start md:mb-5 xl:mb-7"}
              textClass={"md:text-2xl xl:text-3xl font-bold"}
            />
          </Grid>
          <Grid item xs={6} xl={3}>
            <StatisticCard
              title="Supplies"
              text={dashboardDetails?.monthlyCount?.supplyCurrentCount || "0"}
              subText={
                <span
                  className={`flex items-center md:text-xs xl:text-sm ${
                    dashboardDetails?.monthlyCount?.supplyPercentageChange < 0
                      ? "text-red-400"
                      : "text-green-400"
                  }`}
                >
                  {dashboardDetails?.monthlyCount?.sharerPercentageChange <
                  0 ? (
                    <BiDownArrowAlt className="w-5 h-5" />
                  ) : (
                    <BiUpArrowAlt className="w-5 h-5" />
                  )}
                  {dashboardDetails?.monthlyCount?.supplyPercentageChange?.toFixed(
                    2
                  ) || 0}
                  % of last month
                </span>
              }
              // subTitleClass={"text-sm text-green-400"}
              titleClass={"md:text-lg xl:text-xl text-start md:mb-5 xl:mb-7"}
              textClass={"md:text-2xl xl:text-3xl font-bold"}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Card>
              <Grid container spacing={2} className="p-4">
                <Grid item xs={4}>
                  <h3 className="md:text-lg xl:text-xl text-end mb-7">
                    Seekers
                  </h3>
                  <h2 className="md:text-2xl xl:text-3xl text-end font-bold">
                    88
                  </h2>
                </Grid>
                <Grid item xs={4}>
                  <h3 className="md:text-lg xl:text-xl text-end mb-7">
                    Sharers
                  </h3>
                  <h2 className="md:text-2xl xl:text-3xl text-end font-bold">
                    88
                  </h2>
                </Grid>
                <Grid item xs={4}>
                  <h3 className="md:text-lg xl:text-xl text-end mb-7">
                    Suppliers
                  </h3>
                  <h2 className="md:text-2xl xl:text-3xl text-end font-bold">
                    88
                  </h2>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}
          <Grid item xs={6} className="p-0">
            <Card className="p-4">
              <Box className="flex justify-between items-center mb-5">
                <h3 className="md:text-lg xl:text-xl text-start">Revenue</h3>
                <Box className="flex text-zinc-400">
                  <span className="filter-text cursor-pointer">WEEK</span>
                  <span
                    className="text-black filter-text cursor-pointer"
                    style={{ backgroundColor: "#F9DD7B" }}
                  >
                    MONTHLY
                  </span>
                  <span
                    className="filter-text active cursor-pointer"
                    // style={{
                    //   backgroundColor: "#F7F8FA",
                    //   borderRadius: "0px 5px 5px 0px",
                    //   padding: "5px 15px 5px 15px",
                    // }}
                  >
                    YEARLY
                  </span>
                </Box>
              </Box>
              <RevenueChart chartData={dashboardDetails?.yearlyRevenue} />
            </Card>
          </Grid>
          <Grid item xs={6} className="p-0">
            <Card>
              <h3 className="p-4 md:text-lg xl:text-xl text-start">
                Total Users
              </h3>
              <Box className="px-4">
                <Box className="flex justify-between items-center">
                  <p
                    className={`flex items-center md:text-xs xl:text-sm ${
                      dashboardDetails?.monthlyCount?.userPercentageChange < 0
                        ? "text-red-400"
                        : "text-green-400"
                    }`}
                  >
                    {dashboardDetails?.monthlyCount?.userPercentageChange <
                    0 ? (
                      <BiDownArrowAlt className="w-5 h-5" />
                    ) : (
                      <BiUpArrowAlt className="w-5 h-5" />
                    )}
                    {dashboardDetails?.monthlyCount?.userPercentageChange?.toFixed(
                      2
                    ) || 0}
                    % of last month
                  </p>
                  <h2 className={"md:text-2xl xl:text-3xl font-bold"}>
                    {dashboardDetails?.monthlyCount?.userCurrentCount}
                  </h2>
                </Box>
                <Divider />
                <Box className="p-2">
                  <Box className="flex justify-between items-center mb-4">
                    <p className="md:text-md xl:text-lg">Seekers</p>
                    <h2 className="md:text-lg xl:text-xl">
                      {dashboardDetails?.monthlyCount?.userCurrentCount || 0}
                    </h2>
                  </Box>
                  <Box className="flex justify-between items-center mb-4">
                    <p className="md:text-md xl:text-lg">Sharers</p>
                    <h2 className="md:text-lg xl:text-xl">
                      {dashboardDetails?.monthlyCount?.sharerUserCurrentCount ||
                        0}
                    </h2>
                  </Box>
                  <Box className="flex justify-between items-center mb-4">
                    <p className="md:text-md xl:text-lg">Suppliers</p>
                    <h2 className="md:text-lg xl:text-xl">
                      {dashboardDetails?.monthlyCount?.supplyUserCurrentCount ||
                        0}
                    </h2>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} className="p-0">
            <Card className="p-4">
              <Box className="flex justify-between items-center mb-5">
                <h3 className="md:text-lg xl:text-xl text-start">
                  Users by State
                </h3>
                <Box className="flex text-zinc-400">
                  <span
                    className="text-black filter-text cursor-pointer"
                    style={{ backgroundColor: "#F9DD7B" }}
                  >
                    GRAPH
                  </span>
                  <span
                    className="filter-text active cursor-pointer"
                    // style={{
                    //   backgroundColor: "#F7F8FA",
                    //   borderRadius: "0px 5px 5px 0px",
                    //   padding: "5px 15px 5px 15px",
                    // }}
                  >
                    MAP
                  </span>
                </Box>
              </Box>
              <UserChart chartData={dashboardDetails?.userByStates} />
            </Card>
          </Grid>
          <Grid item xs={6} className="p-0">
            <Card className="p-4">
              <Box className="flex justify-between items-center mb-5">
                <h3 className="md:text-lg xl:text-xl text-start">User Count</h3>
                <Box className="flex text-zinc-400">
                  <span
                    className={`filter-text cursor-pointer ${
                      userCountFilter === "monthly" ? "text-black" : ""
                    }`}
                    style={{
                      backgroundColor:
                        userCountFilter === "monthly" ? "#F9DD7B" : "#F7F8FA",
                    }}
                    onClick={() => setUserCountFilter("monthly")}
                  >
                    MONTHLY
                  </span>
                  <span
                    className={`filter-text cursor-pointer ${
                      userCountFilter === "daily" ? "text-black" : ""
                    }`}
                    style={{
                      backgroundColor:
                        userCountFilter === "daily" ? "#F9DD7B" : "#F7F8FA",
                    }}
                    onClick={() => setUserCountFilter("daily")}
                  >
                    DAILY
                  </span>
                </Box>
              </Box>
              <UserCountChart
                chartData={dashboardDetails}
                filter={userCountFilter}
              />
            </Card>
          </Grid>
        </Grid>
        <Box></Box>
      </Box>
    </>
  );
};

export default Dashboard;
