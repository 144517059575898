import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import TableContainerComp from "../common/Table/TableContainer";
import {
  AccountData,
  generateColumns,
  orderDetailsColumns,
  orderDetailsData,
} from "./helper";
import CustomTableContainer from "../common/Table/CustomTableContainer";
import ButtonComponent from "../common/Button";
import SearchInput from "../common/Input/TextInput";
import { CiSearch } from "react-icons/ci";
import { IoChevronBackOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { getReservations } from "../../store/reservation/reservation.slice";
import { useDispatch, useSelector } from "react-redux";

const AccountOrdersDetails = () => {
  const dispatch = useDispatch();
  const {
    reservations: { data: Reservation, total_data },
    isLoading,
  } = useSelector((state) => state.reservation);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState({
    userType: "seeker",
    orderType: "allReserves",
  });
  const [searchState, setSearchState] = useState("");
  const columns = orderDetailsColumns(
    tabs.userType,
    generalInfoDetails?.dateFormat
  );
  const [sorting, setSorting] = useState({
    sortBy: "orderNo",
    sortType: "asc",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  // const [subActiveTab, setSubActiveTab] = useState("allReserves");

  const getAllReservations = (params) => {
    setSearchState(params?.search || "");
    const payload = {
      limit: params?.pageSize || pagination?.pageSize,
      page: params?.page || pagination?.page,
      sortby: params?.sortBy || sorting?.sortBy,
      sorttype: params?.sortType || sorting?.sortType,
      userType: params?.userType || tabs?.userType,
      orderType: params?.orderType || tabs?.orderType,
      userId: params?.userId || location?.state?._id,
      q: params?.q || "",
    };
    dispatch(getReservations(payload));
  };

  const searchHandler = (e) => {
    console.log(e, "value");
    getAllReservations({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  const sortingHandler = (orderByCol) => {
    console.log(orderByCol, "orderByCol");
    const isAsc = sorting?.sortBy === orderByCol && sorting?.sortType === "asc";
    setSorting({ sortType: isAsc ? "desc" : "asc", sortBy: orderByCol });
    getAllReservations({
      sortType: isAsc ? "desc" : "asc",
      sortBy: orderByCol,
    });
  };
  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getAllReservations({ page: page, limit: pageSize });
  };

  useEffect(() => {
    getAllReservations();
    console.log(location.state, "state location");
  }, []);

  return (
    <Box>
      <BreadCrumb
        title="Orders"
        parents={["Accounts Management", "Profile"]}
        parentsURL={["/account", "/account/profile"]}
        parentsData={[{}, location.state]}
      />
      <Box className="mx-2">
        <Card className="p-3">
          <Box className="flex justify-between items-center mb-4">
            <h1 className="flex items-center text-start font-semibold md:text-lg xl:text-xl">
              <span className="me-2">
                {" "}
                <IoChevronBackOutline
                  className="w-6 h-6 cursor-pointer"
                  onClick={() =>
                    navigate("/account/profile", { state: location.state })
                  }
                />{" "}
              </span>
              Order List
            </h1>
            <SearchInput
              placeholder="Search by text"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
          </Box>
          <Grid container className="mb-3">
            <Grid
              item
              xs={4}
              className="cursor-pointer"
              onClick={() => {
                setTabs((prev) => ({
                  orderType: "allReserves",
                  userType: "seeker",
                }));
                setPagination((prev) => ({ ...prev, page: 1 }));
                getAllReservations({
                  userType: "seeker",
                  orderType: "allReserves",
                  page: 1,
                });
              }}
            >
              <h2
                className="py-3 md:text-md xl:text-lg"
                style={{
                  backgroundColor: "#F7F8FA",
                  borderBottom:
                    tabs.userType === "seeker" ? "3px solid #85ABBD" : "",
                  borderRadius:
                    tabs.userType === "seeker" ? "10px 0px 0px 10px" : "",
                }}
              >
                Seeker
              </h2>
            </Grid>
            <Grid
              item
              xs={4}
              className="cursor-pointer"
              onClick={() => {
                setTabs((prev) => ({
                  orderType: "allReserves",
                  userType: "sharer",
                }));
                setPagination((prev) => ({ ...prev, page: 1 }));
                getAllReservations({
                  userType: "sharer",
                  orderType: "allReserves",
                  page: 1,
                });
              }}
            >
              <h2
                className="py-3 md:text-md xl:text-lg"
                style={{
                  backgroundColor: "#F7F8FA",
                  borderBottom:
                    tabs.userType === "sharer" ? "3px solid #85ABBD" : "",
                }}
              >
                Sharer
              </h2>
            </Grid>
            <Grid
              item
              xs={4}
              className="cursor-pointer"
              onClick={() => {
                setTabs((prev) => ({
                  orderType: "allReserves",
                  userType: "supplier",
                }));
                setPagination((prev) => ({ ...prev, page: 1 }));
                getAllReservations({
                  userType: "supplier",
                  orderType: "allReserves",
                  page: 1,
                });
              }}
            >
              <h2
                className="py-3 md:text-md xl:text-lg"
                style={{
                  backgroundColor: "#F7F8FA",
                  borderBottom:
                    tabs.userType === "supplier" ? "3px solid #85ABBD" : "",
                  borderRadius:
                    tabs.userType === "supplier" ? "0px 10px 10px 0px" : "",
                }}
              >
                Supplier
              </h2>
            </Grid>
          </Grid>
          <Box
            className="p-3 rounded-md"
            style={{ backgroundColor: "#F7F8FA" }}
          >
            <Grid container>
              <Grid
                item
                xs={2}
                lg={2}
                onClick={() => {
                  setTabs((prev) => ({ ...prev, orderType: "allReserves" }));
                  setPagination((prev) => ({ ...prev, page: 1 }));
                  getAllReservations({
                    orderType: "allReserves",
                    page: 1,
                  });
                }}
                className="cursor-pointer"
              >
                <h3
                  className="py-1"
                  style={{
                    borderBottom:
                      tabs.orderType === "allReserves"
                        ? "2px solid #F6CA30"
                        : "",
                    color:
                      tabs.orderType === "allReserves" ? "#F6CA30" : "black",
                  }}
                >
                  All reserves
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                onClick={() => {
                  setTabs((prev) => ({ ...prev, orderType: "active" }));
                  setPagination((prev) => ({ ...prev, page: 1 }));
                  getAllReservations({
                    orderType: "active",
                    page: 1,
                  });
                }}
                className="cursor-pointer"
              >
                <h3
                  className="py-1"
                  style={{
                    borderBottom:
                      tabs.orderType === "active" ? "2px solid #F6CA30" : "",
                    color: tabs.orderType === "active" ? "#F6CA30" : "black",
                  }}
                >
                  Active
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                onClick={() => {
                  setTabs((prev) => ({ ...prev, orderType: "completed" }));
                  setPagination((prev) => ({ ...prev, page: 1 }));
                  getAllReservations({
                    orderType: "completed",
                    page: 1,
                  });
                }}
                className="cursor-pointer"
              >
                <h3
                  className="py-1"
                  style={{
                    borderBottom:
                      tabs.orderType === "completed" ? "2px solid #F6CA30" : "",
                    color: tabs.orderType === "completed" ? "#F6CA30" : "black",
                  }}
                >
                  Completed
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                onClick={() => {
                  setTabs((prev) => ({ ...prev, orderType: "upcoming" }));
                  setPagination((prev) => ({ ...prev, page: 1 }));
                  getAllReservations({
                    orderType: "upcoming",
                    page: 1,
                  });
                }}
                className="cursor-pointer"
              >
                <h3
                  className="py-1"
                  style={{
                    borderBottom:
                      tabs.orderType === "upcoming" ? "2px solid #F6CA30" : "",
                    color: tabs.orderType === "upcoming" ? "#F6CA30" : "black",
                  }}
                >
                  Upcoming
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                onClick={() => {
                  setTabs((prev) => ({ ...prev, orderType: "cancelled" }));
                  setPagination((prev) => ({ ...prev, page: 1 }));
                  getAllReservations({
                    orderType: "cancelled",
                    page: 1,
                  });
                }}
                className="cursor-pointer"
              >
                <h3
                  className="py-1"
                  style={{
                    borderBottom:
                      tabs.orderType === "cancelled" ? "2px solid #F6CA30" : "",
                    color: tabs.orderType === "cancelled" ? "#F6CA30" : "black",
                  }}
                >
                  Cancelled
                </h3>
              </Grid>
            </Grid>
            <Divider />
            <Box className="my-3">
              <CustomTableContainer
                columns={columns}
                data={Reservation}
                sortingHandler={sortingHandler}
                sortingDetails={sorting}
                pagination={true}
                paginationDetails={{
                  ...pagination,
                  total_data,
                  pageHandler: pageHandler,
                }}
              />
              {/* <TableContainerComp
                columns={columns}
                rows={AccountData}
                pagination={true}
                hasLink={true}
                navigateURL={"/account-profile"}
              /> */}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default AccountOrdersDetails;
