export const Statistics = [
  {
    title: "Total Revenue",
    amount: "$ 1350",
    subText: "6% of last month",
  },
  {
    title: "Total Orders",
    amount: "$ 88",
    subText: "2% of last month",
  },
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
