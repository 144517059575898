import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Paper,
  TableContainer,
  Pagination,
} from "@mui/material";
import PropTypes from "prop-types";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExternalLinkIcon } from "../../../assets/images/ExternalLinkIcon.svg";
import Loading from "../Loading";

// const usePagination = () => {
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   const next = useCallback(() => setPage((prev) => prev + 1), []);
//   const previous = useCallback(() => setPage((prev) => prev - 1), []);
//   const first = useCallback(() => setPage(1), []);
//   const last = useCallback(() => setPage(totalPages), [totalPages]);
//   const gotoPage = useCallback((page) => setPage(page), []);

//   return {
//     page,
//     totalPages,
//     setTotalPages,
//     next,
//     previous,
//     first,
//     last,
//     gotoPage,
//   };
// };

const CustomTableContainer = ({
  columns,
  data,
  isLoading = false,
  id,
  className,
  rowStyle,
  style,
  headerClassName,
  bodyClassName,
  externalLink,
  pagination,
  paginationDetails,
  sortingDetails,
  sortingHandler,
  pageSize,
  paginationType,
  serverSidePaginationConfig,
  isExpandable,
  isDefaultExpanded,
  renderSubRow,
}) => {
  const navigate = useNavigate();
  //   const [rowData, setRowData] = useState([]);
  //   const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);

  //   const paginate = usePagination();
  //   const { page, setTotalPages } = paginate;

  //   useEffect(() => {
  //     if (!pagination) {
  //       setRowData(data);
  //     }
  //   }, [pagination, data]);

  //   useEffect(() => {
  //     if (pagination && paginationType === "clientSide") {
  //       setTotalPages(Math.ceil(data.length / pageSize));
  //       const rowData = data.slice(pageSize * (page - 1), pageSize * page);
  //       setRowData(rowData);
  //     }
  //   }, [page, pageSize, paginationType, data, pagination, setTotalPages]);

  //   useEffect(() => {
  //     if (
  //       pagination &&
  //       paginationType === "serverSide" &&
  //       serverSidePaginationConfig.endpoint
  //     ) {
  //     }
  //   }, [pagination, paginationType, serverSidePaginationConfig.endpoint]);
  console.log(sortingDetails, "sortingDetails");
  return (
    <Box sx={{ width: "100%" }}>
      {!isLoading && (
        <Paper
          sx={{
            width: "calc(100vw - 18rem - 8px)",
            // overflowX: "scroll",
            marginBottom: "10px",
          }}
        >
          <TableContainer sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
            {/* <div
          className="overflow-y-auto overflow-x-scroll table-container"
          style={{ width: "calc(100vw - 18rem - 8px)" }}
        > */}
            <Table
              id={id}
              className={`rounded-md overflow-y-auto overflow-x-scroll ${className}`}
              sx={{ ...style }}
              stickyHeader
            >
              <TableHead className={headerClassName}>
                <TableRow>
                  {externalLink && (
                    <TableCell
                      className="!border-0"
                      padding="normal"
                    ></TableCell>
                  )}
                  {columns.map((column, index) => {
                    console.log(column, "column in custom");
                    return (
                      <TableCell
                        className="!text-center"
                        key={column.field || index}
                        {...(column.columnProps ?? {})}
                        style={{
                          border: 0,
                          padding: "10px",
                          // fontWeight: 600,
                          minWidth: `${column?.width || "150px"}`,
                          width: "fit-content",
                          textWrap: "nowrap",
                        }}
                      >
                        <Box className="flex items-center">
                          <p className="me-2">{column.label}</p>
                          {!column?.disableSorting &&
                            (sortingDetails?.sortBy === column?.field ? (
                              sortingDetails?.sortType === "asc" ? (
                                <span
                                  onClick={() => sortingHandler(column?.field)}
                                >
                                  <FaCaretUp className="cursor-pointer" />
                                </span>
                              ) : (
                                <span
                                  onClick={() => sortingHandler(column?.field)}
                                >
                                  <FaCaretDown className="cursor-pointer" />
                                </span>
                              )
                            ) : (
                              <span
                                onClick={() => sortingHandler(column?.field)}
                              >
                                <FaCaretUp className="cursor-pointer" />
                              </span>
                            ))}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={bodyClassName}>
                {data.length > 0 ? (
                  data.map((item, rowIndex) => {
                    return (
                      <TableRow
                        className="gray-row"
                        style={{ ...(rowStyle || {}) }}
                      >
                        {externalLink && (
                          <TableCell
                            // padding="checkbox"
                            className="!text-center"
                            sx={{ borderBottom: "none" }}
                            onClick={(event) =>
                              navigate(externalLink, { state: item })
                            }
                            id="link"
                          >
                            <ExternalLinkIcon className="w-5 h-5 cursor-pointer" />
                          </TableCell>
                        )}
                        {columns.map((column, index) => {
                          // console.log(column.renderCell, column.title, "column");
                          return (
                            <TableCell
                              key={column.field || index}
                              style={{
                                minWidth: `${column?.width || "150px"}`,
                              }}
                              className={`!text-start !border-none !text-nowrap`}
                            >
                              {column.renderCell
                                ? column.renderCell(item, rowIndex)
                                : item?.[column.field]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="!text-center !border-none"
                    >
                      No Data Available !
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* </div> */}
          </TableContainer>
        </Paper>
      )}
      {!isLoading && pagination && (
        <div className="flex justify-between mb-3">
          <Box className="flex items-center">
            <span className="text-sm text-zinc-400">View</span>
            <select
              className="h-6 mx-2 text-sm border-0"
              value={paginationDetails?.pageSize || 10}
              onChange={(event) => {
                console.log(event.target.value);
                paginationDetails.pageHandler(
                  paginationDetails?.page || 1,
                  +event.target.value
                );
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span className="text-sm text-zinc-400 me-1">Entries per page</span>
          </Box>
          <Pagination
            variant="text"
            // showFirstButton
            // showLastButton
            color="primary"
            size="small"
            count={Math.ceil(
              paginationDetails?.total_data / paginationDetails?.pageSize || 1
            )}
            // rowsPerPage={10}
            // siblingCount={0}
            // boundaryCount={0}
            page={paginationDetails?.page || 1}
            onChange={(event, page) => {
              paginationDetails?.pageHandler(
                page,
                paginationDetails?.pageSize || 10
              );
            }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#F9DD7B !important",
              },
            }}
          />
        </div>
      )}
      {isLoading && (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Loading isLoading={isLoading} />
        </Box>
      )}
    </Box>
  );
};

CustomTableContainer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  className: PropTypes.string,
  rowStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  externalLink: PropTypes.string,
  pagination: PropTypes.bool,
  pageSize: PropTypes.number,
  paginationType: PropTypes.oneOf(["serverSide", "clientSide"]),
  serverSidePaginationConfig: PropTypes.shape({
    endpoint: PropTypes.string,
  }),
  isExpandable: PropTypes.bool,
  isDefaultExpanded: PropTypes.bool,
  renderSubRow: PropTypes.func,
};

CustomTableContainer.defaultProps = {
  columns: [],
  data: [],
  id: "",
  className: "",
  style: {},
  headerClassName: "",
  bodyClassName: "",
  pagination: false,
  pageSize: 20,
  paginationType: "clientSide",
  serverSidePaginationConfig: {
    endpoint: "",
  },
  isExpandable: false,
  isDefaultExpanded: false,
  renderSubRow: () => null,
};

export default CustomTableContainer;
