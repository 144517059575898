import React, { useEffect, useState } from "react";
import ModalComp from "../../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import { Categories } from "../helper";
import TextInputField from "../../../common/Input/TextInput";
import ButtonComponent from "../../../common/Button";
import ReactQuillComp from "../../../common/Input/ReactQuill";
import { useDispatch } from "react-redux";
import {
  addTermsAndConditions,
  getTermsAndConditionById,
  getTermsAndConditions,
} from "../../../../store/settings/rulesReporting";
import { Form, Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const initialValue = {
  termName: "",
  termDesciption: "",
};

const CreateTermsConditionModal = ({
  open,
  id,
  toggleHandler,
  title,
  width,
}) => {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState(initialValue);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      termName: Yup.string().required("Term Name is required"),
      termDesciption: Yup.string().required("Term Desciption is required"),
    }),
    onSubmit: (values) => {
      let payload = values;
      if (id) {
        payload["_id"] = id;
      }
      dispatch(addTermsAndConditions(payload)).then((data) => {
        if (data?.payload?.success) {
          toast.success(
            `Terms And Condition ${id ? "Edited" : "Added"} Successfully!`
          );
          toggleHandler();
          formik.resetForm();
          dispatch(getTermsAndConditions());
        }
      });
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(getTermsAndConditionById({ id: id })).then((data) => {
        if (data?.payload?.success) {
          setInitialValues(data?.payload?.data);
        } else {
          toast.error("No data found");
        }
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [id]);

  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={width}
      >
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={{ padding: "1rem 5rem" }}>
              {id ? (
                <h1 className="text-xl mb-3">{formik.values?.termName}</h1>
              ) : (
                <Box className="flex items-center md:text-md xl:text-lg md:mb-2 xl:mb-3">
                  <p className="md:me-2 xl:me-3">Name *</p>
                  <TextInputField
                    inputClassName="rounded-xl"
                    name="termName"
                    formik={formik}
                    disabled={!!id}
                    sx={{
                      backgroundColor: "#F7F8FA",
                      fontSize: "15px",
                    }}
                  />
                </Box>
              )}
              <ReactQuillComp name="termDesciption" formik={formik} />
            </Box>
            <Box className="flex items-center justify-center">
              <ButtonComponent
                btnText="Cancel"
                //   startIcon={<RiMessage2Fill />}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  borderRadius: "10px",
                  color: "black",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                  boxShadow: "none",
                  width: "125px",
                }}
                onClick={() => {
                  formik.resetForm();
                  toggleHandler();
                }}
              />
              <ButtonComponent
                btnText="Save"
                btnType="submit"
                btnClass="!px-7"
                //   startIcon={<RiMessage2Fill />}
                style={{
                  backgroundColor: "#263238",
                  border: "1px solid black",
                  borderRadius: "10px",
                  width: "125px",
                  "&:hover": { backgroundColor: "#263238" },
                }}
              />
            </Box>
          </Form>
        </Formik>
      </ModalComp>
    </div>
  );
};

export default CreateTermsConditionModal;
