import React, { useEffect, useState } from "react";
import CustomTableContainer from "../../common/Table/CustomTableContainer";
import { Box, colors, Grid, IconButton, InputAdornment } from "@mui/material";
import { StatisticCard } from "../../common/StatisticCard";
import { CiSearch } from "react-icons/ci";
import SearchInput from "../../common/Input/TextInput";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../common/Button";
import { fontAndborder, helpSupportColumn } from "../helper";
import {
  getSupportTickets,
  updateSupportTicket,
} from "../../../store/settings/helpsupport.slice";
import TableContainerComp from "../../common/Table/TableContainer";
import SelectInput from "../../common/Input/SelectInput";
import { toast } from "react-toastify";
import TextInputField from "../../common/Input/TextInput";

const SupportTicket = ({ tabChangeHandler }) => {
  const dispatch = useDispatch();
  const { data: SupportTickets, total_data } = useSelector(
    (state) => state.settings.helpSupportInfo?.supportTickets
  );
  const { supportTicketCounts } = useSelector(
    (state) => state.settings.helpSupportInfo
  );
  const columns = helpSupportColumn();
  const [openedRow, setOpenedRow] = useState("");
  const [searchState, setSearchState] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [isNewRecords, setIsNewRecords] = useState(false);
  const [editEnabledRows, setEditEnabledRows] = useState({
    _id: "",
    status: "",
    remarksResolved: "",
    remarksInProgress: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState({
    order: "desc",
    orderBy: "createdAt",
  });

  const getSupportTicketsFn = (params, callback) => {
    const p = {
      limit: params?.limit || pagination.pageSize,
      page: params?.page || pagination.page,
      sortby: params?.sortBy || sorting.orderBy,
      sorttype: params?.sortType || sorting.order,
      q: params?.search || "",
      status: params?.status !== undefined ? params?.status : selectedCard,
    };
    if (params?.new === undefined ? isNewRecords : params?.new) {
      p.new = true;
    }
    dispatch(getSupportTickets(p)).then(() => {
      callback && callback();
    });
  };
  const sortingHandler = (sortByCol, sortBy) => {
    console.log(sortBy, "orderBy");
    const isAsc = sorting?.orderBy === sortByCol && sorting?.order === "asc";
    setSorting({
      order: sortBy ? sortBy : isAsc ? "desc" : "asc",
      orderBy: sortByCol,
    });
    getSupportTicketsFn({
      search: searchState,
      sortType: isAsc ? "desc" : "asc",
      sortBy: sortByCol,
    });
  };
  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getSupportTicketsFn({ search: searchState, page: page, limit: pageSize });
  };

  const searchHandler = (e) => {
    console.log(e.target.value, "valuesss");
    getSupportTicketsFn({ page: 1, search: e?.target?.value || "" });
    setSearchState(e.target.value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  useEffect(() => {
    getSupportTicketsFn();
  }, []);

  const options = [
    {
      label: "Pending",
      value: "Pending",
      backgroundColor: "#F3F8FB",
      color: "#85ABBD",
    },
    {
      label: "In Progress",
      value: "In Progress",
      backgroundColor: "#FFFAEF",
      color: "#FFAE00",
    },
    {
      label: "Resolved",
      value: "Resolved",
      backgroundColor: "#F1FFF3",
      color: "#6DC476",
    },
  ];
  return (
    <Box>
      <Box style={{ padding: "1rem 2rem" }}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3} className="p-0">
            <StatisticCard
              title="Total Tickets"
              text={
                (supportTicketCounts?.inProgress || 0) +
                (supportTicketCounts?.pending || 0) +
                (supportTicketCounts?.resolved || 0)
              }
              borderColor={"#263238"}
              allBorder={selectedCard === "" ? true : false}
              titleClass={"text-start md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              cardClass={"flex justify-between"}
              onClick={() => {
                setSelectedCard("");
                getSupportTicketsFn({ status: "" });
              }}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title="Pending"
              text={supportTicketCounts?.pending || "0"}
              borderColor={"#85ABBD"}
              allBorder={selectedCard === "Pending" ? true : false}
              titleClass={"text-start md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              cardClass={"flex justify-between"}
              onClick={() => {
                setSelectedCard("Pending");
                getSupportTicketsFn({ status: "Pending" });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title="In Progress"
              text={supportTicketCounts?.inProgress || "0"}
              borderColor={"#FFAE00"}
              allBorder={selectedCard === "In Progress" ? true : false}
              titleClass={"text-start md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              cardClass={"flex justify-between"}
              onClick={() => {
                setSelectedCard("In Progress");
                getSupportTicketsFn({ status: "In Progress" });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title="Resolved"
              text={supportTicketCounts?.resolved || "0"}
              borderColor={"#00B812"}
              allBorder={selectedCard === "Resolved" ? true : false}
              titleClass={"text-start md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              cardClass={"flex justify-between"}
              onClick={() => {
                setSelectedCard("Resolved");
                getSupportTicketsFn({ status: "Resolved" });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box className="text-start flex items-center justify-between m-2">
          <Box className="flex items-center">
            <SearchInput
              placeholder="Search by text"
              wrapperClassName="me-4"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg text-medium"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
            <Box
              className="ms-4 rounded-md text-md cursor-pointer"
              style={{
                backgroundColor: isNewRecords ? "#FCE38C" : "#FCF0C6",
                border: "1px solid black",
              }}
              onClick={() => {
                getSupportTicketsFn({ new: !isNewRecords });
                setIsNewRecords((prev) => !prev);
              }}
            >
              <p className="px-5 py-1 text-medium">
                New Tickets{" "}
                <span className="font-semibold">
                  {supportTicketCounts?.newCount || "0"}
                </span>
              </p>
            </Box>
          </Box>
          <Box className="flex items-center">
            <ButtonComponent
              btnText="FAQ Editor"
              btnClass="!text-medium"
              style={{
                padding: "7px 20px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
              onClick={() => {
                tabChangeHandler("FAQEditor");
              }}
            />
            {/* <Divider
                    orientation="vertical"
                    style={{ height: "40px", margin: "0px 20px" }}
                  />
                  <Box className="flex">
                    <span className="p-1 bg-white me-3 rounded-md">
                      <FiDownload className="w-6 h-6" />
                    </span>
                    <span className="p-1 bg-white rounded-md">
                      <IoPrintOutline className="w-6 h-6" />
                    </span>
                  </Box> */}
          </Box>
        </Box>
        {/* <Box className="p-1 rounded-md bg-white mx-2">
            <Box className="my-3"> */}
        <TableContainerComp
          columns={columns}
          rows={SupportTickets || []}
          openedRow={openedRow}
          rowClickHandler={(row) => {
            if (openedRow === row?._id) {
              setOpenedRow("");
            } else {
              dispatch(
                updateSupportTicket({
                  _id: row?._id,
                  isViewedByAdmin: true,
                })
              );
              setEditEnabledRows({
                _id: "",
                status: "",
                remarksResolved: "",
                remarksInProgress: "",
              });
              setOpenedRow(row?._id);
            }
          }}
          // isLoading={isLoading}
          rowStyle={{
            backgroundColor: "#F7F8FA",
            borderBottom: "7px solid white",
            marginBottom: "5px",
          }}
          // externalLink={"/reports/reservations-profile"}
          style={{ maxHeight: "30vh", overflowY: "scroll" }}
          pagination={true}
          paginationDetails={{
            ...pagination,
            total_data: total_data,
            pageHandler: pageHandler,
          }}
          sortingDetails={sorting}
          sortingHandler={sortingHandler}
          expendRowFn={(row) => {
            return (
              <Box>
                <Grid container className="justify-between px-5 mb-4">
                  <Grid item xs={5}>
                    <Box className="mb-2">
                      <h1 className="text-lg font-semibold">Topic :</h1>
                      <p className="text-lg">{row?.topic}</p>
                    </Box>
                    <Box className="mb-2">
                      <h1 className="text-lg font-semibold">Description :</h1>
                      <p className="text-lg text-wrap break-words">
                        {row?.description}
                      </p>
                    </Box>
                    <Box className="mb-2">
                      <h1 className="text-lg font-semibold">Attachments :</h1>
                      <Box className="flex">
                        {row?.attachments?.map((image) => {
                          return (
                            <span className="me-2">
                              <img
                                src={image}
                                alt=""
                                width={"40px"}
                                height={"40px"}
                              />
                            </span>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} className="mb-2">
                    <Grid container className="mb-4">
                      <Grid item xs={4}>
                        <h1 className="text-lg font-semibold me-4">Status :</h1>
                      </Grid>
                      <Grid item xs={6} className="text-center">
                        {!editEnabledRows?._id ? (
                          <p
                            className="py-1 font-semibold rounded-lg"
                            style={{
                              border: `2px solid ${
                                fontAndborder[row?.status]?.color
                              }`,
                              color: fontAndborder[row?.status]?.color,
                              backgroundColor:
                                fontAndborder[row?.status]?.background,
                            }}
                          >
                            {row?.status}
                          </p>
                        ) : (
                          <SelectInput
                            // name={"status"}
                            // formik={formik}
                            value={options?.filter(
                              (op) => op.value === editEnabledRows?.status
                            )}
                            onChange={(e) => {
                              // const data = editEnabledRows?.map((r) => {
                              //   if (r._id === row._id) {
                              //     r.status = e.value;
                              //   }
                              //   return r;
                              // });
                              // console.log(data, "datatttttt");
                              setEditEnabledRows((prev) => ({
                                ...prev,
                                status: e.value,
                              }));
                            }}
                            options={options?.filter(
                              (op) => op.value !== editEnabledRows?.status
                            )}
                            formatOptionLabel={(props) => (
                              <div
                                className="flex items-center justify-center py-2 w-full rounded-lg"
                                style={{
                                  backgroundColor: props?.backgroundColor,
                                  color: props?.color,
                                  border: `1px solid ${props?.color}`,
                                }}
                              >
                                <span>{props.label}</span>
                              </div>
                            )}
                            valueStyle={{
                              border: "0px",
                              backgroundColor: "#FFFFFF",
                              "&:hover": {
                                border: "0px",
                                borderColor: "#FFFFFF",
                              },
                            }}
                            optionStyle={{
                              backgroundColor: "#FFFFFF",
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {/* {(selectedRow?.status === "In Progress" ||
                      selectedRow?.status === "Resolved") && ( */}
                    <Box>
                      <h1 className="text-lg font-semibold me-4">Remarks :</h1>
                      <TextInputField
                        // name="address"
                        // formik={formik}
                        value={
                          editEnabledRows?._id
                            ? editEnabledRows?.status === "In Progress"
                              ? editEnabledRows?.remarksInProgress
                              : editEnabledRows?.remarksResolved
                            : row?.remarksInProgress || row?.remarksResolved
                        }
                        multiline={true}
                        minRows={4}
                        maxRows={5}
                        onChange={(e) => {
                          // const data = editEnabledRows?.map((r) => {
                          //   if (r._id === row._id) {
                          //     if (editEnabledRows?.status === "In Progress") {
                          //       r.remarksResolved = "";
                          //       r.remarksInProgress = e.target.value;
                          //     } else {
                          //       r.remarksInProgress = "";
                          //       r.remarksResolved = e.target.value;
                          //     }
                          //   }
                          //   return r;
                          // });
                          setEditEnabledRows((prev) => ({
                            ...prev,
                            remarksResolved:
                              editEnabledRows?.status === "In Progress" ||
                              editEnabledRows?.status === "Pending"
                                ? ""
                                : e.target.value,
                            remarksInProgress:
                              editEnabledRows?.status === "Resolved" ||
                              editEnabledRows?.status === "Pending"
                                ? ""
                                : e.target.value,
                          }));
                        }}
                        disabled={
                          !(
                            editEnabledRows?.status === "In Progress" ||
                            editEnabledRows?.status === "Resolved"
                          )
                        }
                        // wrapperClassName="rounded-full"
                        inputClassName="rounded-xl text-medium"
                        fullWidth={true}
                        sx={{
                          backgroundColor: !(
                            editEnabledRows?.status === "In Progress" ||
                            editEnabledRows?.status === "Resolved"
                          )
                            ? "#F7F8FA"
                            : "#FFFFFF",
                        }}
                      />
                    </Box>
                    {/* // )} */}
                  </Grid>
                </Grid>
                <Box className="flex justify-end">
                  {!editEnabledRows?._id && (
                    <ButtonComponent
                      btnText="Update Status"
                      btnClass="!text-medium"
                      isDisabled={row?.status === "Resolved"}
                      style={{
                        padding: "7px 20px",
                        backgroundColor: "#263238",
                        "&:hover": { backgroundColor: "#263238" },
                      }}
                      onClick={() => {
                        setEditEnabledRows({
                          _id: row?._id,
                          status: row?.status,
                          remarksInProgress: row?.remarksInProgress,
                          remarksResolved: row?.remarksResolved,
                        });
                      }}
                    />
                  )}
                  {editEnabledRows?._id && (
                    <ButtonComponent
                      btnText="Cancel"
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: "1px solid black",
                        borderRadius: "10px",
                        color: "black",
                        "&:hover": { backgroundColor: "#FFFFFF" },
                        boxShadow: "none",
                        width: "125px",
                      }}
                      onClick={() => {
                        // const data = editEnabledRows?.filter(
                        //   (r) => r._id !== row?._id
                        // );
                        setEditEnabledRows({
                          _id: "",
                          status: "",
                          remarksResolved: "",
                          remarksInProgress: "",
                        });
                      }}
                    />
                  )}
                  {editEnabledRows?._id && (
                    <ButtonComponent
                      btnText="Save"
                      btnType="submit"
                      style={{
                        backgroundColor: "#263238",
                        border: "1px solid black",
                        borderRadius: "10px",
                        width: "125px",
                        "&:hover": { backgroundColor: "#263238" },
                      }}
                      onClick={() => {
                        dispatch(
                          updateSupportTicket({
                            _id: row?._id,
                            status: editEnabledRows?.status,
                            remarksInProgress:
                              editEnabledRows?.status === "Pending"
                                ? ""
                                : editEnabledRows?.remarksInProgress,
                            remarksResolved:
                              editEnabledRows?.status === "Pending"
                                ? ""
                                : editEnabledRows?.remarksResolved,
                          })
                        ).then((resp) => {
                          if (resp.payload?.success) {
                            toast.success(
                              "Support ticket status updated successfully!"
                            );
                            getSupportTicketsFn({ search: searchState }, () => {
                              // const data = editEnabledRows?.filter(
                              //   (r) => r._id !== row?._id
                              // );
                              setEditEnabledRows({
                                _id: "",
                                status: "",
                                remarksResolved: "",
                                remarksInProgress: "",
                              });
                            });
                          }
                        });
                      }}
                    />
                  )}
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default SupportTicket;
