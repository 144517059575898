import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const Loading = (isLoading, ...props) => {
  console.log(props, "props");
  return (
    <div className="">
      {/* <Backdrop open={isLoading}> */}
      <CircularProgress color="inherit" {...props} />
      {/* </Backdrop> */}
    </div>
  );
};

export default Loading;
