import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import ImageViewer from "../common/ImageViewer";
import ButtonComponent from "../common/Button";
import { RiMessage2Fill } from "react-icons/ri";
import { MdNavigateNext } from "react-icons/md";
// import TotalEarningChart from "./TotalEarningChart";
// import StoreCard from "./StoreCart";
import { FaStar } from "react-icons/fa";
import LinearProgressBar from "../common/LinearProgressBar";
import { IoFlagOutline } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { FaArrowUpLong } from "react-icons/fa6";
import { SlPrinter } from "react-icons/sl";
// import ReviewSection from "./ReviewSection";
import StepperComp from "../common/StepperComp";
import ModalComp from "../common/ModalComp";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewSection from "../common/ReviewSection";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import AllDetailsModalSeeker from "./AllDetailsModalSeeker";
import AllDetailsModalSharer from "./AllDetailsModalSharer";
import { ActivitySteps } from "../Account/helper";
import { getSupplyDetails } from "../../store/supply/supply.slice";
import { getReviews } from "../../store/common/common.slice";
import { useDispatch, useSelector } from "react-redux";
import { getSeekerDetails } from "../../fn";
import MenuComp from "../common/MenuComp";
import { MdMoreHoriz } from "react-icons/md";
import moment from "moment";

const SupplyWiseProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { supplyDetails } = useSelector((state) => state.supply);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  console.log(getSeekerDetails(supplyDetails), "supplyDetails");
  const {
    data: Reviews,
    avarageRating,
    total_data,
    starPercentages,
  } = useSelector((state) => state.common.reviewDetails);

  const [open, setOpen] = useState({
    showModal: false,
    data: [],
  });
  const [open2, setOpen2] = useState({
    showModal: false,
    data: [],
  });
  const [stars, setStars] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });
  const [loadMoreActivity, setLoadMoreActivity] = useState(false);
  const [seekerDetailsState, setSeekerDetailsState] = useState({
    currentSeeker: {},
    listOfSeeker: [],
  });
  const toggleHandler = (data) => {
    console.log(data, "in toggle handler");
    setOpen((prev) => ({ showModal: !prev.showModal, data: data || [] }));
  };
  const toggleHandler2 = (data) =>
    setOpen2((prev) => ({ showModal: !prev.showModal, data: data || [] }));

  const stores = [
    {
      img: null,
      storeName: "K Mart",
      place: "New York",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "H Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
  ];
  const [reviewFilter, setReviewFilter] = useState("1 month");
  const [expanded, setExpanded] = useState(false);
  const [redFlag, setRedFlag] = useState(true);

  useEffect(() => {
    dispatch(getSupplyDetails({ id: location.state._id }));
    dispatch(
      getReviews({ supplyId: location.state._id, page: 1, size: 100000 })
    );
  }, [location.state]);

  useEffect(() => {
    const s = stars;
    if (starPercentages) {
      starPercentages?.forEach((sa) => {
        s[sa?.star] = +sa?.percentage;
      });
      setStars(s);
    }
  }, [starPercentages]);

  useEffect(() => {
    if (supplyDetails) {
      const seekerDetails = getSeekerDetails(supplyDetails);
      console.log(seekerDetails, "seekerDetails");
      setSeekerDetailsState(seekerDetails);
    }
  }, [supplyDetails]);
  const UserMenuOptions = [
    {
      lable: "Edit",
      onClick: () => {},
    },
    {
      lable: "Disable Surface",
      onClick: () => {
        // setResetPasswordModal(true);
      },
    },
  ];
  return (
    <>
      <Box className="surface-profile">
        <BreadCrumb
          title={supplyDetails?.name}
          parents={["Supply Management"]}
          parentsURL={["/supply"]}
        />
        <Box
          className="mx-2"
          sx={{
            maxHeight: `calc(100vh - 102px - 1.5rem)`,
            overflowY: "scroll",
          }}
        >
          {/* <ModalComp /> */}
          <Grid container spacing={2}>
            <Grid item xs={12} xl={5}>
              <Card className="p-5" style={{ height: "100%" }}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={5}>
                    <Box className="flex flex-col items-center justify-center me-4">
                      <img
                        src={
                          (supplyDetails?.images?.length > 0 &&
                            supplyDetails?.images[0]) ||
                          "/images/BiggerCup.png"
                        }
                        alt=""
                        style={{ width: "100px", height: "auto" }}
                        className="my-1"
                      />
                      <h1 className="my-1 text-xl font-bold">
                        {supplyDetails?.name}
                      </h1>
                      <p
                        className="my-1 px-3 py-2 text-md rounded-md"
                        style={{ backgroundColor: "#FCF0C6" }}
                      >
                        {supplyDetails?.category?.categoryName}
                      </p>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={6}>
                    <Box className="flex">
                      <Grid
                        container
                        spacing={1}
                        columnSpacing={6}
                        className="text-start"
                      >
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-3 text-zinc-400 font-semibold">
                              Code
                            </h3>
                            <p className="mb-3 font-semibold">
                              {supplyDetails?.supplyCode}
                            </p>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-2 text-zinc-400 font-semibold">
                              Creator
                            </h3>
                            <Box className="flex mb-1 items-center">
                              <img
                                src={
                                  supplyDetails?.supplier?.profileUrl ||
                                  "/images/dummy-user.jpg"
                                }
                                alt=""
                                className="rounded-full me-2"
                                style={{ width: "35px", height: "35px" }}
                              />
                              <p className="text-sm whitespace-nowrap">
                                {`${supplyDetails?.supplier?.fname} ${supplyDetails?.supplier?.lname}`}
                              </p>
                            </Box>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-3 text-zinc-400 font-semibold">
                              Status
                            </h3>
                            <p className="mb-3 flex">
                              <GoDotFill
                                className="w-5 h-5"
                                style={{ color: "green" }}
                              />
                              {supplyDetails?.status}
                            </p>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-3 text-zinc-400 font-semibold">
                              Rating
                            </h3>
                            <Box className="flex items-center mb-3">
                              <p className="flex me-2">
                                <FaStar className="text-yellow-400 w-5 h-5 me-1" />{" "}
                                <span>
                                  {supplyDetails?.avgRating?.toFixed(2) || "0"}
                                </span>
                              </p>
                              <Divider
                                orientation="vertical"
                                style={{ height: "20px" }}
                              />
                              <p className="ms-2 whitespace-nowrap">
                                {supplyDetails?.totalReview} Rating
                              </p>
                            </Box>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-3 text-zinc-400 font-semibold">
                              Create Date
                            </h3>
                            <p className="mb-3">
                              {moment(supplyDetails?.createdAt).format(
                                generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                              )}
                            </p>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="my-2">
                            <h3 className="mb-3 text-zinc-400 font-semibold">
                              Update Date
                            </h3>
                            <p className="mb-3">
                              {moment(supplyDetails?.updatedAt).format(
                                generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                              )}
                            </p>
                          </Box>
                        </Grid>
                      </Grid>
                      <MenuComp
                        btn={<MdMoreHoriz className="text-xl" />}
                        menuList={UserMenuOptions}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={6} xl={4}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4 pb-0">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Business
                  </h1>
                </Box>
                <Box className="p-4 pt-0">
                  <Box className="flex justify-between">
                    <Box className="flex text-start">
                      <img
                        src={
                          supplyDetails?.businessProfile?.profileUrl ||
                          "/images/ResturentBig.png"
                        }
                        alt=""
                        style={{ height: "120px", width: "120px" }}
                      />
                      <Box className="ms-2">
                        <h2 className="font-semibold text-zinc-700 sm:text-md xl:text-lg">
                          {supplyDetails?.businessProfile?.name}
                        </h2>
                        <span
                          className="inline-block p-2 rounded-full"
                          style={{ backgroundColor: "#FCF0C6" }}
                        >
                          <SlPrinter className="w-5 h-5" />
                        </span>
                        <p className="text-zinc-400 sm:text-xs xl:text-sm">
                          {supplyDetails?.businessProfile?.street || ""},{" "}
                          {supplyDetails?.businessProfile?.state},
                        </p>
                        <p className="text-zinc-400 sm:text-xs xl:text-sm">
                          {supplyDetails?.businessProfile?.country}
                        </p>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="flex justify-end">
                        <img
                          src={
                            supplyDetails?.supplier?.profileUrl ||
                            "/images/dummy-user.jpg"
                          }
                          alt=""
                          className="surafce-img"
                          style={{ width: "60px", height: "60px" }}
                        />
                      </Box>
                      <Box className="text-end">
                        <h2 className="font-semibold text-zinc-700 sm:text-md xl:text-lg">
                          {`${supplyDetails?.supplier?.fname} ${supplyDetails?.supplier?.lname}`}
                        </h2>
                        <p className="flex text-end sm:text-xs xl:text-sm">
                          <FaStar className="text-yellow-400 star-icon me-1" />{" "}
                          <span className="me-1">
                            {supplyDetails?.supplier?.avgRating?.toFixed(2) ||
                              "0"}
                          </span>
                          Rating
                        </p>
                        <p className="text-zinc-400 md:text-xs xl:text-sm">
                          Owner
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Divider className="!my-4" />
                  <Box className="text-start pb-0">
                    <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                      Partners
                    </h1>
                  </Box>
                  <Box className="flex text-start">
                    {supplyDetails?.businessProfile?.businessPartners?.map(
                      (partner) => {
                        return (
                          <Box className="flex flex-col items-center me-3">
                            <img
                              src={
                                partner?.profileUrl || "/images/dummy-user.jpg"
                              }
                              alt=""
                              style={{ height: "50px", width: "50px" }}
                            />
                            <p className="text-medium">{`${
                              partner?.fname || " "
                            } ${partner?.lname}`}</p>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6} xl={3}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4">
                  <Box>
                    <Box className="flex justify-between">
                      <h1 className="font-semibold md:text-lg xl:text-xl">
                        Total Revenue
                      </h1>
                      <p
                        className="font-semibold rounded-md cursor-pointer text-medium"
                        style={{
                          backgroundColor: "#F7F8FA",
                          padding: "5px 10px",
                        }}
                        onClick={() =>
                          navigate("/supply/profile/order-details", {
                            state: location.state,
                          })
                        }
                      >
                        Goto Orders {">"}
                      </p>
                    </Box>
                    <Box className="p-3 flex justify-between">
                      <Box>
                        <h2 className="font-semibold text-zinc-800 mb-4 md:text-2xl xl:text-3xl">
                          $ {supplyDetails?.totalAmount}
                        </h2>
                        <Box className="flex items-center">
                          <p
                            className="text-green-400 flex p-1 me-2 text-medium"
                            style={{
                              backgroundColor: "#F2FBF3",
                              color: "#00B812",
                            }}
                          >
                            <span>
                              <FaArrowUpLong />
                            </span>
                            6%
                          </p>
                          <p
                            className="text-medium"
                            style={{ color: "#00B812" }}
                          >
                            last month
                          </p>
                        </Box>
                      </Box>
                      <Box>
                        <img src="/images/graph.png" alt="" />
                      </Box>
                    </Box>
                  </Box>
                  <Divider className="!my-4" />
                  <Box>
                    <Box className="flex justify-between">
                      <h1 className="font-semibold md:text-lg xl:text-xl">
                        Total Orders
                      </h1>
                    </Box>
                    <Box className="p-3 flex justify-between">
                      <Box>
                        <h2 className="font-semibold text-zinc-800 mb-4 md:text-2xl xl:text-3xl">
                          {supplyDetails?.orderCount}
                        </h2>
                        <Box className="flex items-center">
                          <p
                            className="text-green-400 flex p-1 me-2 text-medium"
                            style={{
                              backgroundColor: "#F2FBF3",
                              color: "#00B812",
                            }}
                          >
                            <span>
                              <FaArrowUpLong />
                            </span>
                            6%
                          </p>
                          <p
                            className="text-medium"
                            style={{ color: "#00B812" }}
                          >
                            last month
                          </p>
                        </Box>
                      </Box>
                      <Box>
                        <img src="/images/graph.png" alt="" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4">
                  <Box className="mb-3 flex justify-between items-center">
                    <h1 className="font-semibold md:text-lg xl:text-xl">
                      List of Seeker
                    </h1>
                    <p
                      className="text-zinc-400 underline cursor-pointer text-medium"
                      onClick={() => {
                        console.log(
                          seekerDetailsState?.listOfSeeker,
                          "supplyDetails?.listOfSeeker"
                        );
                        toggleHandler(seekerDetailsState?.listOfSeeker);
                      }}
                    >
                      View all
                    </p>
                  </Box>
                  <Grid container spacing={2} alignItems={"center"}>
                    {Object.keys(seekerDetailsState?.currentSeeker).length ===
                    0 ? (
                      <Grid
                        item
                        xs={12}
                        className="flex-1 text-center items-center justify-center"
                      >
                        <p className="text-md">No Seeker</p>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={4}>
                          <Box className="flex items-center">
                            <img
                              src={
                                seekerDetailsState?.currentSeeker?.user
                                  ?.profileUrl || "/images/dummy-user.jpg"
                              }
                              alt=""
                              className="seeker-img rounded-full"
                            />
                            <Box className="ms-2">
                              <h3 className="text-zinc-700 font-semibold md:text-md xl:text-lg">
                                {`${
                                  seekerDetailsState?.currentSeeker?.user
                                    ?.fname || ""
                                } ${
                                  seekerDetailsState?.currentSeeker?.user
                                    ?.lname || ""
                                }`}
                              </h3>
                              <Box className="flex text-zinc-700">
                                <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                                <h1 className="text-medium">
                                  <span>
                                    {seekerDetailsState?.currentSeeker?.user?.avgRating?.toFixed(
                                      2
                                    ) || "0"}
                                  </span>
                                </h1>
                              </Box>
                              <span className="text-zinc-400 text-medium">
                                Current Seeker
                              </span>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box className="flex items-center">
                            <Box className="me-2 text-end">
                              <h3 className="text-zinc-700  text-medium">
                                {seekerDetailsState?.currentSeeker
                                  ?.businessProfile?.name || ""}
                              </h3>
                              <span className="text-zinc-400 text-medium">
                                {seekerDetailsState?.currentSeeker
                                  ?.businessProfile?.state || ""}
                              </span>
                              {/* <Box className="flex text-zinc-700">
                            <p className="text-zinc-400 md:text-xs xl:text-sm">
                              <span>01 Oct ‘23</span> - <span>14 Oct ‘23</span>
                            </p>
                          </Box> */}
                            </Box>
                            <img
                              className="rounded-lg seeker-img"
                              src={
                                seekerDetailsState?.currentSeeker
                                  ?.businessProfile?.profileUrl ||
                                "/images/store.png"
                              }
                              alt=""
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            {seekerDetailsState?.listOfSeeker
                              ?.slice(0, 3)
                              ?.map((seeker) => {
                                return (
                                  <Box className="flex items-center md:mb-1 xl:mb-2">
                                    <img
                                      src={
                                        seeker?.user?.profileUrl ||
                                        "/images/dummy-user.jpg"
                                      }
                                      alt=""
                                      className="seeker-small-img rounded-full"
                                    />
                                    <Box className="ms-2">
                                      <p className="text-medium">
                                        {`${seeker?.user?.fname} ${seeker?.user?.lname}`}{" "}
                                        <span className="text-zinc-400">
                                          {seeker?.businessProfile?.name || ""}
                                        </span>
                                      </p>
                                      <p className="flex me-2 items-center text-medium">
                                        <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />{" "}
                                        <span>
                                          {seeker?.user?.avgRating?.toFixed(
                                            2
                                          ) || "0"}
                                        </span>
                                      </p>
                                    </Box>
                                  </Box>
                                );
                              })}

                            {/* <Box className="flex items-center md:mb-1 xl:mb-2">
                          <img
                            src="/images/dummy-user.jpg"
                            alt=""
                            className="seeker-small-img"
                          />
                          <Box className="ms-2">
                            <p className="text-medium">
                              Ben Parker{" "}
                              <span className="text-zinc-400">Coffee Shop</span>
                            </p>
                            <p className="flex me-2">
                              <FaStar className="text-yellow-400 w-5 h-5 me-1" />{" "}
                              <span>4.7</span>
                            </p>
                          </Box>
                        </Box>
                        <Box className="flex">
                          <img
                            src="/images/dummy-user.jpg"
                            alt=""
                            className="seeker-small-img"
                          />
                          <Box className="ms-2">
                            <p className="text-medium">
                              Ben Parker{" "}
                              <span className="text-zinc-400">Coffee Shop</span>
                            </p>
                            <p className="flex me-2 items-center text-medium">
                              <FaStar className="text-yellow-400 w-5 h-5 me-1" />{" "}
                              <span>4.7</span>
                            </p>
                          </Box>
                        </Box> */}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4">
                  <Box className="mb-3 flex justify-between items-center">
                    <h1 className="font-semibold md:text-lg xl:text-xl">
                      List of Sharer
                    </h1>
                    {supplyDetails?.sharerList?.length > 0 && (
                      <p
                        className="text-zinc-400 underline cursor-pointer text-medium"
                        onClick={() =>
                          toggleHandler2(supplyDetails?.sharerList)
                        }
                      >
                        View all
                      </p>
                    )}
                  </Box>
                  <Grid container spacing={2} alignItems={"center"}>
                    {Object.keys(seekerDetailsState?.currentSeeker).length ===
                    0 ? (
                      <Grid
                        item
                        xs={12}
                        className="flex-1 text-center items-center justify-center"
                      >
                        <p className="text-md">No Sharer</p>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={4}>
                          {supplyDetails?.sharerList?.length > 0 && (
                            <Box className="flex items-center">
                              <img
                                src={
                                  supplyDetails?.sharerList[0]?.sharerUser
                                    ?.profileUrl || "/images/dummy-user.jpg"
                                }
                                alt=""
                                className="seeker-img rounded-full"
                              />
                              <Box className="ms-2">
                                <h3 className="text-zinc-700 font-semibold md:text-md xl:text-lg">
                                  {`${
                                    supplyDetails?.sharerList[0]?.sharerUser
                                      ?.fname || ""
                                  } ${
                                    supplyDetails?.sharerList[0]?.sharerUser
                                      ?.lname || ""
                                  }`}
                                </h3>
                                <Box className="flex text-zinc-700">
                                  <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                                  <h1 className="text-medium">
                                    <span>
                                      {supplyDetails?.sharerList[0]?.sharerUser?.avgRating?.toFixed(
                                        2
                                      ) || "0"}
                                    </span>
                                  </h1>
                                </Box>
                                <span className="text-zinc-400 text-medium">
                                  Current Supplier
                                </span>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {supplyDetails?.sharerList?.length > 0 && (
                            <Box className="flex items-center">
                              <Box className="me-2 text-end">
                                <h3 className="text-zinc-700 text-medium">
                                  {
                                    supplyDetails?.sharerList[0]
                                      ?.businessProfile?.name
                                  }
                                </h3>
                                <span className="text-zinc-400 text-medium">
                                  {
                                    supplyDetails?.sharerList[0]
                                      ?.businessProfile?.state
                                  }
                                </span>
                                {/* <Box className="flex text-zinc-700">
                              <p className="text-zinc-400 md:text-xs xl:text-sm">
                                <span>01 Oct ‘23</span> -{" "}
                                <span>14 Oct ‘23</span>
                              </p>
                            </Box> */}
                              </Box>
                              <img
                                className="rounded-lg seeker-img"
                                src={
                                  supplyDetails?.sharerList[0]?.businessProfile
                                    ?.profileUrl || "/images/store.png"
                                }
                                alt=""
                                // style={{ width: "65px", height: "65px" }}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            {supplyDetails?.sharerList?.length > 1 &&
                              supplyDetails?.sharerList
                                ?.slice(1, 4)
                                ?.map((sharer) => (
                                  <Box className="flex items-center md:mb-1 xl:mb-2">
                                    <img
                                      src={
                                        sharer?.sharerUser?.profileUrl ||
                                        "/images/dummy-user.jpg"
                                      }
                                      alt=""
                                      className="seeker-small-img rounded-full"
                                    />
                                    <Box className="ms-2">
                                      <p className="text-medium">
                                        {`${sharer?.sharerUser?.fname || ""} ${
                                          sharer?.sharerUser?.lname || ""
                                        }`}{" "}
                                        <span className="text-zinc-400">
                                          {sharer?.businessProfile?.name || ""}
                                        </span>
                                      </p>
                                      <p className="flex items-center me-2">
                                        <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />{" "}
                                        <span>
                                          {sharer?.sharerUser?.avgRating?.toFixed(
                                            2
                                          ) || "0"}
                                        </span>
                                      </p>
                                    </Box>
                                  </Box>
                                ))}
                            {/* <Box className="flex items-center md:mb-1 xl:mb-2">
                          <img
                            src="/images/dummy-user.jpg"
                            alt=""
                            className="seeker-small-img"
                          />
                          <Box className="ms-2">
                            <p className="text-medium">
                              Ben Parker{" "}
                              <span className="text-zinc-400">Coffee Shop</span>
                            </p>
                            <p className="flex items-center me-2">
                              <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />{" "}
                              <span>4.7</span>
                            </p>
                          </Box>
                        </Box>
                        <Box className="flex items-center md:mb-1 xl:mb-2">
                          <img
                            src="/images/dummy-user.jpg"
                            alt=""
                            className="seeker-small-img"
                          />
                          <Box className="ms-2">
                            <p className="text-medium">
                              Ben Parker{" "}
                              <span className="text-zinc-400">Coffee Shop</span>
                            </p>
                            <p className="flex items-center me-2">
                              <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />{" "}
                              <span className="text-medium">4.7</span>
                            </p>
                          </Box>
                        </Box> */}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} xl={7}>
              <Card>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Reviews
                  </h1>
                  <Grid container justifyContent={"space-between"} spacing={2}>
                    <Grid item xs={4}>
                      <Box className="flex text-zinc-700 my-3">
                        <FaStar className="text-yellow-400 w-6 h-6 me-2" />
                        <h1 className="md:text-lg xl:text-xl">
                          <span>{avarageRating || "0"}</span> |{" "}
                          <span>{total_data}</span> Reviews
                        </h1>
                      </Box>
                      <Box className="mb-5">
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>5 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[5]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[5]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>4 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[4]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[4]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>3 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[3]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[3]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>2 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[2]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[2]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>1 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[1]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[1]}%</p>
                          </Grid>
                        </Grid>
                      </Box>
                      {Reviews?.length > 0 && (
                        <Box
                          className="flex items-center justify-around text-white rounded-full cursor-pointer flagg-btn"
                          style={{
                            backgroundColor: redFlag ? "#F44646" : "#FFFFFF",
                            border: !redFlag ? "1px solid #F44646" : "",
                            padding: "5px",
                            color: redFlag ? "#FFFFFF" : "#F44646",
                          }}
                          onClick={() => {
                            setRedFlag((prev) => !prev);
                          }}
                        >
                          <IoFlagOutline className="w-7 h-7 ms-2 me-1" />
                          <p className="mx-1">Flagged</p>
                          <span
                            className="bg-white inline-block rounded-full"
                            style={{
                              padding: "2px 9px",
                              backgroundColor: redFlag ? "#FFFFFF" : "#F44646",
                              color: redFlag ? "#F44646" : "#FFFFFF",
                            }}
                          >
                            2
                          </span>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={7}>
                      <Box className="flex justify-end mb-3 text-medium">
                        <p className="me-3 font-semibold text-zinc-600">
                          Filter By:
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "1 month"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("1 month")}
                        >
                          1 month
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "3 months"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("3 months")}
                        >
                          3 months
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "12 months"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("12 months")}
                        >
                          12 months
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "lifetime"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("lifetime")}
                        >
                          Lifetime
                        </p>
                      </Box>
                      <Box className="flex flex-col justify-center items-center">
                        {Reviews?.slice(0, 2)?.map((review) => {
                          return (
                            <ReviewSection data={review} isRedFlag={redFlag} />
                          );
                        })}
                        {expanded &&
                          Reviews?.slice(2, Reviews?.length)?.map((review) => {
                            return (
                              <ReviewSection
                                data={review}
                                isRedFlag={redFlag}
                              />
                            );
                          })}
                        {Reviews?.length > 2 &&
                          (expanded ? (
                            <IoChevronUpSharp
                              className="w-10 h-10 cursor-pointer"
                              onClick={() => setExpanded((prev) => !prev)}
                            />
                          ) : (
                            <IoChevronDownSharp
                              className="w-10 h-10 cursor-pointer"
                              onClick={() => setExpanded((prev) => !prev)}
                            />
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} xl={5}>
              <Card>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Latest Activity
                  </h1>
                  <Box className="flex flex-col justify-between items-center">
                    <StepperComp
                      steps={
                        loadMoreActivity
                          ? ActivitySteps
                          : ActivitySteps?.slice(3)
                      }
                    />
                    {loadMoreActivity ? (
                      <IoChevronUpSharp
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => setLoadMoreActivity((prev) => !prev)}
                      />
                    ) : (
                      <IoChevronDownSharp
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => setLoadMoreActivity((prev) => !prev)}
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AllDetailsModalSeeker
        open={open.showModal}
        toggleHandler={() => toggleHandler()}
        title={"List of Seeker"}
        data={open.data}
        currentSeeker={seekerDetailsState?.currentSeeker}
      />
      <AllDetailsModalSharer
        open={open2.showModal}
        toggleHandler={() => toggleHandler2()}
        title={"List of Sharer"}
        data={open2.data || []}
        currentSharer={supplyDetails?.sharerList?.[0]}
      />
    </>
  );
};

export default SupplyWiseProfile;
