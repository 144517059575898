import { Box } from "@mui/material";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import moment from "moment/moment";

export const generateColumns = () => {
  return [
    {
      id: "image",
      label: "Image",
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img src={item?.image} alt="image" />
          </Box>
        );
      },
    },
    {
      id: "supplyCode",
      numeric: false,
      disablePadding: true,
      label: "Supply Code",
    },
    {
      id: "supplyName",
      numeric: false,
      disablePadding: true,
      label: "Supply Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: true,
      label: "Rating",
      renderCell: (item) => {
        return (
          <Box className="flex items-center">
            <MdOutlineStarPurple500 className="text-yellow-500 w-5 h-5 me-2" />
            <p>{item?.rating}</p>
          </Box>
        );
      },
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: "hostBusiness",
      numeric: false,
      disablePadding: true,
      label: "Host Business",
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src="/images/Resturent.png"
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{item.hostBusiness.hostBusinessName}</p>
              <p className="text-zinc-400 text-sm">
                {item.hostBusiness.hostBusinessPlace}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "sharer",
      numeric: false,
      disablePadding: true,
      label: "Sharer",
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex">
            <img
              src="/images/dummy-user.jpg"
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{item.supplier.supplierName}</p>
              <p className="text-zinc-400 text-sm">
                {item.supplier.supplierCompany}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "totalOrders",
      numeric: false,
      disablePadding: true,
      label: "Total Orders",
      renderCell: (item) => {
        return `$${item?.totalOrders}`;
      },
    },
  ];
};

export const SupplyData = [
  {
    image: "/images/Cups.png",
    supplyCode: "S001",
    supplyName: "Supply Name",
    category: "Category",
    rating: "5.3",
    status: "Active",
    hostBusiness: {
      hostBusinessName: "Tony’s Cafe",
      hostBusinessPlace: "New York",
    },
    supplier: {
      supplierName: "Jeff Anderson",
      supplierCompany: "The Print Shop",
    },
    totalOrders: 1256,
  },
];

export const generateBusinessWiseColumns = () => {
  return [
    {
      id: "image",
      label: "Image",
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img src={item?.image} alt="image" />
          </Box>
        );
      },
    },
    {
      id: "supplyCode",
      numeric: false,
      disablePadding: true,
      label: "Supply Code",
    },
    {
      id: "supplyName",
      numeric: false,
      disablePadding: true,
      label: "Supply Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
      renderCell: (item) => {
        return (
          <Box
            className="flex p-1 rounded-md"
            style={{ backgroundColor: "#FCF0C6" }}
          >
            <FoodBar className="me-2" id="food" />
            <p>{item?.category}</p>
          </Box>
        );
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: true,
      label: "Create Date",
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: true,
      label: "Update Date",
    },
    {
      id: "owner",
      numeric: false,
      disablePadding: true,
      label: "Owner",
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex items-center">
            <img
              src="/images/dummy-user.jpg"
              alt=""
              className="rounded-full me-2"
              style={{ width: "35px", height: "35px" }}
            />
            <p className="text-md">{item.owner.ownerName}</p>
          </Box>
        );
      },
    },
  ];
};

export const BusinessWiseData = [
  {
    image: "/images/Resturent.png",
    supplyCode: "BU19237",
    supplyName: "Tony’s Cafe",
    category: "Cafe",
    createDate: "2023-08-02 12:03:45",
    updateDate: "2023-08-22 20:36:12",
    owner: {
      ownerName: "Ben Parker",
    },
  },
];

export const orderDetailsColumns = (dateFormat) => {
  return [
    {
      label: "Order No.",
      field: "orderId",
    },
    {
      label: "Order Date",
      field: "createdAt",
      renderCell: (item) => {
        return (
          item?.createdAt &&
          moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY")
        );
      },
    },
    {
      label: "Surface",
      field: "surface.name",
      renderCell: (item) => {
        return item?.surface?.name;
      },
    },
    {
      label: "Seeker",
      field: "user.fname",
      renderCell: (item) => {
        return `${item?.user?.fname || ""} ${item?.user?.lname || ""}`;
      },
    },
    {
      label: "Sharer",
      field: "sharerUser.fname",
      renderCell: (item) => {
        return `${item?.sharerUser?.fname || ""} ${
          item?.sharerUser?.lname || ""
        }`;
      },
    },
    {
      label: "Supplier",
      field: "supplyUser.fname",
      renderCell: (item) => {
        return `${item?.supplyUser?.fname || ""} ${
          item?.supplyUser?.lname || ""
        }`;
      },
    },
    {
      label: "Prep Period",
      field: "prepStartDate",
      renderCell: (item) => {
        return `${
          item?.prepStartDate
            ? moment(item?.prepStartDate).format("DD/MM/YYYY")
            : ""
        } - ${
          item?.prepEndDate
            ? moment(item?.prepEndDate).format("DD/MM/YYYY")
            : ""
        }`;
      },
    },
    {
      label: "Total",
      field: "paymentDetails.orderTotal",
      renderCell: (item) => {
        return `$${item?.paymentDetails?.orderTotal?.toFixed(2)}`;
      },
    },
    // {
    //   title: "Action",
    //   renderCell: (item) => (
    //     <div>
    //       <ButtonComponent
    //         btnText="Edit"
    //         btnClass={"btn-warning"}
    //         onClick={() => {}}
    //       />
    //       <ButtonComponent
    //         btnText="Delete"
    //         btnClass={"btn-danger"}
    //         onClick={() => {}}
    //       />
    //     </div>
    //   ),
    // },
  ];
};
export const revenueOrderDetailsColumns = (dateFormat) => {
  return [
    {
      label: "Order No.",
      field: "orderNo",
    },
    {
      label: "Order Date",
      field: "orderDate",
      // renderCell: (item) => {
      //   return (
      //     item?.createdAt &&
      //     moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY")
      //   );
      // },
    },
    {
      label: "Order Type",
      field: "orderType",
      // renderCell: (item) => {
      //   return item?.surface?.name;
      // },
    },
    {
      label: "Surface/Supply",
      field: "supply",
      // renderCell: (item) => {
      //   return `${item?.user?.fname || ""} ${item?.user?.lname || ""}`;
      // },
    },
    {
      label: "User",
      field: "user",
      // renderCell: (item) => {
      //   return `${item?.sharerUser?.fname || ""} ${
      //     item?.sharerUser?.lname || ""
      //   }`;
      // },
    },
    {
      label: "Total",
      field: "total",
      // renderCell: (item) => {
      //   return `$${item?.paymentDetails?.orderTotal?.toFixed(2)}`;
      // },
    },
  ];
};

export const revenueOrderDetailsData = [
  {
    id: 1,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    orderType: "Reservation",
    supply: "Paper Cups",
    user: "Ben Parker (Sharer)",
    total: "$20.00",
  },
  {
    id: 2,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    orderType: "Reservation",
    supply: "Paper Cups",
    user: "Ben Parker (Sharer)",
    total: "$20.00",
  },
];
export const orderDetailsData = [
  {
    id: 1,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    surface: "Paper Cups",
    seeker: "Ben Parker",
    sharer: "Maria Joyce",
    supplier: "Tony  Stark",
    prepPeriod: "4 Sept - 8 Sept, 2023",
    total: "$120.05",
  },
  {
    id: 2,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    surface: "Paper Cups",
    seeker: "Ben Parker",
    sharer: "Maria Joyce",
    supplier: "Tony  Stark",
    prepPeriod: "4 Sept - 8 Sept, 2023",
    total: "$120.05",
  },
];
