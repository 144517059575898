import React from "react";
import ReactQuill from "react-quill";
import { get } from "lodash";

const ReactQuillComp = ({ formik, name, isShowError = true, errorMessage }) => {
  const { values, touched, errors, setFieldValue, handleBlur } = formik || {};
  const isError = !!get(touched, name, "") && !!get(errors, name, "");
  console.log(errors, "errors");
  return (
    <div className="react-quill">
      <ReactQuill
        theme="snow"
        value={get(values, name, "") || ""}
        onChange={(e) => {
          setFieldValue(name, e);
        }}
      />
      {isShowError && (isError || !!errorMessage) && (
        <div className="h-2 text-xs text-red-500">
          {get(touched, name, "") ? get(errors, name, "") || errorMessage : ""}
        </div>
      )}
    </div>
  );
};

export default ReactQuillComp;
