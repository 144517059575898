import { Route, Routes } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import Dashboard from "../components/Dashboard";
import Account from "../components/Account";
import AccountProfile from "../components/Account/AccountProfile";
import AccountOrdersDetails from "../components/Account/AccountOrdersDetails";
import Surface from "../components/Surface";
import SurfaceWiseProfile from "../components/Surface/SurfaceWiseProfile";
import { BusinessWiseProfile as SurfaceBusinessWiseProfile } from "../components/Surface/BusinessWiseProfile";
import { BusinessWiseProfile as SupplyBusinessWiseProfile } from "../components/Supply/BusinessWiseProfile";
import SurfaceOrdersDetails from "../components/Surface/SurfaceOrdersDetails";
import Supply from "../components/Supply";
import SupplyWiseProfile from "../components/Supply/SupplyWiseProfile";
import SupplyOrdersDetails from "../components/Supply/SupplyOrdersDetails";
import Reservation from "../components/Reservation";
import ReservationProfile from "../components/Reservation/ReservationProfile";
import Setting from "../components/Setting";
import Login from "../components/Login";
import PageNotFound from "../components/404";

const RouteProvider = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(userDetails());
  // }, []);
  const authRoutes = [
    {
      path: "/",
      component: <Dashboard />,
    },
    // {
    //   path: "/dashboard",
    //   component: <Dashboard />,
    // },
    {
      path: "/account",
      component: <Account />,
    },
    {
      path: "/account/profile",
      component: <AccountProfile />,
    },
    {
      path: "/account/profile/order-details",
      component: <AccountOrdersDetails />,
    },
    {
      path: "/surface",
      component: <Surface />,
    },
    {
      path: "/surface/surfacewise-profile",
      component: <SurfaceWiseProfile />,
    },
    {
      path: "/surface/businesswise-profile",
      component: <SurfaceBusinessWiseProfile />,
    },
    {
      path: "/surface/profile/order-details",
      component: <SurfaceOrdersDetails />,
    },
    {
      path: "/supply",
      component: <Supply />,
    },
    {
      path: "/supply/supplywise-profile",
      component: <SupplyWiseProfile />,
    },
    {
      path: "/supply/businesswise-profile",
      component: <SupplyBusinessWiseProfile />,
    },
    {
      path: "/supply/profile/order-details",
      component: <SupplyOrdersDetails />,
    },
    {
      path: "/reports",
      component: <Reservation />,
    },
    {
      path: "/reports/reservations-profile",
      component: <ReservationProfile />,
    },
    {
      path: "/settings",
      component: <Setting />,
    },
  ];
  // console.log(authRoutes, "authRoutes");
  return (
    <Routes>
      <Route exact={true} path="/login" element={<Login />} />
      {authRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={true}
          element={<AuthRoutes>{route.component}</AuthRoutes>}
        />
      ))}
      {/* <Route
        exact={true}
        path="/forgot-password"
        element={<ForgotPassword />}
      /> */}
      {/* <Route exact={true} path="/reset-password" element={<ResetPassword />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteProvider;
