import "./App.css";
import Button from "@mui/material/Button";
import SidebarWithHeader from "./components/common/Sidebar";
import RouteProvider from "./routes";
import "./css/index.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { onMessageListener, requestForToken } from "./firebaseConfig";
import { useEffect } from "react";
import Notification from "./components/Notification";

function App({ children }) {
  // useEffect(() => {
  //   console.log("how are you");
  //   requestForToken();
  //   onMessageListener()
  //     .then((payload) => {
  //       console.log(payload, "payload");
  //       // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // }, []);
  return (
    <div className="App">
      {/* <SidebarWithHeader> */}
      {/* <h1 className="text-3xl font-bold underline">Hello world!</h1>
      <Button variant="contained" color="primary">
        Click me Material-UI!
      </Button> */}
      <RouteProvider />
      <ToastContainer />
      <Notification />
      {/* </SidebarWithHeader> */}
    </div>
  );
}

export default App;
