import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";

const UserChart = ({ chartData }) => {
  // const
  // useEffect(()=>{
  //     if(chartData){

  //     }
  // },[chartData])
  const series = [
    {
      data:
        chartData?.userCountByState?.length > 0
          ? chartData?.userCountByState?.map((d) => d.totalUsers)
          : [],
    },
  ];
  var options = {
    chart: {
      type: "bar",
      height: 380,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors:
      chartData?.userCountByState.length > 0
        ? chartData?.userCountByState?.map((d, index) =>
            index % 2 === 0 ? "#85ABBD" : "#F9DD7B"
          )
        : [],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000000", "#47758B"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
    },
    xaxis: {
      categories:
        chartData?.userCountByState.length > 0
          ? chartData?.userCountByState?.map((d) => d?._id || "")
          : [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    // tooltip: {
    //   theme: "dark",
    //   x: {
    //     show: false,
    //   },
    //   y: {
    //     title: {
    //       formatter: function () {
    //         return "";
    //       },
    //     },
    //   },
    // },
  };
  return (
    <Box>
      <Chart options={options} series={series} type="bar" height={350} />
    </Box>
  );
};

export default UserChart;
