import { Box, Card } from "@mui/material";
import React from "react";

export const StatisticCard = ({
  hasSubText = true,
  subText,
  borderColor,
  allBorder,
  title,
  text,
  cardClass,
  titleClass,
  textClass,
  onClick,
}) => {
  console.log(text, "text");
  return (
    <Card
      className={`p-4 ${cardClass}`}
      sx={{
        // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "10px",
        width: "320px",
        maxWidth: "100%",
        borderBottom: borderColor ? `2px solid ${borderColor}` : "",
        borderTop: allBorder ? `2px solid ${borderColor}` : "",
        borderLeft: allBorder ? `2px solid ${borderColor}` : "",
        borderRight: allBorder ? `2px solid ${borderColor}` : "",
      }}
      onClick={onClick ? onClick : () => {}}
    >
      <h3 className={`${titleClass}`}>{title}</h3>
      <Box className="flex justify-between items-center">
        {(hasSubText && subText) || <span>{""}</span>}
        <h2 className={`${textClass}`}>{text || " "}</h2>
      </Box>
    </Card>
  );
};
