import { Box } from "@mui/material";
import React from "react";

const ImageViewer = ({ wrapperClass, url }) => {
  return (
    <Box
      className={`profile-image flex items-center justify-center ${wrapperClass}`}
    >
      <img
        src={url || "/images/profile.png"}
        alt="logo"
        style={{ width: "100px", height: "100px", borderRadius: "50%" }}
      />
    </Box>
  );
};

export default ImageViewer;
