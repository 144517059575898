import React, { useEffect, useState } from "react";
import { StatisticCard } from "../../common/StatisticCard";
import CustomTableContainer from "../../common/Table/CustomTableContainer";
import SearchInput from "../../common/Input/TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import ButtonComponent from "../../common/Button";
import { helpSupportColumn, helpSupportData } from "../helper";
import FAQpage from "./FAQpage";
import { getHelpSupport } from "../../../store/settings/helpsupport.slice";
import SupportTicket from "./SupportTicket";

const HelpSupport = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState("listing");

  const tabChangeHandler = (tabName) => {
    console.log(tabName, "tabName");
    setTab(tabName);
  };
  return tab === "FAQEditor" ? (
    <FAQpage tabChangeHandler={tabChangeHandler} />
  ) : (
    <SupportTicket tabChangeHandler={tabChangeHandler} />
  );
};

export default HelpSupport;
