import { Box } from "@mui/material";
import React from "react";
const messages = [
  {
    id: 0,
    sender: "xyz",
    message: `Hey guys,\n
        Looking forward to have a good buisness here
    `,
    receiver: "xyz",
    time: "11:39 am",
  },
  {
    id: 1,
    sender: "abc",
    message: "Hi",
    receiver: "xyz",
    time: "11:39 am",
  },
  {
    id: 2,
    sender: "xyz",
    message: "Hi",
    receiver: "abc",
    time: "11:39 am",
  },
  {
    id: 3,
    sender: "abc",
    message: "Hi",
    receiver: "xyz",
    time: "11:39 am",
  },
  {
    id: 4,
    sender: "abc",
    message: "Hi",
    receiver: "xyz",
    time: "11:39 am",
  },
  {
    id: 5,
    sender: "xyz",
    message: "Hi",
    receiver: "abc",
    time: "11:39 am",
  },
];
const ChatRoom = () => {
  return (
    <Box>
      {messages.map((mess) => {
        return (
          <Box
            className={`flex p-2 ${mess.sender === "abc" ? "justify-end" : ""}`}
          >
            <span className="me-2">
              <img
                src="/images/dummy-user.jpg"
                alt=""
                className="chat-profile-img"
              />
            </span>
            <Box
              className="p-2 rounded-lg"
              style={{
                minWidth: "150px",
                maxWidth: "60%",
                backgroundColor: mess.sender === "abc" ? "#EEF9FF" : "white",
              }}
            >
              <p className="text-start text-medium">{mess.message}</p>
              <p className="text-end text-zinc-400 text-xs">{mess.time}</p>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChatRoom;
