import { Box, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { GrAttachment } from "react-icons/gr";
import { HiOutlineMicrophone } from "react-icons/hi2";
import { IoMdSend } from "react-icons/io";
import { db, storage } from "../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import moment from "moment";
import { getListener } from "../../fn";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Loading from "../common/Loading";

const MessageDialogBox = ({ open, toggle, toggleCollapse, data }) => {
  console.log(data, "data in messagedialog");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [urls, setUrls] = useState([]);
  const { loginUserDetails } = useSelector((state) => state.auth);
  const fileInputRef = useRef(null);
  const storageRef = ref(storage, "images");
  // useEffect(async () => {
  //   console.log(db, "db in dagav");
  //   const docSnap = await getDocs(
  //     doc(db, "messages", "662f3fa10d54572f08f16eb7-66347a4ad9324fb9c4cac107")
  //   );
  //   if (docSnap.exists()) {
  //     // Document data
  //     console.log(docSnap.id, "doc.id"); // log the document ID
  //     console.log(docSnap.data(), "doc.data"); // log the document data
  //   } else {
  //     console.warn("No such document!");
  //   }
  // }, []);

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        console.log(db, "db in dagav");
        const docRef = collection(
          db,
          `messages/${getListener(
            loginUserDetails?._id,
            data?._id
          )}/${getListener(loginUserDetails?._id, data?._id)}`
        );
        const docSnap = await getDocs(docRef);
        console.log(docSnap, "dodocSnapc"); // log the document ID
        if (!docSnap.empty) {
          let data = [];
          docSnap.docs.forEach((item) => data.push(item.data()));
          console.log(data, "item.data()");
          setMessages(data);
        } else {
          console.warn("No such document!");
        }
      };
      fetchData(); // Call the async function immediately
    }
  }, [open, loginUserDetails, data]);
  useEffect(() => {
    const unsub = onSnapshot(
      collection(
        db,
        `messages/${getListener(
          loginUserDetails?._id,
          data?._id
        )}/${getListener(loginUserDetails?._id, data?._id)}`
      ),
      (docSnap) => {
        if (!docSnap.empty) {
          let data = [];
          docSnap.docs.forEach((item) => data.push(item.data()));
          console.log(data, "item.data()");

          setMessages(data);
        } else {
          console.warn("No such document!");
        }
      }
    );

    return () => {
      unsub();
    };
  }, [loginUserDetails, data]);
  console.log(messages, "data asdef");
  return (
    <Box
      className="flex flex-col rounded-2xl message-modal"
      style={{
        // width: "400px",
        // height: "600px",
        // backgroundColor: "#F9DD7B",
        display: open ? "flex" : "none",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Box className="flex justify-between items-center p-2">
        <Box
          className="flex items-center cursor-pointer"
          onClick={toggleCollapse}
        >
          {/* <span
            className="p-2 rounded-full"
            style={{ backgroundColor: "#E2DAFD" }}
          > */}
          <img
            src={loginUserDetails?.profileUrl || "/images/dummy-user.jpg"}
            alt=""
            style={{ width: "40px", borderRadius: "100%" }}
          />
          {/* </span> */}
          <h1 className="text-white ms-2">{`${loginUserDetails?.fname} ${loginUserDetails?.lname}`}</h1>
        </Box>
        <IoClose
          className="w-7 h-7 text-white cursor-pointer"
          onClick={toggle}
        />
      </Box>
      <Box
        className="rounded-2xl flex flex-col flex-1"
        style={{ width: "100%", height: "350px", backgroundColor: "white" }}
      >
        <Box className="flex-1 overflow-y-scroll" style={{ height: "200px" }}>
          {messages?.map((mess) => {
            return (
              <Box
                className={`flex p-2 ${
                  mess.idFrom === loginUserDetails?._id ? "justify-end" : ""
                }`}
              >
                <span className="me-2">
                  <img
                    src={
                      (mess.idFrom === loginUserDetails?._id
                        ? mess?.dataFrom?.image
                        : mess?.dataTo?.image) || "/images/dummy-user.jpg"
                    }
                    alt=""
                    className="chat-profile-img rounded-full"
                    style={{ width: "30px", height: "30px" }}
                  />
                </span>
                <Box
                  className="rounded-lg p-2"
                  style={{
                    minWidth: "150px",
                    maxWidth: "60%",
                    backgroundColor:
                      mess.sender === "abc" ? "#FCF0C6" : "#FCF0C6",
                  }}
                >
                  {mess?.contentType === 3 ? (
                    <img src={mess.content} alt="" />
                  ) : (
                    <p className="text-start text-medium">{mess.content}</p>
                  )}
                  <p className="text-end text-zinc-400 text-xs">
                    {moment(mess.timestamp).format("HH:MM")}
                    {mess.read || mess.idFrom === loginUserDetails?._id
                      ? "seen"
                      : "unseen"}
                  </p>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          className="p-2 rounded-full m-2"
          style={{ backgroundColor: "#F7F8FA" }}
        >
          <Box className="flex items-center p-2">
            <input
              type="file"
              ref={fileInputRef}
              hidden
              onChange={(event) => {
                {
                  // fileInputRef.current?.files?.length > 0 &&
                  //   fileInputRef.current.files?.map((file) => {
                  uploadBytes(
                    ref(
                      storage,
                      `images/${fileInputRef.current.files[0].name}`
                    ),
                    fileInputRef.current.files[0]
                  ).then((snapshot) => {
                    getDownloadURL(
                      ref(
                        storage,
                        `images/${fileInputRef.current.files[0]?.name}`
                      )
                    ).then((url) => {
                      setUrls((prev) => [...prev, url]);
                      console.log(url, "urls ut;e");
                    });
                    console.log(snapshot, "snapshot");
                  });
                  // });
                }
                console.log(fileInputRef.current.files, "files");
              }}
            />
            <GrAttachment
              className="text-zinc-400 w-7 h-7 me-2 cursor-pointer"
              onClick={() => fileInputRef.current.click()}
            />
            <Divider orientation="vertical" flexItem />
            <input
              className="mx-3 w-full h-8 border-0 p-1"
              type="text"
              placeholder="Type message..."
              style={{ backgroundColor: "#F7F8FA" }}
              value={message}
              onChange={(event) => setMessage(event?.target?.value)}
            />
            <span
              className="p-2 rounded-full"
              style={{ backgroundColor: "#FFE5AD" }}
              onClick={async () => {
                const docSnap = await setDoc(
                  doc(
                    db,
                    "messages",
                    `${getListener(loginUserDetails?._id, data?._id)}`,
                    `${getListener(loginUserDetails?._id, data?._id)}`,
                    `${new Date().getTime()}`
                  ),
                  {
                    contentType: urls?.length > 0 ? 3 : 1,
                    dataFrom: {
                      image: loginUserDetails?.profileUrl,
                      name: `${loginUserDetails?.fname} ${loginUserDetails?.lname}`,
                    },
                    toFrom: {
                      image: data?.profileUrl,
                      name: `${data?.fname} ${data?.lname}`,
                    },
                    idFrom: loginUserDetails?._id,
                    idTo: data?._id,
                    read: false,
                    timestamp: new Date().getTime(),
                    content: urls?.length > 0 ? urls[0] : message,
                  }
                );
                setMessage("");
                setUrls([]);
                fileInputRef.current = null;
              }}
            >
              <IoMdSend className="text-zinc-400 w-6 h-6 cursor-pointer" />
            </span>
          </Box>
          <Box className="flex gap-3">
            {urls?.map((url) => {
              return <img src={url} alt="" width={"40px"} height={"40px"} />;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageDialogBox;
