import { Box, Grid, InputAdornment, IconButton, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StatisticCard } from "../common/StatisticCard";
import SearchInput from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import TableContainerComp from "../common/Table/TableContainer";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { generateColumns } from "./helper";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSurfaces,
  getSurfaces,
} from "../../store/surface/surface.slice";

const SurfaceWiseComp = () => {
  const dispatch = useDispatch();
  const {
    surfaces: {
      data: SurfaceData,
      total_data,
      activeCount,
      inActiveCount,
      superSurfaceCount,
    },
    isLoading,
  } = useSelector((state) => state.surface);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = generateColumns(
    generalInfoDetails?.dateFormat || "DD/MM/YYYY"
  );
  const [searchState, setSearchState] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState({
    order: "asc",
    orderBy: "createdAt",
  });

  const getSurfaceList = (details) => {
    dispatch(
      getSurfaces({
        page: details?.page || pagination.page,
        limit: details?.pageSize || pagination.pageSize,
        sortby: details?.orderBy || sorting.orderBy,
        sorttype: details?.order || sorting.order,
        q: details?.q || "",
      })
    );
  };

  // const toggleHandler = () => setAddAccountModal((prev) => !prev);

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getSurfaceList({ page, pageSize });
  };
  const sortingHandler = (orderByCol) => {
    const isAsc = sorting?.orderBy === orderByCol && sorting?.order === "asc";
    console.log(orderByCol, sorting, isAsc, "orderBy");
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
    getSurfaceList({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
  };
  const searchHandler = (e) => {
    console.log(e, "value");
    getSurfaceList({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  useEffect(() => {
    getSurfaceList();
  }, []);
  return (
    <Box className="surface-wraper">
      <Box className="flex my-3" style={{ padding: "0px 2rem" }}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={6} xl={3} className="p-0">
            <StatisticCard
              title="Total Surfaces"
              text={total_data || "0"}
              borderColor={"#F44646"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid xs={6} item xl={3}>
            <StatisticCard
              title="Active Surfaces"
              text={activeCount || "0"}
              borderColor={"#85ABBD"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid xs={6} item xl={3}>
            <StatisticCard
              title="Inactive Surfaces"
              text={inActiveCount || "0"}
              borderColor={"#00B812"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid xs={6} item xl={3}>
            <StatisticCard
              title="Super Surfaces"
              text={superSurfaceCount || "0"}
              borderColor={"#F9DD7B"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
        </Grid>
        <Box className="ms-3">
          <img src="/images/Map.png" alt="" />
        </Box>
      </Box>
      <Box>
        <Box className="text-start flex justify-between mx-2">
          <Box className="flex items-center justify-center mb-2">
            <SearchInput
              placeholder="Search by text"
              wrapperClassName="me-4"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg md:text-sm xl:text-lg"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
            {/* <ButtonComponent
              btnText="Clear Filter"
              btnClass="text-medium"
              style={{
                padding: "7px 20px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
              onClick={() => {
                searchHandler();
              }}
            /> */}
            <p className="text-zinc-400 ms-4 md:!text-md xl:!text-lg">
              Total surfaces : <span className="text-black">{total_data}</span>
            </p>
          </Box>
          <Box className="flex items-center">
            {/* <ButtonComponent
              btnText="Create Surface"
              startIcon={<FaPlus className="w-4 h-4" />}
              style={{
                // padding: "2px 20px",
                color: "black",
                backgroundColor: "#FFFFFF",
                border: "1px solid black",
                "&:hover": { backgroundColor: "#FFFFFF" },
                boxShadow: "none",
              }}
              onClick={() => {}}
            />
            <Divider
              orientation="vertical"
              style={{ height: "40px", margin: "0px 20px" }}
            /> */}
            <Box className="flex">
              <span className="p-1 bg-white me-3 rounded-md">
                <FiDownload
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    dispatch(
                      downloadSurfaces({
                        // page: details?.page || pagination.page,
                        // limit: details?.pageSize || pagination.pageSize,
                        sortby: sorting.orderBy,
                        sorttype: sorting.order,
                        // q: details?.q || "",
                      })
                    ).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.payload])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "SurfaceUsers.csv");
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    });
                  }}
                />
              </span>
              <span className="p-1 bg-white rounded-md">
                <IoPrintOutline
                  className="w-6 h-6"
                  onClick={() => {
                    window.print();
                  }}
                />
              </span>
              {/* <p className="text-sm">Total User</p> */}
            </Box>
            {/* <p className="text-sm">Total User</p> */}
          </Box>
        </Box>
        <Box className="mx-2">
          <TableContainerComp
            columns={columns}
            rows={SurfaceData}
            pagination={true}
            isLoading={isLoading}
            paginationDetails={{
              ...pagination,
              total_data,
              pageHandler: pageHandler,
            }}
            sortingDetails={sorting}
            sortingHandler={sortingHandler}
            hasLink={true}
            navigateURL={"/surface/surfacewise-profile"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SurfaceWiseComp;
