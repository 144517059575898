import React from "react";
import ModalComp from "../common/ModalComp";
import { Box, Card, Grid } from "@mui/material";
import { FaStar } from "react-icons/fa";

const AllDetailsModalSupplier = ({
  open,
  toggleHandler,
  title,
  data = [],
  currentSupplier,
}) => {
  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={toggleHandler}
        title={title}
        // width={600}
        modalClass="details-modal"
      >
        <Box
          sx={{
            padding: "1rem 1.5rem",
            maxHeight: "600px",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <h1 className="font-semibold md:mb-2 xl:mb-3 md:text-lg xl:text-xl">
            Current Supplier
          </h1>
          <Card className="p-3 ms-3">
            <Box className="flex justify-between items-center">
              <Box className="flex items-center">
                <img
                  src={
                    currentSupplier?.supplier?.profileUrl ||
                    "/images/dummy-user.jpg"
                  }
                  alt=""
                  className="seeker-img"
                />
                <Box className="ms-2">
                  <h3 className="text-zinc-700 font-semibold md:text-md xl:text-lg">
                    {`${currentSupplier?.supplier?.fname || " "} ${
                      currentSupplier?.supplier?.lname || " "
                    }`}
                  </h3>
                  <Box className="flex items-center text-zinc-700">
                    <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                    <h1 className="text-medium">
                      <span>
                        {currentSupplier?.supplier?.avgRating?.toFixed(2) || 0}
                      </span>
                    </h1>
                  </Box>
                  <span className="text-zinc-400 text-medium">
                    Current Supplier
                  </span>
                </Box>
              </Box>
              <Box className="flex items-center">
                <Box className="me-2 text-end">
                  <h3 className="text-zinc-700 text-medium">
                    {currentSupplier?.businessProfile?.name}
                  </h3>
                  <span className="text-zinc-400 text-medium">
                    {currentSupplier?.businessProfile?.state}
                  </span>
                  {/* <Box className="flex text-zinc-700">
                    <p className="text-zinc-400 md:text-xs xl:text-sm">
                      <span>01 Oct ‘23</span> - <span>14 Oct ‘23</span>
                    </p>
                  </Box> */}
                </Box>
                <img
                  className="rounded-lg seeker-img"
                  src={
                    currentSupplier?.businessProfile?.profileUrl ||
                    "/images/store.png"
                  }
                  alt=""
                />
              </Box>
            </Box>
          </Card>
          <h1 className="font-semibold md:my-2 xl:my-3 md:text-lg xl:text-xl">
            Previous
          </h1>
          {data?.map((supplier) => {
            return (
              <Card className="p-3 ms-3 mb-3">
                <Box className="flex justify-between items-center">
                  <Box className="flex">
                    <img
                      src={supplier?.profileUrl || "/images/dummy-user.jpg"}
                      alt=""
                      className="seeker-small-img"
                    />
                    <Box className="ms-2">
                      <h3 className="text-zinc-700 font-semibold md:text-md xl:text-lg">
                        {`${supplier?.fname || " "} ${supplier?.lname || " "}`}
                      </h3>
                      <Box className="flex items-center text-zinc-700">
                        <FaStar className="text-yellow-400 md:w-4 md:h-4 xl:w-5 xl:h-5 me-1" />
                        <h1 className="text-medium">
                          <span>{supplier?.avgRating?.toFixed(2) || 0}</span>
                        </h1>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="flex items-center">
                    <Box className="me-2 text-end">
                      <h3 className="text-zinc-700 text-medium">
                        {supplier?.businesses?.length > 0
                          ? supplier?.businesses[0]?.name
                          : ""}
                      </h3>
                      <span className="text-zinc-400 text-medium">
                        {supplier?.businesses?.length > 0
                          ? supplier?.businesses[0]?.state
                          : ""}
                      </span>
                      {/* <Box className="flex text-zinc-700">
                        <p className="text-zinc-400 md:text-xs xl:text-sm">
                          <span>01 Oct ‘23</span> - <span>14 Oct ‘23</span>
                        </p>
                      </Box> */}
                    </Box>
                    <img
                      className="rounded-lg seeker-small-img"
                      src={
                        supplier?.businesses?.length > 0
                          ? supplier?.businesses[0]?.profileUrl
                          : "/images/store.png"
                      }
                      alt=""
                    />
                  </Box>
                </Box>
              </Card>
            );
          })}
        </Box>
      </ModalComp>
    </div>
  );
};

export default AllDetailsModalSupplier;
