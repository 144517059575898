import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInputField from "../common/Input/TextInput";
import SelectInput from "../common/Input/SelectInput";
import RadioInput from "../common/Input/RadioInput";
import ButtonComponent from "../common/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneralInfo,
  saveGeneralInfo,
} from "../../store/settings/generalInfo.slice";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getCountries, getStates } from "../../store/common/common.slice";

const initialValue = {
  siteTitle: "",
  logo: "",
  tagLine: "",
  timeZone: "",
  defaultLanguage: "",
  dateFormat: "",
  email: "",
  address: {
    address1: "",
    address2: "",
    zipCode: "",
    state: "",
    country: "",
  },
  timeFormat: "",
  weekStartsOn: "",
};

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const { countries, states } = useSelector((state) => state.common);
  const [initialValues, setInitialValues] = useState(initialValue);
  const [disableSetting, setDisableSetting] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "values");
      if (values?.logo?.length > 0) {
        values["logo"] = values?.logo[0];
      }
      dispatch(saveGeneralInfo(values)).then((data) => {
        console.log(data, "data in submit");
        if (data?.payload?.success) {
          toast.success(`General Info Edited Successfully!`);
          dispatch(getGeneralInfo()).then((da) => {
            console.log(da, "deatanhjkk");
            setInitialValues(da?.payload);
          });
          setIsEditable(false);
          // formik.resetForm();
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getGeneralInfo()).then((data) => {
      data?.payload?.address?.country &&
        dispatch(getStates(data?.payload?.address?.country));
      setInitialValues(data?.payload);
    });
    dispatch(getCountries());
  }, []);
  console.log(formik.values["address.address1"], "formik.values");
  return (
    <Formik>
      <Form onSubmit={formik.handleSubmit}>
        <Box className="w-full p-5 text-start flex flex-col items-center bg-white mt-3 rounded-lg">
          <Grid container columnSpacing={10} className="mb-10">
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <p
                    className="md:text-md xl:text-lg font-semibold"
                    style={{ fontFamily: "Inter" }}
                  >
                    Site Title
                  </p>
                </Grid>
                <Grid item xs={7} alignItems={"center"}>
                  <TextInputField
                    name="siteTitle"
                    formik={formik}
                    inputClassName="rounded-xl md:text-md xl:text-lg"
                    fullWidth={true}
                    disabled={!isEditable}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <p className="md:text-md xl:text-lg font-semibold">Tagline</p>
                </Grid>
                <Grid item xs={7} alignItems={"center"}>
                  <TextInputField
                    name="tagLine"
                    formik={formik}
                    disabled={!isEditable}
                    inputClassName="rounded-xl md:text-md xl:text-lg"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <p className="md:text-md xl:text-lg font-semibold">
                    Default Language
                  </p>
                </Grid>
                <Grid item xs={7} alignItems={"center"}>
                  <SelectInput
                    name={"defaultLanguage"}
                    formik={formik}
                    disabled={!isEditable}
                    options={[
                      { label: "English", value: "en" },
                      { label: "Gujarati", value: "guj" },
                    ]}
                  />
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <p className="md:text-md xl:text-lg font-semibold">Email</p>
                </Grid>
                <Grid item xs={7} alignItems={"center"}>
                  <TextInputField
                    name="email"
                    formik={formik}
                    disabled={!isEditable}
                    inputClassName="rounded-xl md:text-md xl:text-lg"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <p className="md:text-md xl:text-lg font-semibold">Address</p>
                </Grid>
                <Grid item xs={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} alignItems={"center"}>
                      <p className="md:text-md xl:text-lg">Address line 1</p>
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <TextInputField
                        name="address.address1"
                        formik={formik}
                        disabled={!isEditable}
                        inputClassName="rounded-xl"
                        fullWidth={true}
                        sx={{
                          backgroundColor: "#F7F8FA",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <p className="md:text-md xl:text-lg">Address line 2</p>
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <TextInputField
                        name="address.address2"
                        formik={formik}
                        disabled={!isEditable}
                        inputClassName="rounded-xl"
                        fullWidth={true}
                        sx={{
                          backgroundColor: "#F7F8FA",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <p className="md:text-md xl:text-lg">Zip Code</p>
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <TextInputField
                        name="address.zipCode"
                        formik={formik}
                        disabled={!isEditable}
                        inputClassName="rounded-xl"
                        fullWidth={true}
                        sx={{
                          backgroundColor: "#F7F8FA",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <p className="md:text-md xl:text-lg">Country</p>
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <SelectInput
                        name="address.country"
                        formik={formik}
                        disabled={!isEditable}
                        onChange={(event) => {
                          formik.setFieldValue("address.country", event.value);
                          dispatch(getStates(event.value));
                        }}
                        value={countries
                          ?.map((country) => ({
                            label: country?.name,
                            value: country?.iso2,
                          }))
                          ?.find(
                            (country) =>
                              country.value === formik.values?.address?.country
                          )}
                        options={
                          countries?.length > 0
                            ? countries?.map((country) => ({
                                label: country?.name,
                                value: country?.iso2,
                              }))
                            : []
                        }
                      />
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <p className="md:text-md xl:text-lg">State</p>
                    </Grid>
                    <Grid item xs={6} alignItems={"center"}>
                      <SelectInput
                        name="address.state"
                        formik={formik}
                        disabled={!isEditable}
                        value={states
                          ?.map((state) => ({
                            label: state?.name,
                            value: state?.iso2,
                          }))
                          ?.find(
                            (state) =>
                              state.value === formik.values?.address?.state
                          )}
                        options={
                          states?.length > 0
                            ? states?.map((state) => ({
                                label: state?.name,
                                value: state?.iso2,
                              }))
                            : []
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <p className="md:text-md xl:text-lg font-semibold">Logo</p>
                </Grid>
                <Grid item xs={7}>
                  <TextInputField
                    type="file"
                    name="logo"
                    formik={formik}
                    disabled={!isEditable}
                    inputClassName="rounded-xl md:text-md xl:text-lg"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="md:text-md xl:text-lg font-semibold">
                    Time Zone
                  </p>
                </Grid>
                <Grid item xs={7}>
                  <SelectInput
                    name="timeZone"
                    formik={formik}
                    disabled={!isEditable}
                    options={[
                      {
                        label: "(UTC-05:00) Eastern Time (US and Canada)",
                        value: "(UTC-05:00) Eastern Time (US and Canada)",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="md:text-md xl:text-lg font-semibold">
                    Date Format
                  </p>
                </Grid>
                <Grid item xs={7}>
                  <RadioInput
                    name={"dateFormat"}
                    formik={formik}
                    isDisabled={!isEditable}
                    options={[
                      { label: "Month DD, YYYY", value: "MMM DD, YYYY" },
                      { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
                      { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
                      { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
                    ]}
                    // onChange={(event) => {
                    //   console.log(event.target.value);
                    // }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="md:text-md xl:text-lg font-semibold">
                    Time Format
                  </p>
                </Grid>
                <Grid item xs={7}>
                  <RadioInput
                    name={"timeFormat"}
                    formik={formik}
                    isDisabled={!isEditable}
                    options={[
                      { label: "HH:MM am/pm", value: "hh:mm am/pm" },
                      { label: "HH:MM:SS am/pm", value: "hh:mm:ss am/pm" },
                      { label: "HH:MM", value: "hh:mm" },
                    ]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="md:text-md xl:text-lg font-semibold">
                    Week Starts on
                  </p>
                </Grid>
                <Grid item xs={7}>
                  <SelectInput
                    name={"weekStartsOn"}
                    formik={formik}
                    disabled={!isEditable}
                    options={[
                      { label: "Monday", value: "monday" },
                      { label: "Tuesday", value: "tuesday" },
                      { label: "Wednesday", value: "wednesday" },
                      { label: "Thursday", value: "thursday" },
                      { label: "Friday", value: "friday" },
                      { label: "Saturday", value: "saturday" },
                      { label: "Sunday", value: "sunday" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isEditable ? (
            <ButtonComponent
              btnText="Edit"
              btnClass="text-medium"
              btnType="button"
              onClick={() => setIsEditable(true)}
              style={{
                padding: "5px 30px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
            />
          ) : (
            <Box className="flex">
              <ButtonComponent
                btnText="Cancel"
                btnClass="text-medium"
                btnType="button"
                onClick={() => {
                  setIsEditable(false);
                  formik.resetForm();
                }}
                style={{
                  padding: "5px 30px",
                  backgroundColor: "#F3F4F6",
                  color: "#263238",
                  "&:hover": { backgroundColor: "#F3F4F6" },
                }}
              />
              <ButtonComponent
                btnText="Save"
                btnClass="text-medium"
                btnType="submit"
                style={{
                  padding: "5px 30px",
                  backgroundColor: "#263238",
                  "&:hover": { backgroundColor: "#263238" },
                }}
              />
            </Box>
          )}
        </Box>
      </Form>
    </Formik>
  );
};

export default GeneralInfo;
