import {
  Box,
  Card,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StatisticCard } from "../common/StatisticCard";
import SearchInput from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import TableContainerComp from "../common/Table/TableContainer";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { SupplyData, orderDetailsColumns, orderDetailsData } from "./helper";
import CustomTableContainer from "../common/Table/CustomTableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getReservations } from "../../store/reservation/reservation.slice";
import { convertNumber } from "../../fn";
import ReservationCom from "./ReservationCom";
import RevenueComp from "./RevenueComp";

const Reservation = () => {
  // const dispatch = useDispatch();
  // const {
  //   reservations: { data: Reservation, total_data, totalOrderValue },
  //   isLoading,
  // } = useSelector((state) => state.reservation);
  // const { generalInfoDetails } = useSelector(
  //   (state) => state.settings.generalInfo
  // );
  const [tab, setTab] = React.useState("revenue");
  // const [searchState, setSearchState] = useState("");
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   pageSize: 10,
  // });
  // const [sorting, setSorting] = useState({
  //   sortType: "asc",
  //   sortBy: "createdAt",
  // });
  // const columns = orderDetailsColumns(generalInfoDetails?.dateFormat);

  // const getAllReservations = (params) => {
  //   setSearchState(params?.search || "");
  //   const payload = {
  //     limit: params?.pageSize || pagination?.pageSize,
  //     page: params?.page || pagination?.page,
  //     sortby: params?.sortBy || sorting?.sortBy,
  //     sorttype: params?.sortType || sorting?.sortType,
  //     orderType: params?.orderType || tab,
  //     q: params?.search || "",
  //   };
  //   dispatch(getReservations(payload));
  // };
  const changeTabHandler = (tab) => {
    setTab(tab);
    // getAllReservations({ orderType: tab });
  };

  // const pageHandler = (page, pageSize) => {
  //   setPagination({ page: page, pageSize: pageSize });
  //   getAllReservations({ page: page, limit: pageSize });
  // };
  // const sortingHandler = (sortBy) => {
  //   // console.log(orderByCol, sorting, "orderBy");
  //   const isAsc = sorting?.sortBy === sortBy && sorting?.sortType === "asc";
  //   setSorting({ sortType: isAsc ? "desc" : "asc", sortBy: sortBy });
  //   getAllReservations({ sortType: isAsc ? "desc" : "asc", sortBy: sortBy });
  // };

  // const searchHandler = (e) => {
  //   console.log(e, "value");
  //   getAllReservations({ page: 1, search: e?.target?.value || "" });
  //   setPagination((prev) => ({ ...prev, page: 1 }));
  // };

  // useEffect(() => {
  //   getAllReservations({ orderType: "all" });
  // }, []);
  // console.log(reservations, "reservations");
  return (
    <>
      {/* <BreadCrumb title="Supply Management" /> */}
      <Box className="flex ms-2 mb-3">
        <h1 className="text-2xl font-bold text-start me-3">Reports</h1>
      </Box>
      <Box className="mb-5">
        <Grid container>
          <Grid item xs={2} xl={1}>
            <h3
              className="py-1 font-semibold text-lg cursor-pointer"
              style={
                tab === "revenue"
                  ? {
                      borderBottom: "2px solid #85ABBD",
                      // color: "#F6CA30",
                    }
                  : {}
              }
              onClick={() => changeTabHandler("revenue")}
            >
              Revenue{" "}
              {/* <span
                      className="py-1 px-2 text-white rounded-full"
                      style={{ backgroundColor: "#F6CA30" }}
                    >
                      3
                    </span> */}
            </h3>
          </Grid>
          <Grid item xs={2} xl={1}>
            <h3
              className="py-1 font-semibold text-lg cursor-pointer"
              style={
                tab === "reservations"
                  ? {
                      borderBottom: "2px solid #85ABBD",
                      // color: "#F6CA30",
                    }
                  : {}
              }
              onClick={() => changeTabHandler("reservations")}
            >
              Reservations{" "}
              {/* <span
                      className="py-1 px-2 rounded-full"
                      style={{ border: "1px solid #000000" }}
                    >
                      2
                    </span> */}
            </h3>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      {tab === "revenue" ? <RevenueComp /> : <ReservationCom />}
    </>
  );
};

export default Reservation;
