import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { MdMoreHoriz } from "react-icons/md";
import MenuComp from "../../../common/MenuComp";
import CreateArticleModal from "./CreateArticleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppArticle,
  deleteAppArticle,
  getAppArticles,
} from "../../../../store/settings/apparticle.slice";
import { toast } from "react-toastify";
import moment from "moment";
import RemoveArticleConfirmation from "./RemoveArticleConfirmation";

const AppArticles = () => {
  const [createAppArticle, setCreateAppArticle] = useState({
    showModal: false,
    id: undefined,
  });
  const [removeArticleConfirmationModal, setRemoveArticleConfirmationModal] =
    useState({
      showModal: false,
      submitHandler: () => {},
    });

  const UserMenuOptions = [
    {
      lable: "Edit",
      onClick: (item) => {
        setCreateAppArticle((prev) => ({
          showModal: !prev?.showModal,
          id: item?._id,
        }));
      },
    },
    {
      lable: "Disable",
      onClick: (item) => {
        dispatch(addAppArticle({ _id: item?._id, status: "Inactive" })).then(
          (data) => {
            if (data?.payload?.success) {
              toast.success(`App Article is Disabled Successfully !`);
              dispatch(getAppArticles());
              // formik.resetForm();
              // toggleHandler();
            } else {
              toast.error(data?.message);
            }
          }
        );
      },
    },
    {
      lable: "Enable",
      onClick: (item) => {
        dispatch(addAppArticle({ _id: item?._id, status: "Active" })).then(
          (data) => {
            if (data?.payload?.success) {
              toast.success(`App Article is Enabled Successfully !`);
              dispatch(getAppArticles());
              // formik.resetForm();
              // toggleHandler();
            } else {
              toast.error(data?.message);
            }
          }
        );
      },
    },
    {
      lable: "Remove",
      onClick: (item) => {
        setRemoveArticleConfirmationModal({
          showModal: true,
          submitHandler: () => {
            dispatch(deleteAppArticle({ articleId: item?._id })).then(
              (data) => {
                if (data?.payload?.success) {
                  toast.success(`App Article is Removed Successfully !`);
                  setRemoveArticleConfirmationModal({
                    showModal: false,
                    submitHandler: () => {},
                  });
                  dispatch(getAppArticles());
                  // formik.resetForm();
                  // toggleHandler();
                } else {
                  toast.error(data?.message);
                }
              }
            );
          },
        });
      },
    },
  ];

  const dispatch = useDispatch();

  const { appArticles } = useSelector((state) => state.settings.apparticles);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );

  useEffect(() => {
    dispatch(getAppArticles());
  }, []);

  return (
    <>
      <Box className="p-4 text-start bg-white rounded-lg app-article-wraper">
        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
          App Articles
        </h1>
        <Box>
          <Grid container spacing={2}>
            {appArticles?.map((article, index) => {
              return (
                <Grid item md={12} xl={6} key={index}>
                  <Box
                    className="flex p-4 rounded-lg"
                    style={{ backgroundColor: "#F7F8FA" }}
                  >
                    <Box className="flex items-center">
                      <span>
                        <img
                          src={
                            article?.articleImage || "/images/ArticlePic.png"
                          }
                          alt=""
                          className="app-article-img"
                        />
                      </span>
                      <Box className="ms-3 flex-1 text-medium flex-wrap">
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <p>Name</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>{article?.articleName}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Status</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p className="flex items-center">
                              <GoDotFill
                                color={
                                  article?.status === "Active" ? "green" : "red"
                                }
                                className="w-5 h-5"
                              />
                              {article?.status}
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>From</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>
                              {moment(article?.fromDate).format(
                                generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>To</p>
                          </Grid>
                          <Grid item xs={10}>
                            <p>
                              {moment(article?.toDate).format(
                                generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <p>Link</p>
                          </Grid>
                          <Grid item xs={10} className="link-info">
                            <a
                              href={article?.articleLink}
                              className="link-info"
                              style={{ color: "blue" }}
                            >
                              {article?.articleLink}
                            </a>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <span>
                      <MenuComp
                        selectedItem={article}
                        btn={<MdMoreHoriz className="text-xl" />}
                        menuList={UserMenuOptions?.filter((menu) => {
                          if (menu?.lable === "Disable") {
                            if (article?.status === "Active") {
                              return menu;
                            }
                          } else if (menu?.lable === "Enable") {
                            if (article?.status === "Inactive") {
                              return menu;
                            }
                          } else {
                            return menu;
                          }
                        })}
                        btnBackgroundColor="white"
                        menuBackgroundColor="white"
                      />
                    </span>
                  </Box>
                </Grid>
              );
            })}
            <Grid item md={12} xl={6}>
              <Box
                className="flex p-4 rounded-lg"
                style={{ backgroundColor: "#F7F8FA" }}
                onClick={() =>
                  setCreateAppArticle((prev) => ({
                    showModal: !prev.showModal,
                    id: undefined,
                  }))
                }
              >
                <Box className="flex items-center">
                  <span>
                    <img src="/images/NewArticlePic.png" alt="" />
                  </span>
                  <Box className="ms-4 flex-1">
                    <p className="text-xl">New Article</p>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CreateArticleModal
        // width={1000}
        title={"App Article New"}
        open={createAppArticle.showModal}
        id={createAppArticle.id}
        toggleHandler={() => {
          setCreateAppArticle((prev) => ({
            showModal: !prev?.showModal,
            id: undefined,
          }));
        }}
      />
      <RemoveArticleConfirmation
        open={removeArticleConfirmationModal?.showModal}
        toggleHandler={() =>
          setRemoveArticleConfirmationModal({
            showModal: false,
            submitHandler: () => {},
          })
        }
        submitHandler={removeArticleConfirmationModal?.submitHandler}
      />
    </>
  );
};

export default AppArticles;
