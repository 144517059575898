import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ViewDocumentModal from "./ViewDocumentModal";
import { useDispatch } from "react-redux";
import {
  approveRejectDocument,
  getAccountDashboardDetails,
} from "../../store/account/account.slice";
import { toast } from "react-toastify";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "300px",
  height: "300px",
};

// const center = {
//   lat: 40.7128,
//   lng: -74.006,
// };

const StoreCard = ({
  store,
  userId,
  index,
  toggleBusinessCard,
  activeCard,
}) => {
  const dispatch = useDispatch();
  const [center, setCenter] = useState({
    lat: store?.location?.lat,
    lng: store?.location?.long,
  });
  const [viewDocumentModal, setViewDocumentModal] = useState({
    showModal: false,
    document: null,
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAzn26EQASd9h17oWhNQO4EcjQZHAxTcCg",
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // map.setZoom(10);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const documentApproveRejectModal = (status, documentId) => {
    console.log(status, documentId, "Details");
    dispatch(
      approveRejectDocument({
        payload: {
          businessId: store?._id,
          docId: documentId,
        },
        params: {
          status: status,
        },
      })
    ).then((res) => {
      console.log(res, "response in approveRejectDocument");
      if (res?.payload?.success) {
        toast.success(
          `Document ${
            status === "accept" ? "Approved" : "Rejected"
          } Successfully!`
        );
        setViewDocumentModal({ showModal: false, document: null });
        dispatch(getAccountDashboardDetails({ id: userId }));
      }
    });
  };

  useEffect(() => {
    if (store) {
      console.log(
        {
          lat: store?.location?.lat,
          lng: store?.location?.long,
        },
        "data in useeffect"
      );
      setCenter({
        lat: store?.location?.lat,
        lng: store?.location?.long,
      });
    }
  }, [store]);
  return (
    <>
      <Box className="business-card">
        <Box
          className={`flex items-center justify-between p-2 rounded-md ${
            activeCard === index ? "pb-4 rounded-b-none" : ""
          }`}
          style={{ cursor: "pointer", backgroundColor: "#F7F8FA" }}
          onClick={toggleBusinessCard}
        >
          <Box className="flex">
            <img
              src={store?.profileUrl}
              // width={"60px"}
              // height={"60px"}
              style={{ width: "60px", height: "60px" }}
              className="rounded-md"
            />
            <Box className="ms-2">
              <h1 className="font-semibold">{store?.name}</h1>
              <p className="text-sm">{store?.state}</p>
            </Box>
          </Box>
          {activeCard === index ? <FaChevronUp /> : <FaChevronDown />}
        </Box>
        {activeCard === index && (
          <Box
            className="rounded-md p-4"
            style={{
              backgroundColor: "#F7F8FA",
              width: `calc(400% + ${3 * 16}px)`,
              marginLeft: `calc(-${index % 4} * 100% - ${index % 4} * 16px)`,
            }}
          >
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={6} lg={4}>
                <Box>
                  <Box className="flex my-3">
                    <img
                      src={store?.profileUrl}
                      // width={"200px"}
                      // height={"auto"}
                      className="rounded-md"
                      style={{ width: "200px", height: "250px" }}
                    />
                    <Box className="ms-2">
                      <h1 className="title font-semibold mb-2">
                        {store?.name}
                      </h1>
                      <Box className="text-sm text-zinc-400 mb-2">
                        <p>
                          {store?.street},{store?.state},
                        </p>
                        <p>{store?.country}</p>
                      </Box>
                      <Box className="flex flex-wrap">
                        {store?.category?.map((cat) => {
                          return (
                            <span
                              className="rounded-md bg-zinc-200 me-2 tag"
                              style={{
                                backgroundColor: "#FCF0C6",
                                border: "1px solid #F6CA30",
                              }}
                            >
                              {cat?.categoryName}
                            </span>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="my-3 text-start text-sm text-zinc-600">
                    <p>{store?.description}</p>
                  </Box>
                  <Box className="my-3">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <p className="text-zinc-500">Email:</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          {store?.email}
                          {"  "}
                          <span
                            className={`text-sm ${
                              store?.isEmailVerified
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {store?.isEmailVerified
                              ? "Verified"
                              : "Not Verified"}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={6} className="mb-3">
                        <p className="text-zinc-500">Phone:</p>
                      </Grid>
                      <Grid item xs={6} className="mb-3">
                        <p>
                          {store?.phone}
                          {"  "}
                          <span
                            className={`text-sm ${
                              store?.isMobileVerified
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {store?.isMobileVerified
                              ? "Verified"
                              : "Not Verified"}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="text-zinc-500">Documentation:</p>
                      </Grid>
                      <Grid item xs={6} className="mb-3 flex items-center">
                        {store?.documents?.map((doc) => {
                          return (
                            <Box>
                              <img
                                src={doc?.url}
                                // width={"100px"}
                                // height={"100px"}
                                style={{ width: "100px", height: "100px" }}
                                onClick={() => {
                                  (!(doc?.isAccepted || doc?.isRejected) ||
                                    !doc?.isAccepted) &&
                                    setViewDocumentModal({
                                      showModal: true,
                                      document: doc,
                                    });
                                }}
                              />
                              {!(doc?.isAccepted || doc?.isRejected) ? (
                                <span className="text-sm text-blue-500 ms-2">
                                  Pending
                                </span>
                              ) : (
                                <span
                                  className={`text-sm ${
                                    doc?.isAccepted
                                      ? "text-green-500"
                                      : "text-red-500"
                                  } ms-2`}
                                >
                                  {doc?.isAccepted ? "Verified" : "Rejected"}
                                </span>
                              )}
                            </Box>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Box className="mb-5">
                  <h1
                    className="my-2 text-zinc-700 md:text-lg xl:text-xl"
                    style={{ fontWeight: "600" }}
                  >
                    Surfaces
                  </h1>
                  <Box className="flex flex-wrap my-2">
                    {store?.surface?.length === 0 && (
                      <span className="text-sm mx-2">No Surface</span>
                    )}
                    {store?.surface?.map((s) => {
                      return (
                        <Box className="mx-2">
                          <img
                            src="/images/store.png"
                            width={"90px"}
                            height={"auto"}
                            className="rounded-md"
                          />
                          <p className="text-zinc-500 text-sm">{s.name}</p>
                        </Box>
                      );
                    })}

                    {store?.surface?.length > 3 && (
                      <Card
                        className="flex items-center justify-center mt-1"
                        style={{
                          width: "80px",
                          height: "40px",
                          backgroundColor: "#F7F8FA",
                        }}
                      >
                        <p>View all</p>
                      </Card>
                    )}
                  </Box>
                </Box>
                <Box>
                  <h1
                    className="my-3 text-zinc-700 md:text-lg xl:text-xl"
                    style={{ fontWeight: "600" }}
                  >
                    Supplies
                  </h1>
                  <Box className="flex flex-wrap my-2">
                    {store?.supply?.length === 0 && (
                      <span className="text-sm mx-2">No Supplies</span>
                    )}
                    {store?.supply?.map((s) => {
                      return (
                        <Box className="mx-2">
                          <img
                            src={
                              s?.images?.length > 0
                                ? s?.images[0]
                                : "/images/store.png"
                            }
                            width={"90px"}
                            height={"auto"}
                            className="rounded-md"
                          />
                          <p className="text-zinc-500 text-sm">{s.name}</p>
                        </Box>
                      );
                    })}
                    {store?.supply?.length > 3 && (
                      <Card
                        className="flex items-center justify-center mt-1"
                        style={{
                          width: "80px",
                          height: "40px",
                          backgroundColor: "#F7F8FA",
                        }}
                      >
                        <p>View all</p>
                      </Card>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} lg={4}>
                <h1
                  className="my-3 text-zinc-700 md:text-lg xl:text-xl"
                  style={{ fontWeight: "600" }}
                >
                  Location
                  <Box
                    style={{
                      width: "5rem",
                      height: "5rem",
                      backgroundColor: "white",
                    }}
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={8}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      // onGoogleApiLoaded={({ map, maps }) =>
                      //   renderMarkers(map, maps)
                      // }
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <Marker position={center} />
                    </GoogleMap>
                  </Box>
                </h1>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <ViewDocumentModal
        open={viewDocumentModal?.showModal}
        toggleHandler={() =>
          setViewDocumentModal((prev) => ({ showModal: false, document: null }))
        }
        submitHandler={documentApproveRejectModal}
        data={viewDocumentModal?.document}
      />
    </>
  );
};

export default StoreCard;
