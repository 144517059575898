import React, { useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CiSettings } from "react-icons/ci";
import { MdOutlineInsertChart } from "react-icons/md";
import { GoPeople } from "react-icons/go";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineBell } from "react-icons/hi2";
import { VscChevronDown } from "react-icons/vsc";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ListIcon } from "../../assets/images/ListIcon.svg";
import { ReactComponent as SurfaceIcon } from "../../assets/images/SurfaceIcon.svg";
import { ReactComponent as SupplyIcon } from "../../assets/images/SupplyIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/DashboardIcon.svg";
import { useSelector } from "react-redux";
import MenuComp from "./MenuComp";
import { CgProfile } from "react-icons/cg";

const SidebarWithHeader = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname.split("/")[1];
  console.log(location, "location");
  const { isAuthenticated, isLoading, loginUserDetails } = useSelector(
    (state) => state.auth
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  console.log(loginUserDetails, "loginUserDetails");

  const UserMenuOptions = [
    {
      lable: "Disable User",
      onClick: () => {},
    },
    {
      lable: "Reset Password",
      onClick: () => {
        // setResetPasswordModal(true);
      },
    },
  ];
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F7F8FA" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "18rem",
          backgroundColor: "#F9DD7B",
          height: "100vh",
          justifyContent: "start",
          alignItems: "center",
          padding: "1.2rem",
          gap: "4rem",
        }}
      >
        <img
          src="/images/Surfacesharelogo.png"
          alt="logo"
          className="w-[13rem] "
        />
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <ListItem
            onClick={(e) => navigate("/")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              gap: "0.5rem",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "" ? "#263238" : "",
              color: location === "" ? "white" : "",
            }}
          >
            <MdOutlineInsertChart className="w-7 h-7" />
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            onClick={(e) => navigate("/account")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              gap: "0.5rem",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "account" ? "#263238" : "",
              color: location === "account" ? "white" : "",
            }}
          >
            <GoPeople className="w-6 h-6" />
            <ListItemText primary="Accounts" />
          </ListItem>
          <ListItem
            onClick={(e) => navigate("/surface")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              borderRadius: "10px",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "surface" ? "#263238" : "",
              color: location === "surface" ? "white" : "",
            }}
          >
            <SurfaceIcon
              className="w-6 h-6"
              stroke={location === "surface" ? "white" : "#263238"}
            />
            <ListItemText primary="Surfaces" />
          </ListItem>
          <ListItem
            onClick={(e) => navigate("/supply")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              borderRadius: "10px",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "supply" ? "#263238" : "",
              color: location === "supply" ? "white" : "",
            }}
          >
            <SupplyIcon
              className="w-6 h-6"
              fill={location === "supply" ? "white" : "#263238"}
            />
            <ListItemText primary="Supplies" />
          </ListItem>
          <ListItem
            onClick={(e) => navigate("/reports")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              gap: "0.5rem",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "reports" ? "#263238" : "",
              color: location === "reports" ? "white" : "",
            }}
          >
            <ListIcon
              className="w-6 h-6"
              fill={location === "reports" ? "white" : "#263238"}
            />
            <ListItemText primary="Reports" />
          </ListItem>
          <ListItem
            onClick={(e) => navigate("/settings")}
            className="cursor-pointer"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              gap: "0.5rem",
              // "&:hover": {
              //   backgroundColor: "#263238",
              //   color: "white",
              // },
              backgroundColor: location === "settings" ? "#263238" : "",
              color: location === "settings" ? "white" : "",
            }}
          >
            <CiSettings className="w-6 h-6" />
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "10%",
          // justifyContent: "end",
          alignItems: "flex-start",
          padding: "0.5rem 1rem 0rem 1rem",
          backgroundColor: "#F7F8FA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.3rem",
            marginLeft: "auto",
          }}
        >
          {/* <IoIosSearch className="w-7 h-7" /> */}
          <img
            src="https://flagsapi.com/IN/shiny/64.png"
            alt="logo"
            className="w-8 h-8"
          />
          <HiOutlineBell className="w-7 h-7" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9DD7B",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={loginUserDetails?.profileUrl || "/images/dummy-user.jpg"}
                alt="logo"
                width="50px"
                height="50px"
                className="rounded-full"
              />
            </Box>

            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // gap: "0.6rem",
                }}
                className="cursor-pointer"
                onClick={toggleDropdown}
              >
                <Typography>{`${loginUserDetails?.fname} ${loginUserDetails?.lname}`}</Typography>
                <VscChevronDown />
              </Box>
              <Box
                id="dropdown"
                className={`absolute right-5 mt-2 z-10 w-56 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 ${
                  isOpen ? "" : "hidden"
                }`}
              >
                <ul
                  className="p-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <Box className="px-4 py-2 cursor-pointer text-md font-semibold flex items-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      <CgProfile className="w-6 h-6 me-2" /> My Profile
                    </Box>
                  </li>
                  <li>
                    <Box className="flex justify-between">
                      <Box className="px-4 py-2 cursor-pointer text-md font-semibold flex items-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <IoNotificationsOutline className="w-6 h-6 me-2" />
                        Notification
                      </Box>
                      <Switch
                        defaultChecked
                        className="cursor-pointer"
                        sx={{
                          color: "#F6CA30",
                          "& .MuiSwitch-thumb": { backgroundColor: "#F9DD7B" },
                          // "& .MuiSwitch-track": { backgroundColor: "#FCEFC1" },
                          "& .MuiButtonBase-root .MuiSwitch-switchBase .MuiSwitch-track":
                            {
                              backgroundColor: "#FCEFC1",
                            },
                        }}
                      />
                    </Box>
                  </li>
                  <li>
                    <Box
                      className="px-4 py-2 cursor-pointer text-md text-red-500 font-semibold flex items-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => {
                        localStorage.removeItem("surfaceShareToken");
                        navigate("/login");
                      }}
                    >
                      <MdLogout className="w-6 h-6 me-2" />
                      Log out
                    </Box>
                  </li>
                </ul>
              </Box>
            </div>

            {/* <MenuComp btn={<VscChevronDown />} menuList={UserMenuOptions} /> */}
          </Box>
        </Box>
        <Box className="w-full">{children}</Box>
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;
