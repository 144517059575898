import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import CreateCategoryModal from "./CreateCategoryModal";
import CategoryCard from "./CategoryCard";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../store/settings/categories.slice";

const Category = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories({ for: "all" }));
  }, []);

  const { categories, isLoading } = useSelector(
    (state) => state.settings.categories
  );

  const [createCategoryModal, setCreateCategoryModal] = useState({
    showModal: false,
    activeCategory: undefined,
    selectedCategory: undefined,
  });

  const toggleHandler = (categoryName, category) => {
    console.log(categoryName, category, "details about on toggle");
    setCreateCategoryModal((prev) => ({
      showModal: !prev.showModal,
      activeCategory: categoryName,
      selectedCategory: category,
    }));
  };
  return (
    <>
      <Box className="p-4 text-start bg-white rounded-lg">
        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">Categories</h1>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6} xl={4}>
              <Box
                className="p-3 rounded-md"
                style={{ backgroundColor: "#F7F8FA", height: "100%" }}
              >
                <Box className="flex justify-between mb-3">
                  <h1 className="md:text-lg xl:text-xl font-semibold ps-2">
                    Surface Categories
                  </h1>
                  <button
                    onClick={
                      () => toggleHandler("Surface Categories")
                      // setCreateCategoryModal((prev) => ({
                      //   showModal: !prev.showModal,
                      //   activeCategory: "Surface Categories",
                      // }))
                    }
                  >
                    <AiFillPlusCircle className="w-9 h-9" />
                  </button>
                </Box>
                <Box
                  sx={{
                    maxHeight: "450px",
                    overflowY: "scroll",
                  }}
                >
                  {categories
                    .find((cat) => cat.type === "Surface Categories")
                    ?.data.map((cat) => (
                      <CategoryCard data={cat} toggleHandler={toggleHandler} />
                    ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} xl={4}>
              <Box
                className="p-3 rounded-md"
                style={{ backgroundColor: "#F7F8FA", height: "100%" }}
              >
                <Box className="flex justify-between mb-3 ">
                  <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                    Supply Categories
                  </h1>
                  <button
                    onClick={
                      () => toggleHandler("Supply Categories")
                      // setCreateCategoryModal((prev) => ({
                      //   showModal: !prev.showModal,
                      //   activeCategory: "Supply Categories",
                      // }))
                    }
                  >
                    <AiFillPlusCircle className="w-9 h-9" />
                  </button>
                </Box>
                <Box
                  sx={{
                    maxHeight: "450px",
                    overflowY: "scroll",
                  }}
                >
                  {categories
                    .find((cat) => cat.type === "Supply Categories")
                    ?.data.map((cat) => (
                      <CategoryCard data={cat} toggleHandler={toggleHandler} />
                    ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} xl={4}>
              <Box
                className="p-3 rounded-md"
                style={{ backgroundColor: "#F7F8FA", height: "100%" }}
              >
                <Box className="flex justify-between mb-3">
                  <h1 className="md:text-lg xl:text-xl font-semibold mb-3 ps-2">
                    Business Categories
                  </h1>
                  <button
                    onClick={
                      () => toggleHandler("Business Categories")
                      // setCreateCategoryModal((prev) => ({
                      //   showModal: !prev.showModal,
                      //   activeCategory: "Business Categories",
                      // }))
                    }
                  >
                    <AiFillPlusCircle className="w-9 h-9" />
                  </button>
                </Box>
                <Box
                  sx={{
                    maxHeight: "450px",
                    overflowY: "scroll",
                  }}
                >
                  {categories
                    .find((cat) => cat.type === "Business Categories")
                    ?.data?.map((cat) => (
                      <CategoryCard data={cat} toggleHandler={toggleHandler} />
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CreateCategoryModal
        title={`${createCategoryModal.selectedCategory ? "Edit" : "Create"} ${
          createCategoryModal.activeCategory?.split(" ")[0]
        } Category`}
        open={createCategoryModal.showModal}
        activeCategory={createCategoryModal.activeCategory}
        categories={categories}
        selectedCategory={createCategoryModal.selectedCategory}
        toggleHandler={() => toggleHandler()}
      />
    </>
  );
};

export default Category;
