import { Box, Card, Grid, InputAdornment, IconButton } from "@mui/material";
import React from "react";
import SwitchComp from "../common/SwitchComp";
import SurfaceWiseComp from "./SurfaceWiseComp";
import BusinessWiseComp from "./BusinessWiseComp";

const Surface = () => {
  const [filter, setFilter] = React.useState("surfaceWise");
  const changeHandler = (event) => {
    setFilter(event.target.checked ? "businessWise" : "surfaceWise");
  };
  return (
    <>
      {/* <BreadCrumb title="Surface Management" /> */}
      <Box className="flex ms-2">
        <h1 className="text-2xl font-bold text-start me-3">
          Surface Managements
        </h1>
        <Box className="flex justify-center items-center">
          <p
            className={`${
              filter === "surfaceWise" ? "font-semibold" : "text-zinc-500"
            }`}
          >
            Surface wise
          </p>
          <SwitchComp
            checked={filter === "surfaceWise" ? false : true}
            changeHandler={changeHandler}
          />
          <p
            className={`${
              filter === "businessWise" ? "font-semibold" : "text-zinc-500"
            }`}
          >
            Business wise
          </p>
        </Box>
      </Box>
      {filter === "surfaceWise" && <SurfaceWiseComp />}
      {filter === "businessWise" && <BusinessWiseComp />}
    </>
  );
};

export default Surface;
