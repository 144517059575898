// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhuf-GgLGYGeSbbT5ZZOEqrY00aNfj7f0",
  authDomain: "fir-surface.firebaseapp.com",
  projectId: "fir-surface",
  storageBucket: "fir-surface.appspot.com",
  messagingSenderId: "925995324502",
  appId: "1:925995324502:web:27fd2609caa4f17cdd57f7",
  measurementId: "G-2BXVTPTZTK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const dbRealtime = getDatabase();
export const storage = getStorage(app);
const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();
// export const collectionRef = (collectionName) => {
//   return collection(firestore, collectionName);
// };
const messaging = getMessaging(app);
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BI69CQhA-c76snOUJc4JGmkWYmC8mzxBOCvTCJ770xzjRyDbT6Ao_Jxa6qA6bZK5AjbwuEq_73_ukp8SLGhc8gw",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/firebase-messaging-sw.js")
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);

//       // Use the service worker with Firebase Messaging
//       messaging.useServiceWorker(registration);

//       // Request permission and get token
//       return getToken(messaging, {
//         vapidKey:
//           "BI69CQhA-c76snOUJc4JGmkWYmC8mzxBOCvTCJ770xzjRyDbT6Ao_Jxa6qA6bZK5AjbwuEq_73_ukp8SLGhc8gw",
//       });
//     })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("Token received:", currentToken);
//         // Send the token to your server and update the UI if necessary
//       } else {
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token.", err);
//     });

//   // Handle incoming messages
//   onMessage(messaging, (payload) => {
//     console.log("Message received. ", payload);
//     // Customize notification here if needed
//     const notificationTitle = payload.notification.title;
//     const notificationOptions = {
//       body: payload.notification.body,
//       icon: payload.notification.icon, // Add other options if needed
//     };

//     if (Notification.permission === "granted") {
//       new Notification(notificationTitle, notificationOptions);
//     }
//   });
// }
