import React from "react";
import ModalComp from "../common/ModalComp";
import ButtonComponent from "../common/Button";
import { Box } from "@mui/material";

const DeleteTemplateConfirmationModal = ({
  open,
  toggleHandler,
  submitHandler,
}) => {
  return (
    <ModalComp
      open={open}
      width={450}
      isDividerNeeded={false}
      toggleHandler={toggleHandler}
    >
      <Box className="px-6 text-center">
        <h1 className="text-xl font-semibold mb-4">Delete template</h1>
        <p className="mb-4">
          Are you sure you want to delete this email template ?
        </p>
        <Box className="mb-4">
          <ButtonComponent
            btnText="Yes"
            style={{
              width: "120px",
              backgroundColor: "#00B812",
              boxShadow: "none",
              "&:hover": { backgroundColor: "#00B812" },
            }}
            onClick={submitHandler}
          />
          <ButtonComponent
            btnText="No"
            style={{
              width: "120px",
              backgroundColor: "#F44646",
              boxShadow: "none",
              "&:hover": { backgroundColor: "#F44646" },
            }}
            onClick={toggleHandler}
          />
        </Box>
      </Box>
    </ModalComp>
  );
};

export default DeleteTemplateConfirmationModal;
