import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const uploadImage = createAsyncThunk(
  "common/uploadImage",
  async (formData) => {
    const postData = async () => {
      return POST("/media/upload", formData).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await postData();
    } catch (error) {
      console.log(error, "error===");
      return [];
    }
  }
);

export const getReviews = createAsyncThunk(
  "common/getReviews",
  async (params) => {
    const getData = async () => {
      return GET("/review/get", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

export const getCountries = createAsyncThunk(
  "common/getCountries",
  async () => {
    const getData = async () => {
      return GET("https://api.countrystatecity.in/v1/countries", {
        headers: {
          "X-CSCAPI-KEY":
            "dXVvZGV2dzZZTFdvSlpJYVVSMUU0bXc2RHB2N1BidTlGTnFqVXU3dw==",
        },
      }).then((res) => {
        console.log(res, "response ");
        if (res) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getStates = createAsyncThunk(
  "common/getStates",
  async (countryCode) => {
    const getData = async () => {
      console.log(countryCode, "countryCode");
      return GET(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "dXVvZGV2dzZZTFdvSlpJYVVSMUU0bXc2RHB2N1BidTlGTnFqVXU3dw==",
          },
        }
      ).then((res) => {
        console.log(res, "response ");
        if (res) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getCities = createAsyncThunk(
  "common/getCities",
  async ({ countryCode, stateCode }) => {
    const getData = async () => {
      console.log(stateCode, "countryCode");
      return GET(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "dXVvZGV2dzZZTFdvSlpJYVVSMUU0bXc2RHB2N1BidTlGTnFqVXU3dw==",
          },
        }
      ).then((res) => {
        console.log(res, "response ");
        if (res) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false,
    firebaseToken: {},
    reviewDetails: {},
    countries: [],
    states: [],
    cities: [],
    isError: false,
  },
  reducers: {
    resetCountries: (state) => {
      state.countries = [];
    },
    resetStates: (state) => {
      state.states = [];
    },
    resetCities: (state) => {
      state.cities = [];
    },
    setFirebaseToken: (state, payload) => {
      // state.firebaseToken
      console.log(payload, "payload in set");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReviews.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReviews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reviewDetails = action.payload;
    });
    builder.addCase(getReviews.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.countries = action.payload;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.states = action.payload;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cities = action.payload;
    });
  },
});

export const { resetCountries, resetStates, resetCities, setFirebaseToken } =
  commonSlice.actions;
export default commonSlice.reducer;
