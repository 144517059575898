import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import ImageViewer from "../common/ImageViewer";
import ButtonComponent from "../common/Button";
import { RiMessage2Fill } from "react-icons/ri";
import { MdNavigateNext } from "react-icons/md";
import TotalEarningChart from "./TotalEarningChart";
import StoreCard from "./StoreCart";
import { FaStar } from "react-icons/fa";
import LinearProgressBar from "../common/LinearProgressBar";
import { IoFlagOutline } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { MdMoreHoriz } from "react-icons/md";
import ReviewSection from "../common/ReviewSection";
import StepperComp from "../common/StepperComp";
import ModalComp from "../common/ModalComp";
import { useLocation, useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { ActivitySteps } from "./helper";
import MenuComp from "../common/MenuComp";
import ResetPasswordConfirmationModal from "./ResetPasswordConfirmationModal";
import UsageChart from "./UsageChart";
import MessageDialogBox from "./MessageDialogBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccount,
  getAccountDashboardDetails,
} from "../../store/account/account.slice";
import { getReviews } from "../../store/common/common.slice";
import { toast } from "react-toastify";
import DisableAccountConfirmationModal from "./DisableAccountConfirmationModal";
import moment from "moment";

const AccountProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { accountDetails } = useSelector((state) => state.account);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  console.log(accountDetails, "accountDetails");
  const {
    data: Reviews,
    avarageRating,
    total_data,
    starPercentages,
  } = useSelector((state) => state.common.reviewDetails);

  const stores = [
    {
      img: null,
      storeName: "K Mart",
      place: "New York",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "H Mart",
      place: "Manhattan",
    },
    {
      img: null,
      storeName: "E Mart",
      place: "Manhattan",
    },
  ];

  const UserMenuOptions = [
    {
      lable: `${accountDetails?.isDisabled ? "Enable" : "Disable"} User`,
      onClick: () => {
        setDisableAccountModal(true);
      },
    },
    {
      lable: "Reset Password",
      onClick: () => {
        setResetPasswordModal(true);
      },
    },
  ];

  const [reviewFilter, setReviewFilter] = useState("1 month");
  const [expanded, setExpanded] = useState(false);
  const [loadMoreActivity, setLoadMoreActivity] = useState(false);
  const [redFlag, setRedFlag] = useState(true);
  const [activeBusinessCard, setActiveBusinessCard] = useState(null);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [disableAccountModal, setDisableAccountModal] = useState(false);
  const [resetPasswordSendLinkModal, setResetPasswordSendLinkModal] =
    useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [messageModalCollapse, setMessageModalCollapse] = useState(false);
  const [stars, setStars] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });

  const toggleBusinessCard = (index) => {
    console.log(index, activeBusinessCard, "index");
    if (activeBusinessCard === index) {
      setActiveBusinessCard(null);
    } else {
      setActiveBusinessCard(index);
    }
  };

  useEffect(() => {
    console.log(location, "location.state");
    dispatch(getAccountDashboardDetails({ id: location.state._id }));
    dispatch(getReviews({ userId: location.state._id, page: 1, size: 100000 }));
  }, [location.state]);

  useEffect(() => {
    const s = stars;
    if (starPercentages) {
      starPercentages?.forEach((sa) => {
        s[sa?.star] = +sa?.percentage;
      });
      setStars(s);
    }
  }, [starPercentages]);
  console.log(generalInfoDetails?.dateFormat, "generalInfoDetails?.dateFormat");
  return (
    <>
      <Box className="p-2 account-profile">
        <BreadCrumb
          title="Profile"
          parents={["Accounts Management"]}
          parentsURL={["/account"]}
        />
        <Box
          className="mx-2"
          sx={{
            maxHeight: `calc(100vh - 102px - 1.5rem)`,
            overflowY: "scroll",
          }}
        >
          {/* <ModalComp /> */}
          <Grid container spacing={2}>
            <Grid item xs={7} lg={5}>
              <Card className="p-5" style={{ height: "100%" }}>
                <Box className="flex">
                  <Box className="pe-2">
                    <Box className="flex flex-col items-center justify-center">
                      {/* <Grid container columnSpacing={3}> */}
                      {/* <Grid item xs={4}></Grid> */}
                      {/* <Grid item xs={4} sx={{ padding: "0px !important" }}> */}
                      <Box className="flex">
                        <ImageViewer
                          wrapperClass={"my-3"}
                          url={accountDetails?.profileUrl}
                        />
                        <MenuComp
                          btn={<MdMoreHoriz className="text-xl" />}
                          menuList={UserMenuOptions}
                        />
                      </Box>
                      {/* </Grid> */}
                      {/* <Grid item xs={4}> */}
                      {/* </Grid> */}
                      {/* </Grid> */}
                      {/* <Box className="w-full flex justify-center">
                      <span style={{ justifySelf: "right" }}>
                        <MenuComp btn={<CiCircleMore className="text-3xl" />} />
                      </span>
                    </Box> */}
                      <h1 className="my-2 text-xl font-bold">
                        {`${accountDetails?.fname || ""} ${
                          accountDetails?.lname || ""
                        }`}
                      </h1>
                      <p className="my-2 text-sm text-zinc-400 text-wrap">
                        {accountDetails?.email}
                      </p>
                      <Box className="my-2 text-sm">
                        <p>Roles</p>
                        <Box className="flex flex-wrap">
                          {accountDetails?.isSeeker && (
                            <p className="mx-1 font-semibold">Seeker</p>
                          )}
                          {accountDetails?.isSharer && (
                            <>
                              <Divider orientation="vertical" flexItem />
                              <p className="mx-1 font-semibold">Sharer</p>
                            </>
                          )}
                          {accountDetails?.isSupplier && (
                            <>
                              <Divider orientation="vertical" flexItem />
                              <p className="mx-1 font-semibold">Supplier</p>
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box className="my-2">
                        <ButtonComponent
                          btnText="Send Message"
                          btnClass="text-nowrap md:text-xs xl:text-sm"
                          startIcon={<RiMessage2Fill />}
                          style={{
                            backgroundColor: "#263238",
                            "&:hover": { backgroundColor: "#263238" },
                          }}
                          onClick={() => setMessageModal(true)}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box className="ps-2">
                    <Grid
                      container
                      spacing={1}
                      columnSpacing={6}
                      className="text-start profile-details"
                    >
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Gender
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.gender || " - "}
                          </p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Date of Birth
                          </h3>
                          <p className="mb-3">{accountDetails?.dob || " - "}</p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Phone
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.phone || " - "}
                          </p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Address
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.address || " - "}
                          </p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            City
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.city || " - "}
                          </p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            State
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.state || " - "}
                          </p>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Country
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.country || " - "}
                          </p>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="my-2">
                          <h3 className="mb-3 text-zinc-400 font-semibold">
                            Zip Code
                          </h3>
                          <p className="mb-3">
                            {accountDetails?.zipCode || " - "}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={5} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Card>
                    <Box className="text-start profile-static">
                      <h3 className="text-zinc-400 text-sm mb-3 font-semibold">
                        Ac Code
                      </h3>
                      <h2 className="text-lg font-semibold">
                        {accountDetails?.acCode || " - "}
                      </h2>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <Box className="text-start profile-static">
                      <h3 className="text-zinc-400 mb-3 font-semibold">
                        Status
                      </h3>
                      <h2 className="text-lg font-semibold flex items-center">
                        <GoDotFill
                          color={
                            accountDetails?.status === "Active"
                              ? "#00B812"
                              : "#F44646"
                          }
                        />
                        {accountDetails?.status || " - "}
                      </h2>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <Box className="text-start profile-static">
                      <h3 className="text-zinc-400 text-sm mb-3 font-semibold">
                        Reg. Date
                      </h3>
                      <h2 className="text-lg font-semibold">
                        {accountDetails?.createdAt
                          ? moment(accountDetails?.createdAt).format(
                              generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                            )
                          : " - "}
                      </h2>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card style={{ height: "100%" }}>
                    <h1 className="text-start p-4 text-zinc-400 font-semibold">
                      Usage
                    </h1>
                    {/* <UsageChart /> */}
                    <p className="mb-5">No Data Avilable</p>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4">
                  <Box className="flex justify-between">
                    <h1 className="font-semibold sm:text-lg xl:text-xl">
                      Total Earnings
                    </h1>
                    <p
                      className="font-semibold rounded-md cursor-pointer md:text-sm xl:text-md"
                      style={{
                        backgroundColor: "#F7F8FA",
                        padding: "5px 10px",
                      }}
                      onClick={() =>
                        navigate("/account/profile/order-details", {
                          state: location.state,
                        })
                      }
                    >
                      Goto Orders {">"}
                    </p>
                    {/* <ButtonComponent
                    btnText="Goto Orders"
                    btnClass="px-3 py-2 font-semibold"
                    style={{
                      backgroundColor: "#F7F8FA",
                      "&:hover": {
                        backgroundColor: "#F7F8FA",
                        boxShadow: "none",
                      },
                      boxShadow: "none",
                      color: "black",
                      fontWeight: "500",
                    }}
                  /> */}
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={12}>
                        <TotalEarningChart />
                      </Grid>
                      <Grid item xs={6} lg={12}>
                        <Grid
                          container
                          spacing={1}
                          className="sm:text-sm lg:text-md"
                        >
                          <Grid item xs={6}>
                            <p className="flex items-center">
                              <GoDotFill color="#B5D2E0" className="w-5 h-5" />K
                              Mart
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p>$540</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="flex items-center">
                              <GoDotFill color="#D2E9F5" className="w-5 h-5" />M
                              Mart
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p>$810</p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ height: "100%" }}>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Businesses & Surfaces
                  </h1>
                  <Grid container spacing={2}>
                    {accountDetails?.businesses?.map((store, index) => (
                      <Grid item xs={3}>
                        <StoreCard
                          store={store}
                          userId={location.state?._id}
                          index={index}
                          toggleBusinessCard={() => toggleBusinessCard(index)}
                          activeCard={activeBusinessCard}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Card>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Reviews
                  </h1>
                  <Grid container justifyContent={"space-between"} spacing={2}>
                    <Grid item xs={4}>
                      <Box className="flex text-zinc-700 my-3">
                        <FaStar className="text-yellow-400 w-6 h-6 me-2" />
                        <h1 className="md:text-lg xl:text-xl">
                          <span>{avarageRating || 0}</span> |{" "}
                          <span>{total_data}</span> Reviews
                        </h1>
                      </Box>
                      <Box className="mb-5">
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>5 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={
                                // +starPercentages?.find(
                                //   (star) => star.star === 5
                                // )?.percentage || 0
                                stars[5]
                              }
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[5]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>4 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[4]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[4]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>3 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[3]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[3]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>2 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[2]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[2]}%</p>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="my-2 text-sm"
                        >
                          <Grid item xs={1}>
                            <FaStar className="text-yellow-400" />
                          </Grid>
                          <Grid item xs={3}>
                            <span>1 Stars</span>
                          </Grid>
                          <Grid item xs={7} className="text-base">
                            <LinearProgressBar
                              backgroundColor={"#FCF0C6"}
                              color={"#F6CA30"}
                              value={stars[1]}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <p>{stars[1]}%</p>
                          </Grid>
                        </Grid>
                      </Box>
                      {Reviews?.length > 0 && (
                        <Box
                          className="flex items-center justify-around text-white rounded-full cursor-pointer flagg-btn"
                          style={{
                            backgroundColor: redFlag ? "#F44646" : "#FFFFFF",
                            border: !redFlag ? "1px solid #F44646" : "",
                            padding: "5px",
                            color: redFlag ? "#FFFFFF" : "#F44646",
                          }}
                          onClick={() => {
                            setRedFlag((prev) => !prev);
                          }}
                        >
                          <IoFlagOutline className="w-6 h-6 ms-2 me-1" />
                          <p className="mx-1">Flagged</p>
                          {/* <span
                          className="bg-white rounded-full flex justify-center items-center mx-1"
                          style={{
                            // width: "30px",
                            // height: "30px",
                            padding: "0px",
                            color: redFlag ? "#FFFFFF" : "#F44646",
                            backgroundColor: redFlag ? "#F44646" : "#FFFFFF",
                          }}
                        > */}
                          <span
                            className="bg-white inline-block rounded-full"
                            style={{
                              padding: "2px 9px",
                              backgroundColor: redFlag ? "#FFFFFF" : "#F44646",
                              color: redFlag ? "#F44646" : "#FFFFFF",
                            }}
                          >
                            2
                          </span>
                          {/* </span> */}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={7}>
                      <Box className="flex justify-end mb-3 md:text-sm xl:text-md">
                        <p className="me-3 font-semibold text-zinc-600">
                          Filter By:
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "1 month"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("1 month")}
                        >
                          1 month
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "3 months"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("3 months")}
                        >
                          3 months
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "12 months"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("12 months")}
                        >
                          12 months
                        </p>
                        <p
                          className={`me-3 cursor-pointer ${
                            reviewFilter === "lifetime"
                              ? "underline font-semibold"
                              : ""
                          }`}
                          onClick={() => setReviewFilter("lifetime")}
                        >
                          Lifetime
                        </p>
                      </Box>
                      <Box className="flex flex-col justify-center items-center">
                        {Reviews?.slice(0, 2)?.map((review) => {
                          return (
                            <ReviewSection data={review} isRedFlag={redFlag} />
                          );
                        })}
                        {Reviews?.length > 2 &&
                          expanded &&
                          Reviews?.slice(2, Reviews?.length)?.map((review) => {
                            return (
                              <ReviewSection
                                data={review}
                                isRedFlag={redFlag}
                              />
                            );
                          })}
                        {/* <ReviewSection isRedFlag={redFlag} /> */}
                        {/* {expanded && (
                          <>
                            <ReviewSection isRedFlag={redFlag} />
                            <ReviewSection isRedFlag={redFlag} />
                            <ReviewSection isRedFlag={redFlag} />
                            <ReviewSection isRedFlag={redFlag} />
                          </>
                        )} */}
                        {Reviews?.length > 2 &&
                          (expanded ? (
                            <IoChevronUpSharp
                              className="w-10 h-10 cursor-pointer"
                              onClick={() => setExpanded((prev) => !prev)}
                            />
                          ) : (
                            <IoChevronDownSharp
                              className="w-10 h-10 cursor-pointer"
                              onClick={() => setExpanded((prev) => !prev)}
                            />
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Card>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Latest Activity
                  </h1>
                  <Box className="flex flex-col justify-between items-center">
                    <StepperComp
                      steps={
                        loadMoreActivity
                          ? ActivitySteps
                          : ActivitySteps?.slice(3)
                      }
                    />
                    {loadMoreActivity ? (
                      <IoChevronUpSharp
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => setLoadMoreActivity((prev) => !prev)}
                      />
                    ) : (
                      <IoChevronDownSharp
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => setLoadMoreActivity((prev) => !prev)}
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card>
                <Box className="text-start p-4">
                  <h1 className="font-semibold mb-3 sm:text-lg xl:text-xl">
                    Latest Session
                  </h1>
                  <Box className="flex justify-between items-center">
                    <Box className="md:me-2 xl:me-3">
                      <Box className="mb-2">
                        <p className="text-medium text-zinc-400 md:mb-1 xl:mb-2">
                          Last Login IP
                        </p>
                        <p className="text-medium">
                          {accountDetails?.lastLoginIP}
                        </p>
                      </Box>
                      <Divider />
                      <Box className="mt-2">
                        <p className="text-medium text-zinc-400 md:mb-1 xl:mb-2">
                          Last Login
                        </p>
                        <p className="text-medium">
                          {accountDetails?.lastLoginTime
                            ? moment(accountDetails?.lastLoginTime).format(
                                generalInfoDetails?.dateFormat || "DD/MM/YYYY"
                              )
                            : " - "}
                        </p>
                      </Box>
                    </Box>
                    <Box className="flex flex-col items-end">
                      <img src="/images/Map.png" />
                      <p className="text-medium">New York, USA</p>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ResetPasswordConfirmationModal
        open={resetPasswordModal}
        toggleHandler={() => setResetPasswordModal((prev) => !prev)}
        submitHandler={() => {
          setResetPasswordModal((prev) => !prev);
          setResetPasswordSendLinkModal((prev) => !prev);
        }}
      />
      <DisableAccountConfirmationModal
        open={disableAccountModal}
        toggleHandler={() => setDisableAccountModal((prev) => !prev)}
        title={`${accountDetails?.isDisabled ? "Enable" : "Disable"} User`}
        subTitle={`Are you sure you want to ${
          accountDetails?.isDisabled ? "Enable" : "Disable"
        } User !`}
        submitHandler={() => {
          dispatch(
            addAccount({
              _id: accountDetails?._id,
              isDisabled: !accountDetails?.isDisabled,
            })
          ).then((res) => {
            console.log(res?.payload, "payload ");
            if (res?.payload?.success) {
              setDisableAccountModal(false);
              dispatch(getAccountDashboardDetails({ id: accountDetails?._id }));
              toast.success(
                `Account ${
                  res?.payload?.data?.isDisabled ? "Disabled" : "Enabled"
                } Successfully`
              );
            }
          });
        }}
      />

      <ModalComp
        width={450}
        open={resetPasswordSendLinkModal}
        isDividerNeeded={false}
        toggleHandler={() => setResetPasswordSendLinkModal((prev) => !prev)}
      >
        <Box className="text-center">
          <h1 className="text-xl font-semibold mb-3">Reset Link Send</h1>
          <p>A password email link is send to the user</p>
        </Box>
      </ModalComp>
      <Box
        className="message-box"
        style={{
          // position: "absolute",
          bottom: messageModalCollapse ? "-530px" : "0px",
          // left: "70%",
        }}
      >
        <MessageDialogBox
          open={messageModal}
          toggle={() => setMessageModal((prev) => !prev)}
          toggleCollapse={() => setMessageModalCollapse((prev) => !prev)}
          data={location.state || ""}
        />
      </Box>
    </>
  );
};

export default AccountProfile;
