import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
const RevenueChart = ({ chartData }) => {
  const [monthFilter, setMonthFilter] = useState(12);
  const [d1, setD1] = useState([]);
  const [d2, setD2] = useState([]);
  useEffect(() => {
    if (chartData) {
      console.log(chartData?.revenueByMonth, "chartData?.revenueByMonth");
      const da1 = [];
      const da2 = [];
      Object.values(chartData?.revenueByMonth)?.map((revenue) => {
        da1.push(revenue?.currentYear);
        da2.push(revenue?.previousYear);
      });
      console.log(da1, da2, "details");
      setD1(da1);
      setD2(da2);
    }
  }, [chartData]);
  let data = {
    series: [
      {
        name: "Last Year",
        data: d1.slice(0, monthFilter),
      },
      {
        name: "This Year",
        data: d2.slice(0, monthFilter),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.35,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          // shadow: {
          //   enabled: true,
          //   color: "#000",
          //   top: 18,
          //   left: 7,
          //   blur: 10,
          //   opacity: 0.2,
          // },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ].slice(0, monthFilter),
      },
      yaxis: {
        min: 0,
        max: Math.ceil(Math.max(Math.max(...d1), Math.max(...d2))),
        tickAmount: 2,
        labels: {
          formatter: function (val, index) {
            return `$${val}`;
          },
          style: {
            cssClass: "text-zinc-400",
            fontSize: "14px",
          },
        },
        // title: {
        //   text: "$ (thousands)",
        // },
      },
      fill: {
        opacity: 1,
      },
      colors: ["#F9DD7B", "#85ABBD"],
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands";
      //     },
      //   },
      // },
    },
  };

  return (
    <div>
      <div id="chart">
        <Box className="flex justify-around">
          <Box className="px-3 flex">
            <Box className="flex items-center me-2">
              <Box
                style={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#85ABBD",
                }}
              ></Box>
              <p className="revenue-chart-legend text-start ps-2">Last Year</p>
            </Box>
            <Box className="flex items-center">
              <Box
                style={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#F9DD7B",
                }}
              ></Box>
              <p className="revenue-chart-legend text-start ps-2">This Year</p>
            </Box>
          </Box>
          <Box>
            <span
              className={`revenue-chart-filter cursor-pointer ${
                monthFilter === 3 && "font-semibold"
              }`}
              style={{
                borderBottom: monthFilter === 3 ? "2px solid black" : "",
              }}
              onClick={() => setMonthFilter(3)}
            >
              3m
            </span>
            <span
              className={`revenue-chart-filter cursor-pointer ${
                monthFilter === 6 && "font-semibold"
              }`}
              style={{
                borderBottom: monthFilter === 6 ? "2px solid black" : "",
              }}
              onClick={() => setMonthFilter(6)}
            >
              6m
            </span>
            <span
              style={{
                borderBottom: monthFilter === 12 ? "2px solid black" : "",
              }}
              className={`revenue-chart-filter cursor-pointer ${
                monthFilter === 12 && "font-semibold"
              }`}
              onClick={() => setMonthFilter(12)}
            >
              12m
            </span>
          </Box>
        </Box>
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          height={250}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default RevenueChart;
