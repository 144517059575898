// import { Navigate, useLocation } from "react-router-dom";
// import Layout from "../components/layout/Layout";
// import { useSelector } from "react-redux";

import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/common/Layout";
import { Navigate, useLocation } from "react-router-dom";
import { userDetails } from "../store/auth/auth.slice";
import { getGeneralInfo } from "../store/settings/generalInfo.slice";

const AuthRoutes = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, loginUserDetails } = useSelector(
    (state) => state.auth
  );
  const token = localStorage.getItem("surfaceShareToken");
  console.log(token, isAuthenticated, "isAuthenticated");
  if (token && !isAuthenticated) {
    dispatch(userDetails()).then((res) => {
      if (!res?.payload?._id) {
        <Navigate
          to="/login"
          replace={true}
          state={{ currentPath: location.pathname }}
        />;
      }
    });
    dispatch(getGeneralInfo());
  } else if (!isAuthenticated || !token) {
    console.log(token, "token");
    return (
      <Navigate
        to="/login"
        replace={true}
        state={{ currentPath: location.pathname }}
      />
    );
  }

  return <Layout>{children}</Layout>;
};
export default AuthRoutes;
