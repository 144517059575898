import React, { useEffect, useRef, useState } from "react";
import ModalComp from "../../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import { Categories } from "../helper";
import TextInputField from "../../../common/Input/TextInput";
import ButtonComponent from "../../../common/Button";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  addCategory,
  deleteCategory,
  getCategories,
} from "../../../../store/settings/categories.slice";
import { MdDelete } from "react-icons/md";
import { FaRegImage } from "react-icons/fa6";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import { uploadImage } from "../../../../store/common/common.slice";
import DeleteCategoryConfirmation from "./DeleteCategoryConfirmation";

const CreateCategoryModal = ({
  open,
  toggleHandler,
  title,
  activeCategory,
  selectedCategory,
  categories,
}) => {
  const dispatch = useDispatch();
  const [isEditAble, setIsEditAble] = useState(false);
  const imgRef = useRef(null);
  const [initialValue, setInitialValue] = useState({
    categoryName: "",
    logo: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    showModal: false,
    submitHandler: () => {},
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedCategory) {
      setIsEditAble(false);
    } else {
      setIsEditAble(true);
    }
    // if (selectedCategory) {
    console.log(selectedCategory, "selected");
    setInitialValue({
      categoryName: selectedCategory?.categoryName || "",
      logo: selectedCategory?.logoLink || "",
    });
    // }
  }, [selectedCategory]);
  const formik = useFormik({
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Category Name is required"),
    }),
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        categoryName: values.categoryName,
        logoLink: values.logo,
        categoryFor: activeCategory.split(" ")[0],
      };
      if (selectedCategory) {
        payload._id = selectedCategory?._id;
      }
      dispatch(addCategory(payload)).then((res) => {
        if (res?.payload?.success) {
          formik.resetForm();
          dispatch(getCategories({ for: "all" }));
          toast.success(
            `Category ${selectedCategory ? "Updated" : "Added"} Successfully`
          );
          toggleHandler();
        }
      });
    },
  });
  return (
    <Box>
      <ModalComp open={open} toggleHandler={toggleHandler} title={title}>
        <Formik onSubmit={formik.handleSubmit}>
          <Box className="p-5 flex flex-col items-center">
            <Box className="my-5 !w-full">
              <Grid container spacing={3}>
                {/* <Box className="flex items-center flex-wrap"> */}
                {categories
                  .find((cat) => cat.type === activeCategory)
                  ?.data?.map((category) => {
                    return (
                      <Grid item xs={2}>
                        <Box className="px-2">
                          <Box
                            className="flex flex-col items-center text-medium p-3"
                            style={{
                              textAlign: "center",
                              backgroundColor:
                                category?._id === selectedCategory?._id
                                  ? "#F9DD7B"
                                  : "#FFFFFF",
                            }}
                          >
                            <Box
                              className="p-1 rounded-lg"
                              style={{ border: "1px solid #323031" }}
                            >
                              <img
                                src={category?.logoLink}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </Box>
                            <h3>{category?.categoryName}</h3>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                {/* </Box> */}
              </Grid>
            </Box>
            <Box className="flex items-center my-2">
              <p className="md:text-md xl:text-lg me-2">Category Name</p>
              <TextInputField
                isShowError={true}
                inputClassName="rounded-xl"
                fullWidth={true}
                name="categoryName"
                formik={formik}
                disabled={!isEditAble}
                sx={{
                  backgroundColor: !isEditAble ? "#F7F8FA" : "#FFFFFF",
                }}
              />
            </Box>
            <Box className="flex items-center my-5">
              <p className="md:text-md xl:text-lg me-2">Logo</p>
              {formik.values?.logo ? (
                loading ? (
                  <Loading isLoading={loading} />
                ) : (
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={formik.values?.logo}
                    alt=""
                    className="rounded-lg"
                    onClick={() => isEditAble && imgRef.current.click()}
                  />
                )
              ) : (
                <Box
                  className="flex flex-col justify-center items-center"
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#EEF9FF",
                  }}
                  onClick={() => isEditAble && imgRef.current.click()}
                >
                  <FaRegImage className="w-7 h-7" />
                  <p>Add image</p>
                </Box>
              )}
              <input
                type="file"
                ref={imgRef}
                hidden={true}
                onChange={(e) => {
                  // const file = e.target.files[0];
                  setLoading(true);
                  const formData = new FormData();
                  formData.append("photo", e.target.files[0]);
                  dispatch(uploadImage(formData)).then((op) => {
                    setLoading(false);
                    const value = op?.payload?.url[0];
                    formik.setFieldValue("logo", value);
                  });
                }}
              />
            </Box>
            <Box className="my-5">
              {!selectedCategory && (
                <ButtonComponent
                  btnText="Cancel"
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid black",
                    borderRadius: "10px",
                    color: "black",
                    "&:hover": { backgroundColor: "#FFFFFF" },
                    boxShadow: "none",
                    width: "125px",
                  }}
                  onClick={toggleHandler}
                />
              )}
              {selectedCategory && !isEditAble && (
                <ButtonComponent
                  btnText={"Edit"}
                  // btnType="submit"
                  onClick={() => setIsEditAble(true)}
                  style={{
                    backgroundColor: "#263238",
                    border: "1px solid black",
                    borderRadius: "10px",
                    width: "125px",
                    "&:hover": { backgroundColor: "#263238" },
                  }}
                />
              )}
              {isEditAble && (
                <ButtonComponent
                  btnText={"Save"}
                  btnType="submit"
                  onClick={formik.handleSubmit}
                  style={{
                    backgroundColor: "#263238",
                    border: "1px solid black",
                    borderRadius: "10px",
                    width: "125px",
                    "&:hover": { backgroundColor: "#263238" },
                  }}
                />
              )}
              {selectedCategory && (
                <ButtonComponent
                  style={{
                    backgroundColor: "#F7F8FA",
                    border: "1px solid black",
                    borderRadius: "10px",
                    width: "30px",
                    "&:hover": { backgroundColor: "#F7F8FA" },
                  }}
                  startIcon={<MdDelete className="w-6 h-6 text-black" />}
                  onClick={() => {
                    setConfirmationModal({
                      showModal: true,
                      submitHandler: () => {
                        dispatch(
                          deleteCategory({ categoryId: selectedCategory?._id })
                        ).then((res) => {
                          if (res?.payload?.success) {
                            toast.success("Category Deleted Successfully!");
                            dispatch(getCategories({ for: "all" }));
                            setConfirmationModal({
                              showModal: false,
                              submitHandler: () => {},
                            });
                            toggleHandler();
                          }
                        });
                      },
                    });
                  }}
                />
              )}
            </Box>
          </Box>
        </Formik>
      </ModalComp>
      <DeleteCategoryConfirmation
        open={confirmationModal.showModal}
        submitHandler={confirmationModal.submitHandler}
        toggleHandler={() =>
          setConfirmationModal({ showModal: false, submitHandler: () => {} })
        }
      />
    </Box>
  );
};

export default CreateCategoryModal;
