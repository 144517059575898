import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getCategories = createAsyncThunk(
  "settings/getCategories",
  async (params) => {
    const getData = async () => {
      return GET("/category/get", { params }).then((res) => {
        console.log(res, "response");
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addCategory = createAsyncThunk(
  "settings/addCategory",
  async (payload) => {
    const postData = async () => {
      return POST("/category/create", payload).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "settings/deleteCategory",
  async (params) => {
    const postData = async () => {
      return POST(`/category/delete/${params?.categoryId}`).then((res) => {
        console.log(res, "response");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    isLoading: false,
    categories: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default categoriesSlice.reducer;
