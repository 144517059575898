import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { PiPencilSimpleBold } from "react-icons/pi";
import { ReactComponent as PaperCup } from "../../../../assets/images/PaperCups.svg";
import { FieldArray, FormikProvider, useFormik } from "formik";
import TextInputField from "../../../common/Input/TextInput";
import { IoCloseSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import CreateCancellationTermsModal from "./CreateCancellationTermsModal";
import {
  editCancellationPolicy,
  getCancellationPolicy,
} from "../../../../store/settings/rulesReporting";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../../common/Button";
import { toast } from "react-toastify";
// import CreateCategoryModal from "./CreateCategoryModal";

const initialValue = {
  surfaceCancellationReason: [""],
  surfaceReporting: [""],
  supplyReporting: [""],
  userReporting: [""],
};
const CancellationTermsCard = ({ data, toggleHandler }) => {
  return (
    <Box className="p-2 rounded-md mb-2" sx={{ backgroundColor: "#F7F8FA" }}>
      <Box className="flex justify-between">
        <h1 className="md:text-md xl:text-lg">{data?.title}</h1>
        <span
          className="cursor-pointer bg-black rounded-full md:p-1 xl:p-2"
          onClick={toggleHandler}
        >
          {/* <GoPencil className="w-5 h-5" color="white" /> */}
          <PiPencilSimpleBold
            className="md:w-5 md:h-5 xl:w-6 xl:h-6"
            color="white"
          />
        </span>
      </Box>
      <Box
        className="p-2 bg-white m-3"
        dangerouslySetInnerHTML={{ __html: data?.description }}
      ></Box>
    </Box>
  );
};

const CancellationTerms = () => {
  const dispatch = useDispatch();
  const { cancelltionPolicies } = useSelector(
    (state) => state.settings.rulesAndReportingInfo
  );

  const [initialValues, setInitialValues] = useState(initialValue);
  const [createModal, setCreateModal] = useState({
    showModal: false,
    data: undefined,
  });
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(getCancellationPolicy()).then((d) => {
      if (d?.payload?.data) {
        const val = {
          surfaceCancellationReason: d?.payload?.data
            ?.surfaceCancellationReason || [""],
          surfaceReporting: d?.payload?.data?.surfaceReporting || ["xyz"],
          supplyReporting: d?.payload?.data?.supplyReporting || ["wed"],
          userReporting: d?.payload?.data?.userReporting || ["scf"],
        };
        setInitialValues((prev) => ({ ...prev, ...val }));
      }
    });
  }, []);

  return (
    <>
      <Box className="p-4 text-start bg-white rounded-lg mb-2">
        <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
          Cancellation Terms & Conditions
        </h1>
        <Grid container spacing={3} className="mb-2">
          <Grid item xs={6}>
            <CancellationTermsCard
              data={{
                title: "Surface Cancellation Policy",
                description: cancelltionPolicies["surfaceCancellationPolicy"],
              }}
              toggleHandler={() =>
                setCreateModal((prev) => ({
                  showModal: true,
                  data: {
                    title: "Surface Cancellation Policy",
                    description:
                      cancelltionPolicies["surfaceCancellationPolicy"],
                  },
                }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CancellationTermsCard
              data={{
                title: "Supply Cancellation Policy",
                description: cancelltionPolicies["supplyCancellationPolicy"],
              }}
              toggleHandler={() =>
                setCreateModal((prev) => ({
                  showModal: true,
                  data: {
                    title: "Supply Cancellation Policy",
                    description:
                      cancelltionPolicies["supplyCancellationPolicy"],
                  },
                }))
              }
            />
          </Grid>
        </Grid>
        <Divider />
        <FormikProvider value={formik}>
          <Box className="my-3">
            <h1 className="mb-2 md:text-md xl:text-lg">
              Surface Reserve Cancellation
            </h1>
            <p className="mb-1">
              The following will be the reasons to select while canceling a
              surface reservation.
            </p>
            <Box className="my-3">
              <FieldArray
                name="surfaceCancellationReason"
                render={(arrayHelper) => {
                  return (
                    <Box>
                      {formik.values?.surfaceCancellationReason?.map(
                        (reason, index) => {
                          return (
                            <Grid container className="mb-2">
                              <Grid
                                item
                                xl={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h3>Reason {index + 1}</h3>
                              </Grid>
                              <Grid item xl={6}>
                                <Box className="flex items-center">
                                  <TextInputField
                                    wrapperClassName="w-full"
                                    name={`surfaceCancellationReason[${index}]`}
                                    formik={formik}
                                    inputClassName="rounded-xl text-medium"
                                    fullWidth={true}
                                    sx={{
                                      backgroundColor: "#F7F8FA",
                                    }}
                                  />
                                  <Box
                                    className={"p-2 rounded-md ms-2"}
                                    style={{ backgroundColor: "#F7F8FA" }}
                                    onClick={() => arrayHelper.remove(index)}
                                  >
                                    <IoCloseSharp className="w-5 h-5" />
                                  </Box>
                                  <Box
                                    className={"p-2 rounded-md ms-2"}
                                    style={{ backgroundColor: "#F7F8FA" }}
                                    onClick={() => {
                                      arrayHelper.insert(index + 1, "");
                                    }}
                                  >
                                    <FaPlus className="w-5 h-5" />
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                      <Box className="ms-auto">
                        <ButtonComponent
                          btnText="Save"
                          // btnType="submit"
                          style={{
                            marginLeft: "auto",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid black",
                            borderRadius: "10px",
                            color: "black",
                            "&:hover": { backgroundColor: "#FFFFFF" },
                            boxShadow: "none",
                            // width: "9rem",
                          }}
                          onClick={() => {
                            dispatch(
                              editCancellationPolicy({
                                surfaceCancellationReason:
                                  formik.values?.surfaceCancellationReason,
                              })
                            ).then((data) => {
                              if (data?.payload?.success) {
                                toast.success(
                                  "Surface Cancellation Reasons Updated Successfully!"
                                );
                              }
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box className="my-2">
            <h1 className="mb-2 md:text-lg xl:text-xl font-semibold">
              Reporting
            </h1>
            <Box className="px-3">
              <Box className="my-3">
                <h1 className="mb-2 md:text-md xl:text-lg">
                  Surface Reporting
                </h1>
                <p className="mb-1">
                  The following will be the reasons to select while reporting a
                  surface.
                </p>
                <Box className="my-3">
                  <FieldArray
                    name="surfaceReporting"
                    render={(arrayHelper) => {
                      return (
                        <Box>
                          {formik.values?.surfaceReporting?.map(
                            (reason, index) => {
                              return (
                                <Grid container className="mb-2">
                                  <Grid
                                    item
                                    xl={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h3>Reason {index + 1}</h3>
                                  </Grid>
                                  <Grid item xl={6}>
                                    <Box className="flex items-center">
                                      <TextInputField
                                        wrapperClassName="w-full"
                                        name={`surfaceReporting[${index}]`}
                                        formik={formik}
                                        inputClassName="rounded-xl text-medium"
                                        fullWidth={true}
                                        sx={{
                                          backgroundColor: "#F7F8FA",
                                        }}
                                      />
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() =>
                                          arrayHelper.remove(index)
                                        }
                                      >
                                        <IoCloseSharp className="w-5 h-5" />
                                      </Box>
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() => {
                                          arrayHelper.insert(index + 1, "");
                                        }}
                                      >
                                        <FaPlus className="w-5 h-5" />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
              <Divider />
              <Box className="my-3">
                <h1 className="mb-2 md:text-md xl:text-lg">Supply Reporting</h1>
                <p className="mb-1">
                  The following will be the reasons to select while reporting a
                  supply.
                </p>
                <Box className="my-3">
                  <FieldArray
                    name="supplyReporting"
                    render={(arrayHelper) => {
                      return (
                        <Box>
                          {formik.values?.supplyReporting?.map(
                            (reason, index) => {
                              return (
                                <Grid container className="mb-2">
                                  <Grid
                                    item
                                    xl={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h3>Reason {index + 1}</h3>
                                  </Grid>
                                  <Grid item xl={6}>
                                    <Box className="flex items-center">
                                      <TextInputField
                                        wrapperClassName="w-full"
                                        name={`supplyReporting[${index}]`}
                                        formik={formik}
                                        inputClassName="rounded-xl text-medium"
                                        fullWidth={true}
                                        sx={{
                                          backgroundColor: "#F7F8FA",
                                        }}
                                      />
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() =>
                                          arrayHelper.remove(index)
                                        }
                                      >
                                        <IoCloseSharp className="w-5 h-5" />
                                      </Box>
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() => {
                                          arrayHelper.insert(index + 1, "");
                                        }}
                                      >
                                        <FaPlus className="w-5 h-5" />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
              <Divider />
              <Box className="my-3">
                <h1 className="mb-2 md:text-md xl:text-lg">User Reporting</h1>
                <p className="mb-1">
                  The following will be the reasons to select while reporting a
                  surface.
                </p>
                <Box className="my-3">
                  <FieldArray
                    name="userReporting"
                    render={(arrayHelper) => {
                      return (
                        <Box>
                          {formik.values?.userReporting?.map(
                            (reason, index) => {
                              return (
                                <Grid container className="mb-2">
                                  <Grid
                                    item
                                    xl={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h3>Reason {index + 1}</h3>
                                  </Grid>
                                  <Grid item xl={6}>
                                    <Box className="flex items-center">
                                      <TextInputField
                                        wrapperClassName="w-full"
                                        name={`userReporting[${index}]`}
                                        formik={formik}
                                        inputClassName="rounded-xl text-medium"
                                        fullWidth={true}
                                        sx={{
                                          backgroundColor: "#F7F8FA",
                                        }}
                                      />
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() =>
                                          arrayHelper.remove(index)
                                        }
                                      >
                                        <IoCloseSharp className="w-5 h-5" />
                                      </Box>
                                      <Box
                                        className={"p-2 rounded-md ms-2"}
                                        style={{ backgroundColor: "#F7F8FA" }}
                                        onClick={() => {
                                          arrayHelper.insert(index + 1, "");
                                        }}
                                      >
                                        <FaPlus className="w-5 h-5" />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="ms-auto">
              <ButtonComponent
                btnText="Save"
                // btnType="submit"
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  borderRadius: "10px",
                  color: "black",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                  boxShadow: "none",
                  // width: "9rem",
                }}
                onClick={() => {
                  dispatch(
                    editCancellationPolicy({
                      surfaceReporting: formik.values?.surfaceReporting,
                      supplyReporting: formik?.values?.supplyReporting,
                      userReporting: formik?.values?.userReporting,
                    })
                  ).then((data) => {
                    if (data?.payload?.success) {
                      toast.success("Reporting Reasons Updated Successfully!");
                    }
                  });
                }}
              />
            </Box>
          </Box>
        </FormikProvider>
      </Box>
      <CreateCancellationTermsModal
        // width={1000}
        title={"Edit"}
        open={createModal.showModal}
        data={createModal?.data}
        toggleHandler={() =>
          setCreateModal({ showModal: false, data: undefined })
        }
      />
    </>
  );
};

export default CancellationTerms;
