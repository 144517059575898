import { Box, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import CustomTableContainer from "../common/Table/CustomTableContainer";
import GeneralInfo from "./GeneralInfo";
import ManageAccess from "./ManageAccess";
import ConfigEmail from "./ConfigEmail";
import AppSetting from "./AppSetting";
import BreadCrumb from "../common/BreadCrumb";
import HelpSupport from "./HelpSupport/HelpSupport.jsx";

const Setting = () => {
  const [activeTab, setActiveTab] = useState("generalInfo");
  const renderTab = (activeTab) => {
    switch (activeTab) {
      case "generalInfo":
        return <GeneralInfo />;
      case "manageAccess":
        return <ManageAccess />;
      case "configEmail":
        return <ConfigEmail />;
      case "appSetting":
        return <AppSetting />;
      case "help&support":
        return <HelpSupport />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <Box className="flex ms-2 mb-4">
        {/* <BreadCrumb title="Settings" parents={[]} parentsURL={[]} /> */}
        <h1 className="text-2xl font-bold text-start me-3">Settings</h1>
      </Box>
      <Box className="text-md setting-wraper">
        <Grid
          container
          className="rounded-md mx-2 text-nowrap"
          // sx={{ width: "calc(100vw - 18rem - 8px)" }}
        >
          {/* <Grid container> */}
          <Grid
            item
            xs={2}
            className="cursor-pointer"
            onClick={() => setActiveTab("generalInfo")}
          >
            <h3
              className="py-1 px-3 font-semibold"
              style={{
                borderBottom:
                  activeTab === "generalInfo" ? "2px solid #F6CA30" : "",
                color: activeTab === "generalInfo" ? "#F6CA30" : "",
              }}
            >
              General Info{" "}
              {/* <span
                    className="py-1 px-2 text-white rounded-full"
                    style={{ backgroundColor: "#F6CA30" }}
                  >
                    3
                  </span> */}
            </h3>
          </Grid>
          <Grid
            item
            xs={2}
            className="cursor-pointer"
            onClick={() => setActiveTab("manageAccess")}
          >
            <h3
              className="py-1 px-3 font-semibold"
              style={{
                borderBottom:
                  activeTab === "manageAccess" ? "2px solid #F6CA30" : "",
                color: activeTab === "manageAccess" ? "#F6CA30" : "",
              }}
            >
              Manage Access{" "}
              {/* <span
                    className="py-1 px-2 rounded-full"
                    style={{ border: "1px solid #000000" }}
                  >
                    2
                  </span> */}
            </h3>
          </Grid>
          <Grid
            item
            xs={2}
            className="cursor-pointer"
            onClick={() => setActiveTab("configEmail")}
          >
            <h3
              className="py-1 px-3 font-semibold"
              style={{
                borderBottom:
                  activeTab === "configEmail" ? "2px solid #F6CA30" : "",
                color: activeTab === "configEmail" ? "#F6CA30" : "",
              }}
            >
              Config Email{" "}
              {/* <span
                    className="py-1 px-2 rounded-full"
                    style={{ border: "1px solid #000000" }}
                  >
                    1
                  </span> */}
            </h3>
          </Grid>
          <Grid
            item
            xs={2}
            className="cursor-pointer"
            onClick={() => setActiveTab("appSetting")}
          >
            <h3
              className="py-1 px-3 font-semibold"
              style={{
                borderBottom:
                  activeTab === "appSetting" ? "2px solid #F6CA30" : "",
                color: activeTab === "appSetting" ? "#F6CA30" : "",
              }}
            >
              App Settings{" "}
            </h3>
          </Grid>
          <Grid
            item
            xs={2}
            className="cursor-pointer"
            onClick={() => setActiveTab("help&support")}
          >
            <h3
              className="py-1 px-3 font-semibold"
              style={{
                borderBottom:
                  activeTab === "help&support" ? "2px solid #F6CA30" : "",
                color: activeTab === "help&support" ? "#F6CA30" : "",
              }}
            >
              Help & Support{" "}
              {/* <span
                    className="py-1 px-2 rounded-full"
                    style={{ border: "1px solid #000000" }}
                  >
                    0
                  </span> */}
            </h3>
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Divider />
        <Box
          sx={{
            maxHeight: `calc(100vh - 102px - 1.5rem)`,
            overflowY: "scroll",
          }}
        >
          {renderTab(activeTab)}
        </Box>
      </Box>
    </>
  );
};

export default Setting;
