import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { get } from "lodash";

const RadioInput = ({
  name,
  formik,
  isDisabled = false,
  options,
  isVertical = false,
  isShowError = true,
  errorMessage,
}) => {
  const { values, touched, errors, setFieldValue, handleBlur } = formik || {};
  console.log(values, "values in general info");
  const isError = !!get(touched, name, "") && !!get(errors, name, "");
  return (
    <>
      <RadioGroup
        row={isVertical}
        aria-label="options"
        name="options"
        value={get(values, name, "") || null}
        onChange={(event) => {
          setFieldValue(name, event.target.value);
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            disabled={isDisabled}
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "#263238",
                  },
                }}
              />
            }
            label={option.label}
            className="text-medium"
          />
        ))}
      </RadioGroup>
      {isShowError && (isError || !!errorMessage) && (
        <div className="h-2 text-xs text-red-500">
          {get(touched, name, "") ? get(errors, name, "") || errorMessage : ""}
        </div>
      )}
    </>
  );
};

export default RadioInput;
