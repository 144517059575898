import { Box } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BreadCrumb = ({
  parents = [],
  parentsURL = [],
  parentsData = [],
  title = "",
}) => {
  const navigate = useNavigate();
  return (
    <Box className="flex">
      {parents &&
        parents?.map((parent, index) => {
          console.log(parentsData.length > index ? parentsData[index] : {});
          return (
            <h1 className="text-2xl font-bold text-start mb-5 ms-2 flex">
              <p
                onClick={() =>
                  navigate(
                    parentsURL.length > index ? parentsURL[index] : "#",
                    {
                      state:
                        parentsData.length > index ? parentsData[index] : {},
                    }
                  )
                }
                className="me-2 cursor-pointer"
              >
                {parent}
              </p>
              <span>{">"}</span>
            </h1>
          );
        })}
      <h1 className="text-2xl font-bold text-start mb-5 ms-2">{title}</h1>
    </Box>
  );
};

export default BreadCrumb;
