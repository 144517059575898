import React, { useEffect, useRef, useState } from "react";
import ModalComp from "../common/ModalComp";
import { Box, Grid } from "@mui/material";
import TextInputField from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import SelectInput from "../common/Input/SelectInput";
// import Select, { components } from "react-select";
import DateInput from "../common/Input/DateInput";
import { statusOptions } from "./helper";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getStates,
  resetCities,
  resetCountries,
  resetStates,
  uploadImage,
} from "../../store/common/common.slice";
import { addAccount } from "../../store/account/account.slice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Loading from "../common/Loading";
// import DummyUser from "../../../public/images/dummy-user.jpg";

const initialValue = {
  logo: "",
  acCode: "",
  phone: "",
  fname: "",
  lname: "",
  address: "",
  gender: "",
  city: "",
  zipCode: "",
  status: "active",
  state: "",
  email: "",
  country: "",
  dob: "",
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const zipCodeRegExp = /^[1-9][0-9]{5}$/;

const AddNewAccountModal = ({ open, toggleHandler, title, getAccountList }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { countries, states, cities } = useSelector((state) => state.common);
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object({
      acCode: Yup.string().required("Ac Code is required"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "too short")
        .max(10, "too long")
        .required("Phone No is required"),
      fname: Yup.string().required("First Name is required"),
      lname: Yup.string().required("Last Name is required"),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      city: Yup.string().required("City is required"),
      zipCode: Yup.string()
        .matches(zipCodeRegExp, "Zip Code is not valid")
        .required("Zip Code is required"),
      status: Yup.string().required("Status is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      email: Yup.string().email("Validate Email").required("Email is required"),
      dob: Yup.date()
        .typeError("Enter Valid DOB")
        .max(new Date(), "Enter Valid DOB")
        .required("DOB is required"),
    }),
    onSubmit: (values) => {
      console.log(values, "values");
      dispatch(addAccount(values)).then((res) => {
        console.log(res, "res");
        if (res.payload?.success) {
          getAccountList();
          toggleHandler();
          toast.success(`Account Added Successfly!`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });
  const imgRef = useRef(null);
  console.log(formik.errors, "formik.errors");

  useEffect(() => {
    if (open) {
      dispatch(getCountries());
    }
    return () => {
      dispatch(resetCountries());
      dispatch(resetCities());
      dispatch(resetStates());
    };
  }, [open]);
  return (
    <div className="add-account-modal">
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={"800px"}
        // modalClass="responsive-width"
      >
        <Box className="p-3" sx={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                style={{ width: "100px", height: "100px" }}
                className="rounded-full flex items-center justify-center"
              >
                {loading ? (
                  <Loading isLoading={loading} />
                ) : (
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={formik?.values?.logo || "/images/dummy-user.jpg"}
                    // ref={imgPreviewRef}
                    alt=""
                    className="rounded-full"
                    // onClick={() => imgRef.current.click()}
                  />
                )}
                <input
                  type="file"
                  ref={imgRef}
                  hidden={true}
                  onChange={(e) => {
                    // const file = e.target.files[0];
                    setLoading(true);
                    const formData = new FormData();
                    formData.append("photo", e.target.files[0]);
                    dispatch(uploadImage(formData)).then((op) => {
                      setLoading(false);
                      const value = op?.payload?.url[0];
                      formik.setFieldValue("logo", value);
                    });
                    // const reader = new FileReader();
                    // reader.onload = () => {
                    //   // Set the image source to the file content
                    //   if (
                    //     imgPreviewRef.current &&
                    //     typeof reader.result === "string"
                    //   ) {
                    //     imgPreviewRef.current.src = reader.result;
                    //   }
                    // };
                    // reader.readAsDataURL(file);
                  }}
                />
                <Box
                  style={{ marginLeft: "-15px" }}
                  className="flex flex-col justify-between"
                >
                  <span
                    className="cursor-pointer rounded-full mb-8"
                    style={{ backgroundColor: "#F6CA30" }}
                  >
                    <GoPencil
                      className="w-6 h-6 p-1"
                      color="white"
                      onClick={() => imgRef.current.click()}
                    />
                  </span>
                  <span
                    className="cursor-pointer rounded-full"
                    style={{ backgroundColor: "#F3F4F6" }}
                    onClick={() => formik.setFieldValue("logo", "")}
                  >
                    <RiDeleteBinLine className="w-6 h-6 p-1" color="red" />
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p className="text-medium">Ac Code *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    // wrapperClassName="rounded-full"
                    name="acCode"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">First Name *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="fname"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Last Name *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="lname"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Gender *</p>
                </Grid>
                <Grid item xs={8}>
                  <SelectInput
                    defaultValue={{}}
                    name={"gender"}
                    formik={formik}
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                    className="text-medium"
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">DOB *</p>
                </Grid>
                <Grid item xs={8}>
                  <DateInput
                    className="text-medium"
                    name={"dob"}
                    formik={formik}
                    // maxDate={dayjs()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Status *</p>
                </Grid>
                <Grid item xs={8}>
                  <SelectInput
                    name={"status"}
                    options={statusOptions}
                    // hasIcon={true}
                    formik={formik}
                    formatOptionLabel={(props) => (
                      <div className="flex items-center">
                        {props?.icon && (
                          <span className="me-1">{props?.icon}</span>
                        )}
                        {props.label}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Email *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="email"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p className="text-medium">Phone *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="phone"
                    type="number"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Address *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="address"
                    formik={formik}
                    multiline={true}
                    minRows={4}
                    maxRows={5}
                    // wrapperClassName="rounded-full"
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Country *</p>
                </Grid>
                <Grid item xs={8}>
                  <SelectInput
                    name={"country"}
                    formik={formik}
                    onChange={(event) => {
                      formik.setFieldValue("country", event.value);
                      console.log(event.value, "event details");
                      dispatch(getStates(event.value));
                    }}
                    options={
                      countries?.length > 0
                        ? countries?.map((country) => ({
                            label: country?.name,
                            value: country?.iso2,
                          }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">State *</p>
                </Grid>
                <Grid item xs={8}>
                  <SelectInput
                    name={"state"}
                    formik={formik}
                    onChange={(event) => {
                      formik.setFieldValue("state", event.value);
                      console.log(event.value, "event details");
                      dispatch(
                        getCities({
                          countryCode: formik.values.country,
                          stateCode: event.value,
                        })
                      );
                    }}
                    options={
                      states?.length > 0
                        ? states?.map((state) => ({
                            label: state?.name,
                            value: state?.iso2,
                          }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">City *</p>
                </Grid>
                <Grid item xs={8}>
                  <SelectInput
                    name={"city"}
                    formik={formik}
                    // onChange={(event) => {
                    //   console.log(event, "event details city");
                    //   formik.setFieldValue("city", event.value);
                    //   // dispatch(getStates(event.value));
                    // }}
                    options={
                      cities?.length > 0
                        ? cities?.map((city) => ({
                            label: city?.name,
                            value: city?.name,
                          }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className="text-medium">Zip Code *</p>
                </Grid>
                <Grid item xs={8}>
                  <TextInputField
                    name="zipCode"
                    type="number"
                    formik={formik}
                    inputClassName="rounded-xl text-medium"
                    fullWidth={true}
                    sx={{
                      backgroundColor: "#F7F8FA",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} className="flex justify-between">
              <ButtonComponent
                btnText="Cancel"
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  borderRadius: "10px",
                  color: "black",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                  boxShadow: "none",
                  width: "9rem",
                }}
                onClick={() => {
                  formik.resetForm();
                  toggleHandler();
                }}
              />
              <ButtonComponent
                btnText="Save"
                btnType="submit"
                btnClass="!px-7"
                onClick={formik.handleSubmit}
                //   startIcon={<RiMessage2Fill />}
                style={{
                  backgroundColor: "#263238",
                  border: "1px solid black",
                  borderRadius: "10px",
                  width: "9rem",
                  "&:hover": { backgroundColor: "#263238" },
                }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
      </ModalComp>
    </div>
  );
};

export default AddNewAccountModal;
