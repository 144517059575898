import React, { useEffect, useState } from "react";
import ModalComp from "../../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import TextInputField from "../../../common/Input/TextInput";
import ButtonComponent from "../../../common/Button";
import SelectInput from "../../../common/Input/SelectInput";
import { Form, Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  addTaxCommission,
  getTaxCommissionById,
  getTaxCommissions,
} from "../../../../store/settings/taxcommission.slice";
import {
  getCountries,
  getStates,
  resetStates,
} from "../../../../store/common/common.slice";

const initialValue = {
  taxName: "",
  taxCode: "",
  taxRate: 0,
  country: "",
  state: [],
};

const CreateNewTaxModal = ({ open, id, toggleHandler, title, width }) => {
  const dispatch = useDispatch();
  const { countries, states, cities } = useSelector((state) => state.common);
  const [initialValues, setInitialValues] = useState(initialValue);

  useEffect(() => {
    if (id) {
      dispatch(getTaxCommissionById({ id: id })).then((data) => {
        console.log(data?.payload?.country, "data?.payload?.country");
        dispatch(getStates(data?.payload?.country));
        if (data?.payload) {
          const prefilData = {
            taxName: data?.payload?.taxName || "",
            taxCode: data?.payload?.taxCode || "",
            taxRate: data?.payload?.taxRate || 0,
            country: data?.payload?.country || "",
            state: data?.payload?.state || "",
          };
          setInitialValues(prefilData);
        }
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [id]);

  useEffect(() => {
    if (open) {
      dispatch(getCountries());
      dispatch(resetStates());
    }
  }, [open]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      taxName: Yup.string().required("Enter Tax Name"),
      taxCode: Yup.string().required("Enter Tax Code"),
      taxRate: Yup.number().min(1, "Enter Tax Rate"),
      country: Yup.string().required("Select Country"),
      state: Yup.array().min(1, "Select State"),
    }),
    onSubmit: (values) => {
      console.log(values, "jhjadsdsgfu");
      const payload = values;
      if (id) {
        payload._id = id;
      }
      dispatch(addTaxCommission(values)).then((data) => {
        if (data?.payload?.success) {
          toast.success(`Tax ${id ? "Edited" : "Added"} Successfully!`);
          dispatch(getTaxCommissions());
          formik.resetForm();
          toggleHandler();
        } else {
          toast.error(data?.message);
        }
      });
    },
  });
  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={width}
      >
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <Box className="p-5">
              <Box>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    <p>Tax Name *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="taxName"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={true}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    <p>Tax Code *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="taxCode"
                          // type="number"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={false}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    <p>Tax Rate *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1 ms-2">
                        <TextInputField
                          formik={formik}
                          name="taxRate"
                          type="number"
                          inputClassName="rounded-xl"
                          fullWidth={true}
                          // isShowError={false}
                          sx={{
                            backgroundColor: "#F7F8FA",
                          }}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    <p>Country *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1 ms-2">
                        <SelectInput
                          name={"country"}
                          defaultValue={" "}
                          formik={formik}
                          onChange={(event) => {
                            formik.setFieldValue("country", event.value);
                            console.log(event.value, "event details");
                            dispatch(getStates(event.value));
                          }}
                          value={countries
                            ?.map((country) => ({
                              label: country?.name,
                              value: country?.iso2,
                            }))
                            .find(
                              (country) =>
                                country.value === formik.values.country
                            )}
                          options={
                            countries?.length > 0
                              ? countries?.map((country) => ({
                                  label: country?.name,
                                  value: country?.iso2,
                                }))
                              : []
                          }
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    <p>State *</p>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className="flex items-center">
                      <span className="flex-1 ms-2">
                        <SelectInput
                          name={"state"}
                          formik={formik}
                          defaultValue={" "}
                          isMulti={true}
                          value={states
                            ?.map((state) => ({
                              label: state?.name,
                              value: state?.iso2,
                            }))
                            ?.filter((state) =>
                              formik.values.state.includes(state?.value)
                            )}
                          onChange={(event) => {
                            console.log(event, "kig");
                            formik.setFieldValue(
                              "state",
                              event?.map((e) => e?.value)
                            );
                          }}
                          options={
                            states?.length > 0
                              ? states?.map((state) => ({
                                  label: state?.name,
                                  value: state?.iso2,
                                }))
                              : []
                          }
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="my-5 flex justify-center">
                      <ButtonComponent
                        btnText="Cancel"
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid black",
                          borderRadius: "10px",
                          color: "black",
                          "&:hover": { backgroundColor: "#FFFFFF" },
                          boxShadow: "none",
                          width: "125px",
                        }}
                        onClick={() => {
                          formik.resetForm();
                          toggleHandler();
                        }}
                      />
                      <ButtonComponent
                        btnText="Save"
                        btnType="submit"
                        style={{
                          backgroundColor: "#263238",
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "125px",
                          "&:hover": { backgroundColor: "#263238" },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        </Formik>
      </ModalComp>
    </div>
  );
};

export default CreateNewTaxModal;
