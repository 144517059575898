import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getGeneralInfo = createAsyncThunk(
  "settings/getGeneralInfo",
  async () => {
    const getData = async () => {
      return GET("/settings/getGeneralInfo").then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const saveGeneralInfo = createAsyncThunk(
  "settings/saveGeneralInfo",
  async (payload) => {
    const postData = async () => {
      return POST("/settings/saveGeneralInfo", payload).then((res) => {
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
// export const addAppArticle = createAsyncThunk(
//   "settings/addAppArticle",
//   async (payload) => {
//     const postData = async () => {
//       return POST("/apparticle/add", payload).then((res) => {
//         console.log(res, "response");
//         return res;
//       });
//     };
//     try {
//       return await postData();
//     } catch (error) {
//       return [];
//     }
//   }
// );

const generalInfoSlice = createSlice({
  name: "generalInfo",
  initialState: {
    isLoading: false,
    generalInfoDetails: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getGeneralInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.generalInfoDetails = action.payload || [];
    });
    builder.addCase(getGeneralInfo.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default generalInfoSlice.reducer;
