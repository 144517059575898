import { Box, Grid, InputAdornment, IconButton, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StatisticCard } from "../common/StatisticCard";
import SearchInput from "../common/Input/TextInput";
import ButtonComponent from "../common/Button";
import TableContainerComp from "../common/Table/TableContainer";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { SupplyData, generateColumns } from "./helper";
import { FiDownload } from "react-icons/fi";
import { IoPrintOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { downloadSupplies, getSupplies } from "../../store/supply/supply.slice";

const SupplyWiseComp = () => {
  const dispatch = useDispatch();
  const {
    supplies: { data: Supplies, total_data, activeCount, inActiveCount },
    isLoading,
  } = useSelector((state) => state.supply);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [searchState, setSearchState] = useState("");
  const [sorting, setSorting] = useState({
    order: "asc",
    orderBy: "createdAt",
  });
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = generateColumns(
    generalInfoDetails?.dateFormat || "DD/MM/YYYY"
  );
  const getAllSupplies = (params) => {
    // setPagination((prev) => ({
    //   page: params?.page || prev?.page,
    //   pageSize: params?.pageSize || prev?.pageSize,
    // }));
    // setSorting((prev) => ({
    //   sortBy: params?.sortBy || prev?.sortBy,
    //   sortType: params?.sortType || prev?.sortType,
    // }));
    const payload = {
      limit: params?.pageSize || pagination?.pageSize,
      page: params?.page || pagination?.page,
      sortby: params?.orderBy || sorting?.orderBy,
      sorttype: params?.order || sorting?.order,
      q: params?.q || "",
      // order: params?.order || tab,
    };
    dispatch(getSupplies(payload));
  };

  const pageHandler = (page, pageSize) => {
    setPagination({ page: page, pageSize: pageSize });
    getAllSupplies({ page, pageSize });
  };
  const sortingHandler = (orderByCol) => {
    console.log(orderByCol, sorting, "orderBy");
    const isAsc = sorting?.orderBy === orderByCol && sorting?.order === "asc";
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
    getAllSupplies({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
  };
  const searchHandler = (e) => {
    console.log(e, "value");
    getAllSupplies({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  useEffect(() => {
    getAllSupplies();
  }, []);
  console.log(Supplies, "Supplies");
  return (
    <Box className="surface-wraper">
      <Box className="flex my-3" style={{ padding: "0px 2rem" }}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={6} lg={3} className="p-0">
            <StatisticCard
              title="Total Supplies"
              text={total_data || "0"}
              borderColor={"#F44646"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <StatisticCard
              title="Active Supplies"
              text={activeCount || "0"}
              borderColor={"#85ABBD"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <StatisticCard
              title="Inactive Supplies"
              text={inActiveCount || "0"}
              borderColor={"#00B812"}
              titleClass={"text-start md:mb-5 xl:mb-7 md:text-lg xl:text-xl"}
              textClass={"font-bold md:text-2xl xl:text-3xl"}
              //   subText="6% of last month"
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <img src="/images/Map.png" alt="" />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box className="text-start flex justify-between m-2">
          <Box className="flex items-center justify-center">
            <SearchInput
              placeholder="Search by text"
              wrapperClassName="me-4"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg text-medium"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
            {/* <ButtonComponent
              btnText="Clear Filter"
              btnClass="text-medium"
              style={{
                padding: "7px 20px",
                backgroundColor: "#263238",
                "&:hover": { backgroundColor: "#263238" },
              }}
              onClick={() => {
                searchHandler();
              }}
            /> */}
            <p className="text-zinc-400 ms-4 md:!text-md xl:!text-lg">
              Total supplies : <span className="text-black">{total_data}</span>
            </p>
          </Box>
          <Box className="flex items-center">
            {/* <ButtonComponent
              btnText="Create Supply"
              startIcon={<FaPlus className="w-4 h-4" />}
              style={{
                // padding: "2px 20px",
                color: "black",
                backgroundColor: "#FFFFFF",
                border: "1px solid black",
                "&:hover": { backgroundColor: "#FFFFFF" },
                boxShadow: "none",
              }}
              onClick={() => {}}
            />
            <Divider
              orientation="vertical"
              style={{ height: "40px", margin: "0px 20px" }}
            /> */}
            <Box className="flex">
              <span className="p-1 bg-white me-3 rounded-md">
                <FiDownload
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    dispatch(
                      downloadSupplies({
                        sortby: sorting?.orderBy,
                        sorttype: sorting?.order,
                      })
                    ).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.payload])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "Supplies.csv");
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    });
                  }}
                />
              </span>
              <span className="p-1 bg-white rounded-md">
                <IoPrintOutline
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    window.print();
                  }}
                />
              </span>
              {/* <p className="text-sm">Total User</p> */}
            </Box>
            {/* <p className="text-sm">Total User</p> */}
          </Box>
        </Box>
        <Box className="mx-2">
          <TableContainerComp
            columns={columns}
            rows={Supplies}
            pagination={true}
            isLoading={isLoading}
            paginationDetails={{
              ...pagination,
              total_data,
              pageHandler: pageHandler,
            }}
            sortingDetails={sorting}
            sortingHandler={sortingHandler}
            hasLink={true}
            navigateURL={"/supply/supplywise-profile"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SupplyWiseComp;
