import { Box } from "@mui/material";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import moment from "moment";

export const generateColumns = (dateFormat) => {
  return [
    {
      id: "image",
      label: "Image",
      width: "80px",
      disableSorting: true,
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img
              src={item?.images?.length > 0 ? item?.images[0] : ""}
              alt="image"
              style={{ width: "50px", height: "40px" }}
            />
          </Box>
        );
      },
    },
    {
      id: "supplyCode",
      numeric: false,
      disablePadding: true,
      label: "Supply Code",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Supply Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
      renderCell: (item) => {
        return item?.category?.categoryName;
      },
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: true,
      label: "Rating",
      renderCell: (item) => {
        return (
          <Box className="flex items-center">
            <MdOutlineStarPurple500 className="text-yellow-500 w-5 h-5 me-2" />
            <p>{item?.avgRating?.toFixed(2)}</p>
          </Box>
        );
      },
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      label: "Status",
    },
    {
      id: "hostBusiness",
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      label: "Host Business",
      width: "270px",
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={item?.businessProfile?.profileUrl || "/images/Resturent.png"}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{item?.businessProfile?.name}</p>
              <p className="text-zinc-400 text-sm">
                {item?.businessProfile?.state}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "supplier",
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      label: "Supplier",
      width: "200px",
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={item?.supplier?.profileUrl || "/images/dummy-user.jpg"}
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${item?.supplier?.fname || " "} ${
                item?.supplier?.lname || " "
              }`}</p>
              <p className="text-zinc-400 text-sm">
                {item?.businessProfile?.name}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "orderCount",
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      label: "Total Orders",
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      label: "Total Amount",
      renderCell: (item) => {
        return `$ ${item?.totalAmount}`;
      },
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Create Date",
      renderCell: (item) => {
        return moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY");
      },
    },
    {
      id: "updatedDate",
      numeric: false,
      disablePadding: true,
      label: "Update Date",
      renderCell: (item) => {
        return moment(item?.updatedAt).format(dateFormat || "DD/MM/YYYY");
      },
    },
  ];
};

export const SupplyData = [
  {
    image: "/images/Cups.png",
    supplyCode: "S001",
    supplyName: "Supply Name",
    category: "Category",
    rating: "5.3",
    status: "Active",
    hostBusiness: {
      hostBusinessName: "Tony’s Cafe",
      hostBusinessPlace: "New York",
    },
    supplier: {
      supplierName: "Jeff Anderson",
      supplierCompany: "The Print Shop",
    },
    totalOrders: 1256,
  },
];

export const generateBusinessWiseColumns = (dateFormat) => {
  return [
    {
      id: "image",
      label: "Image",
      width: "80px",
      disableSorting: true,
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img
              src={
                item?.images?.length > 0 ? item?.images[0] : "/images/Cups.png"
              }
              alt="image"
              style={{ width: "50px", height: "40px" }}
            />
          </Box>
        );
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Business Name",
      renderCell: (item) => {
        return item?.name || " - ";
      },
    },
    // {
    //   id: "surfaceName",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "Surface Name",
    // },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
      renderCell: (item) => {
        return item?.category?.length > 0 ? (
          <Box
            className="flex items-center justify-center p-1 rounded-md"
            style={{ backgroundColor: "#FCF0C6" }}
          >
            <FoodBar className="me-2" id="food" />
            <p className="text-md">
              {item?.category?.length > 0
                ? item?.category[0]?.categoryName
                : ""}
            </p>
          </Box>
        ) : (
          " - "
        );
      },
    },
    {
      id: "createAt",
      numeric: false,
      disablePadding: true,
      label: "Create Date",
      renderCell: (item) => {
        return item?.createdAt
          ? moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY")
          : "";
      },
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: true,
      label: "Update Date",
      renderCell: (item) => {
        return item?.updatedAt
          ? moment(item?.updatedAt).format(dateFormat || "DD/MM/YYYY")
          : "";
      },
    },
    {
      id: "owner",
      numeric: false,
      disablePadding: true,
      label: "Owner",
      width: "200px",
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex items-center">
            <img
              src={item?.businessOwner?.profileUrl || "/images/dummy-user.jpg"}
              alt=""
              className="rounded-full me-2"
              style={{ width: "35px", height: "35px" }}
            />
            <p className="text-md">{`${item?.businessOwner?.fname || ""} ${
              item?.businessOwner?.lname || ""
            }`}</p>
          </Box>
        );
      },
    },
  ];
};

export const BusinessWiseData = [
  {
    image: "/images/Resturent.png",
    supplyCode: "BU19237",
    supplyName: "Tony’s Cafe",
    category: "Cafe",
    createDate: "2023-08-02 12:03:45",
    updateDate: "2023-08-22 20:36:12",
    owner: {
      ownerName: "Ben Parker",
    },
  },
];

const orderType = (orderDetails) => {
  if (orderDetails?.isCompletedBySharer) {
    return "Completed";
  } else if (orderDetails?.isSharerRejected) {
    return "Cancelled";
  } else if (
    orderDetails?.isSharerAccepted &&
    !orderDetails?.isStartedBySupplier
  ) {
    return "Upcoming";
  } else if (
    orderDetails?.isSharerAccepted &&
    orderDetails?.isStartedBySupplier &&
    !orderDetails?.isCompletedBySharer
  ) {
    return "Active";
  } else {
    return null;
  }
};

export const orderDetailsColumns = (dateFormat) => {
  return [
    {
      label: "Order No.",
      field: "orderId",
      disableSorting: true,
    },
    {
      label: "Order Date",
      field: "createdAt",
      renderCell: (item) => {
        return moment(item?.createdAt).format(dateFormat || "DD/MM/YYYY");
      },
    },
    {
      label: "Order Type",
      field: "orderType",
      disableSorting: true,
      renderCell: (item) => {
        const type = orderType(item);
        return type ? (
          <span
            className="px-3 py-2 rounded-md"
            style={{ backgroundColor: "#FCF0C6" }}
          >
            {type}
          </span>
        ) : (
          ""
        );
      },
    },
    {
      label: "Supply",
      field: "supply",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={item?.supplyUser?.profileUrl || "/images/Cups.png"}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${item?.supplyUser?.fname || ""} ${
                item?.supplyUser?.lname || ""
              }`}</p>
              <p className="text-zinc-400 text-sm"></p>
            </Box>
          </Box>
        );
      },
    },
    {
      label: "Seeker",
      field: "seeker",
      disableSorting: true,
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex">
            <img
              src={item?.user?.profileUrl || "/images/dummy-user.jpg"}
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${item?.user?.fname} ${item?.user?.lname}`}</p>
              <p className="text-zinc-400 text-sm">
                {item?.seeker?.seekerCompany || ""}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      label: "Prep Period",
      field: "prepPeriod",
      renderCell: (item) => {
        return (
          <Box>
            <p>
              From:{" "}
              <span>
                {item?.fromDate &&
                  moment(item?.fromDate).format(dateFormat || "DD/MM/YYYY")}
              </span>
            </p>
            <p>
              To:{" "}
              <span>
                {item?.toDate &&
                  moment(item?.toDate).format(dateFormat || "DD/MM/YYYY")}
              </span>
            </p>
          </Box>
        );
      },
    },
    {
      label: "Total",
      field: "orderTotal",
      renderCell: (item) => {
        return `$${item?.paymentDetails?.orderTotal}`;
      },
    },
    {
      label: "Sharer",
      field: "sharer",
      disableSorting: true,
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src={item?.sharerUser?.profileUrl || "/images/dummy-user.jpg"}
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{`${item?.sharerUser?.fname} ${item?.sharerUser?.lname}`}</p>
              <p className="text-zinc-400 text-sm">
                {item?.supplier?.supplierCompany || ""}
              </p>
            </Box>
          </Box>
        );
      },
    },
    // {
    //   label: "Action",
    //   renderCell: (item) => (
    //     <div>
    //       <ButtonComponent
    //         btnText="Edit"
    //         btnClass={"btn-warning"}
    //         onClick={() => {}}
    //       />
    //       <ButtonComponent
    //         btnText="Delete"
    //         btnClass={"btn-danger"}
    //         onClick={() => {}}
    //       />
    //     </div>
    //   ),
    // },
  ];
};

export const orderDetailsData = [
  {
    id: 1,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    orderType: "Supply",
    supply: {
      supplyName: "Paper Cups",
      supplyCompany: "K Mart",
    },
    seeker: {
      seekerName: "Jeff Anderson",
      seekerCompany: "The Print Shop",
    },
    prePeriod: {
      fromDate: "Oct 28, 2023",
      toDate: "Oct 28, 2023",
    },
    total: "100",
    sharer: {
      sharerName: "Ben Parker",
      sharerCompany: "Coffee Shop",
    },
  },
  {
    id: 2,
    orderNo: 10224,
    orderDate: "Oct 28, 2023",
    orderType: "Supply",
    supply: {
      supplyName: "Paper Cups",
      supplyCompany: "K Mart",
    },
    seeker: {
      seekerName: "Jeff Anderson",
      seekerCompany: "The Print Shop",
    },
    prePeriod: {
      fromDate: "Oct 28, 2023",
      toDate: "Oct 28, 2023",
    },
    total: "1000",
    sharer: {
      sharerName: "Ben Parker",
      sharerCompany: "Coffee Shop",
    },
  },
];
