import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { FaCalendarAlt } from "react-icons/fa";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative !important",
    top: "0px",
  },

  [`& .${stepConnectorClasses.line}`]: {
    width: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#000",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FCF0C6",
}));

export default function StepperComp({ steps }) {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={1}
        connector={<ColorlibConnector />}
        orientation="vertical"
        sx={{
          "& .MuiStepLabel-alternativeLabel": {
            flexDirection: "row !important",
            margin: "0 4px !important",
          },
          "& .MuiStepLabel-root": {
            padding: "4px 0px !important",
          },
        }}
      >
        {steps?.map((step) => (
          <Step key={step.id}>
            <StepLabel
              StepIconComponent={(index) => {
                return <ColorlibStepIconRoot>{step.icon}</ColorlibStepIconRoot>;
              }}
            >
              <h1 className="text-sm">{step.label}</h1>
              <p className="text-sm text-zinc-400 text-start">
                {step.subLabel}
              </p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
