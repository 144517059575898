import React, { useEffect, useState } from "react";
import ModalComp from "../../../common/ModalComp";
import { Box, Button, Grid } from "@mui/material";
import { Categories } from "../helper";
import TextInputField from "../../../common/Input/TextInput";
import ButtonComponent from "../../../common/Button";
import ReactQuillComp from "../../../common/Input/ReactQuill";
import { useDispatch } from "react-redux";
import {
  addTermsAndConditions,
  editCancellationPolicy,
  getCancellationPolicy,
  getTermsAndConditionById,
  getTermsAndConditions,
} from "../../../../store/settings/rulesReporting";
import { Form, Formik, useFormik } from "formik";
import { toast } from "react-toastify";

const initialValue = {
  description: "",
};

const CreateCancellationTermsModal = ({
  open,
  data,
  toggleHandler,
  title,
  width,
}) => {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState(initialValue);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: yup.object().shape({
    //   email: yup.string().email().required("Email is required"),
    //   password: yup.string().required("Password is required"),
    // }),
    onSubmit: (values) => {
      const title =
        data?.title === "Surface Cancellation Policy"
          ? "surfaceCancellationPolicy"
          : "supplyCancellationPolicy";
      let payload = {
        [title]: values?.description,
      };
      dispatch(editCancellationPolicy(payload)).then((data) => {
        if (data?.payload?.success) {
          toast.success(`Cancellation Policy Updated Successfully!`);
          toggleHandler();
          formik.resetForm();
          dispatch(getCancellationPolicy());
        }
      });
    },
  });

  useEffect(() => {
    if (data) {
      setInitialValues({ description: data?.description });
    }
  }, [data]);

  return (
    <div>
      <ModalComp
        open={open}
        toggleHandler={() => {
          formik.resetForm();
          toggleHandler();
        }}
        title={title}
        width={width}
      >
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={{ padding: "1rem 5rem" }}>
              <Box className="flex items-center md:text-md xl:text-lg md:mb-2 xl:mb-3">
                <p className="md:me-2 xl:me-3 font-semibold">{data?.title}</p>
                {/* <TextInputField
                  inputClassName="rounded-xl"
                  name="termName"
                  formik={formik}
                  disabled={!!id}
                  sx={{
                    backgroundColor: "#F7F8FA",
                    fontSize: "15px",
                  }}
                /> */}
              </Box>
              <ReactQuillComp name="description" formik={formik} />
            </Box>
            <Box className="flex items-center justify-center">
              <ButtonComponent
                btnText="Cancel"
                //   startIcon={<RiMessage2Fill />}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  borderRadius: "10px",
                  color: "black",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                  boxShadow: "none",
                  width: "125px",
                }}
                onClick={() => {
                  formik.resetForm();
                  toggleHandler();
                }}
              />
              <ButtonComponent
                btnText="Save"
                btnType="submit"
                btnClass="!px-7"
                //   startIcon={<RiMessage2Fill />}
                style={{
                  backgroundColor: "#263238",
                  border: "1px solid black",
                  borderRadius: "10px",
                  width: "125px",
                  "&:hover": { backgroundColor: "#263238" },
                }}
              />
            </Box>
          </Form>
        </Formik>
      </ModalComp>
    </div>
  );
};

export default CreateCancellationTermsModal;
