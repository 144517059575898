import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function MenuComp({
  selectedItem,
  btn,
  menuList,
  btnBackgroundColor = "#F7F8FA",
  menuBackgroundColor = "#F7F8FA",
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event, "event");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menu-comp">
      {/* <div className="flex justify-end" onClick={handleClick}> */}
      <div
        className="p-1 rounded-full cursor-pointer"
        style={{ backgroundColor: btnBackgroundColor }}
        onClick={handleClick}
      >
        {btn}
      </div>
      {/* </div> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            backgroundColor: menuBackgroundColor,
          },
        }}
      >
        {menuList.length > 0 &&
          menuList.map((item, index) => (
            <MenuItem
              className="menu-item text-medium"
              onClick={() => item.onClick(selectedItem)}
              key={index}
            >
              {item.lable}
            </MenuItem>
          ))}
        {/* <MenuItem sx={{ padding: "2px 20px" }} onClick={handleClose}>
          Disable User
        </MenuItem>
        <MenuItem sx={{ padding: "2px 20px" }} onClick={handleClose}>
          Reset User
        </MenuItem> */}
      </Menu>
    </div>
  );
}
