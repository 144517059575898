import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import {
  Card,
  Divider,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExternalLinkIcon } from "../../../assets/images/ExternalLinkIcon.svg";
import Loading from "../Loading";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import SearchInput from "../../common/Input/TextInput";
import { CiSearch } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import ButtonComponent from "../Button";

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
// function stableSort(array = [], comparator) {
//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis?.map((el) => el[0]);
// }
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    hasLink,
  } = props;
  console.log(order, orderBy, "order order");
  const [filterCol, setFilterCol] = useState("");
  const [searchState, setSearchState] = useState("");
  const searchHandler = (e) => {};
  // useEffect(() => {
  //   window.onclick = (event) => {
  //     console.log(event.target?.id);
  //     if (!event.target?.id || event.target?.id !== "downarrow") {
  //       // console.log(event.target, "event.target");
  //       setFilterCol("");
  //     }
  //   };
  // }, []);
  return (
    <TableHead>
      <TableRow>
        {hasLink && (
          <TableCell
            padding="normal"
            sx={{ borderBottom: "2px solid #D2D2D2" }}
          ></TableCell>
        )}
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="font-semibold"
            sx={{
              minWidth: `${headCell?.width || "150px"}`,
              // width: "100%",
              // maxWidth: "230px",
              textWrap: "nowrap",
              borderBottom: "2px solid #D2D2D2",
              fontFamily: "Inter, sans-serif",
              // fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span">
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}

            <Box className="flex items-center">
              <p className="me-2">{headCell.label}</p>
              {/* {!headCell?.disableSorting &&
                (orderBy === headCell?.id ? (
                  order === "asc" ? (
                    <span
                      onClick={() => {
                        setFilterCol(headCell?.id);
                        // onRequestSort(headCell?.id);
                      }}
                    >
                      <FaCaretUp className="cursor-pointer" />
                    </span>
                  ) : (
                    <span onClick={() => onRequestSort(headCell?.id)}>
                      <FaCaretDown className="cursor-pointer" />
                    </span>
                  )
                ) : (
                  <span
                    onClick={() => {
                      setFilterCol(headCell?.id);
                      // onRequestSort(headCell?.id);
                    }}
                  >
                    <FaCaretUp className="cursor-pointer" />
                  </span>
                ))} */}
              {!headCell?.disableSorting &&
                (orderBy === headCell?.id && order === "asc" ? (
                  <span
                    onClick={() => {
                      setFilterCol("");
                      onRequestSort(headCell?.id);
                    }}
                  >
                    <FaCaretUp className="cursor-pointer" id="uparrow" />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setFilterCol(headCell?.id);
                      onRequestSort(headCell?.id);
                    }}
                  >
                    <FaCaretDown className="cursor-pointer" id="downarrow" />
                  </span>
                ))}
              {/* <Box
                id="dropdown"
                className={`absolute top-2/4 mt-2 z-10 w-56 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 ${
                  filterCol === headCell?.id ? "" : "hidden"
                } z-50`}
              >
                <ul
                  className="p-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li className="mb-2">
                    <Box>
                      <Box
                        className="flex items-center mb-2 cursor-pointer"
                        onClick={() => {
                          onRequestSort(headCell?.id, "asc");
                        }}
                      >
                        <FaArrowDownLong className="me-3" />
                        <span>Sort A to Z</span>
                      </Box>
                      <Box
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                          onRequestSort(headCell?.id, "desc");
                        }}
                      >
                        <FaArrowUpLong className="me-3" />
                        <span>Sort Z to A</span>
                      </Box>
                    </Box>
                  </li>
                  <Divider />
                  <li className="mb-2">
                    <Box>
                      <SearchInput
                        placeholder="Search by text"
                        isShowError={false}
                        value={searchState}
                        onChange={searchHandler}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton>
                              <CiSearch />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputClassName="rounded-lg"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            margin: "10px 0px 10px 0px",
                            borderRadius: "10px",
                            border: 0,
                          },
                        }}
                      />
                      <Card className="p-2">
                        <p className="mb-3 flex items-center">
                          <Checkbox
                            className="me-3"
                            style={{ padding: "0px" }}
                          />
                          (Select All)
                        </p>
                        <p className="mb-3 flex items-center">
                          <Checkbox
                            className="me-3"
                            style={{ padding: "0px" }}
                          />
                          Neel Patel
                        </p>
                        <p className="mb-3 flex items-center">
                          <Checkbox
                            className="me-3"
                            style={{ padding: "0px" }}
                          />
                          Jerin George
                        </p>
                      </Card>
                    </Box>
                  </li>
                  <Divider />
                  <li>
                    <Box
                      className="p-2 cursor-pointer text-md font-semibold flex items-center"
                      onClick={() => {}}
                    >
                      <RxCross1 className="w-5 h-5 me-2 font-bold text-red-500" />
                      Clear Filter
                    </Box>
                    <Box className="flex justify-between">
                      <ButtonComponent
                        btnText="CANCEL"
                        style={{
                          backgroundColor: "#F7F8FA",
                          border: "0px",
                          borderRadius: "10px",
                          color: "black",
                          "&:hover": { backgroundColor: "#F7F8FA" },
                          boxShadow: "none",
                          width: "9rem",
                        }}
                        onClick={() => {}}
                      />
                      <ButtonComponent
                        btnText="OK"
                        btnType="submit"
                        // btnClass="!px-7"
                        onClick={() => {}}
                        //   startIcon={<RiMessage2Fill />}
                        style={{
                          backgroundColor: "#263238",
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "9rem",
                          "&:hover": { backgroundColor: "#263238" },
                        }}
                      />
                    </Box>
                  </li>
                </ul>
              </Box> */}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableContainerComp = ({
  rows,
  openedRow,
  rowClickHandler = () => {},
  columns,
  pagination,
  isLoading = false,
  paginationDetails,
  sortingDetails,
  sortingHandler,
  hasLink,
  navigateURL,
  expendRowFn,
}) => {
  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const usePagination = () => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(15);

    const next = useCallback(() => setPage((prev) => prev + 1), []);
    const previous = useCallback(() => setPage((prev) => prev - 1), []);
    const first = useCallback(() => setPage(1), []);
    const last = useCallback(() => setPage(totalPages), [totalPages]);
    const gotoPage = useCallback((page) => setPage(page), []);

    return {
      page,
      totalPages,
      setTotalPages,
      next,
      previous,
      first,
      last,
      gotoPage,
    };
  };

  const paginate = usePagination();
  const { page, setTotalPages } = paginate;

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  console.log(sortingDetails, "sortingDetails");
  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  return (
    <Box sx={{ width: "100%" }}>
      {!isLoading && (
        <Paper
          sx={{
            width: "calc(100vw - 18rem - 8px)",
            // overflowX: "scroll",
            marginBottom: "10px",
          }}
        >
          <TableContainer sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
            <Table aria-labelledby="tableTitle" stickyHeader>
              <EnhancedTableHead
                numSelected={selected.length}
                order={sortingDetails?.order || ""}
                orderBy={sortingDetails?.orderBy || ""}
                onRequestSort={sortingHandler}
                rowCount={rows?.length || 0}
                columns={columns}
                hasLink={hasLink}
                navigateURL={navigateURL}
              />
              <TableBody>
                {rows?.length > 0 ? (
                  rows?.map((row, index) => {
                    console.log(row, "rowwwwwwwwwwww");
                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => {
                            rowClickHandler(row);
                          }}
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{
                            cursor: "pointer",
                            borderBottom: "none",
                          }}
                        >
                          {hasLink && (
                            <TableCell
                              padding="checkbox"
                              sx={{
                                borderBottom: "none",
                                minWidth: "60px",
                                paddingLeft: "10px",
                              }}
                              onClick={(event) =>
                                navigate(navigateURL, { state: row })
                              }
                              id="link"
                            >
                              <ExternalLinkIcon className="md:w-5 md:h-5 xl:w-6 xl:h-6" />
                            </TableCell>
                          )}
                          {columns.map((col) => {
                            return (
                              <TableCell
                                align="left"
                                sx={{
                                  borderBottom: "none",
                                  fontSize: "15px",
                                  width: `${col.width || "150px"}`,
                                  // textWrap: "nowrap",
                                }}
                                id={`${col.id}${index}`}
                              >
                                {col?.renderCell
                                  ? col.renderCell(row, index)
                                  : row[col.id]}
                              </TableCell>
                            );
                          })}
                        </TableRow>

                        {expendRowFn && openedRow === row._id && (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              {/* Render your additional details/content here */}
                              {expendRowFn(row)}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })
                ) : (
                  <TableRow
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      colSpan={columns.length}
                      className="!text-center !border-none"
                      // sx={{
                      //   borderBottom: "none",
                      //   display: "flex",
                      //   justifyContent: "center",
                      // }}
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
                {/* {rows?.length === 0 && <p>No Data Found</p>} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper>
      )}
      {!isLoading && pagination && (
        <div className="flex justify-between mb-3">
          <Box className="flex items-center">
            <span className="text-sm text-zinc-400">View</span>
            <select
              className="h-6 mx-2 text-sm border-0"
              value={paginationDetails?.pageSize || 10}
              onChange={(event) =>
                paginationDetails.pageHandler(
                  paginationDetails?.page || 1,
                  +event.target.value
                )
              }
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span className="text-sm text-zinc-400 me-1">Entries per page</span>
          </Box>
          <Pagination
            variant="text"
            // showFirstButton
            // showLastButton
            hideNextButton={
              paginationDetails?.page ===
              Math.ceil(
                paginationDetails?.total_data / paginationDetails?.pageSize || 1
              )
            }
            hidePrevButton={paginationDetails?.page === 1}
            color="primary"
            size="small"
            count={Math.ceil(
              paginationDetails?.total_data / paginationDetails?.pageSize || 1
            )}
            // rowsPerPage={10}
            // siblingCount={0}
            // boundaryCount={0}
            page={paginationDetails?.page || 1}
            onChange={(event, page) => {
              paginationDetails?.pageHandler(
                page,
                paginationDetails?.pageSize || 10
              );
            }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#F9DD7B !important",
              },
            }}
          />
        </div>
      )}
      {isLoading && (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Loading isLoading={isLoading} />
        </Box>
      )}
    </Box>
  );
};

export default TableContainerComp;
