import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DELETE, GET, POST } from "../../services/methods";

export const getTermsAndConditions = createAsyncThunk(
  "rulesReporting/getTermsAndCondition",
  async () => {
    const getData = async () => {
      return GET("/termsCondition/get").then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addTermsAndConditions = createAsyncThunk(
  "rulesReporting/addTermsAndConditions",
  async (payload) => {
    const postData = async () => {
      return POST("/termsCondition/add", payload).then((res) => {
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

export const getTermsAndConditionById = createAsyncThunk(
  "rulesReporting/getTermsAndConditionById",
  async (params) => {
    const getData = async () => {
      return GET("termsCondition/getById", { params }).then((res) => {
        return res;
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

export const getCancellationPolicy = createAsyncThunk(
  "rulesReporting/getCancellationPolicy",
  async () => {
    const getData = async () => {
      return GET("/cancellationPolicy/get").then((res) => {
        return res;
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

export const editCancellationPolicy = createAsyncThunk(
  "rulesReporting/editCancellationPolicy",
  async (payload) => {
    const postData = async () => {
      return POST("/cancellationPolicy/edit", payload).then((res) => {
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
const rulesReportingSlice = createSlice({
  name: "rulesReporting",
  initialState: {
    isLoading: false,
    termsAndConditions: [],
    cancelltionPolicies: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getTermsAndConditions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTermsAndConditions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.termsAndConditions = action.payload || [];
    });
    builder.addCase(getTermsAndConditions.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getCancellationPolicy.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCancellationPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cancelltionPolicies = action.payload?.data || [];
    });
    builder.addCase(getCancellationPolicy.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default rulesReportingSlice.reducer;
