import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getSupplies = createAsyncThunk(
  "account/getSupplies",
  async (params) => {
    const getData = async () => {
      return GET("/admin/supply/list", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const downloadSupplies = createAsyncThunk(
  "account/downloadSupplies",
  async (params) => {
    const getData = async () => {
      return GET("/admin/supply/listCSV", { params }).then((res) => {
        return res;
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getSupplyDetails = createAsyncThunk(
  "account/getSupplyDetails",
  async (params) => {
    const getData = async () => {
      return GET(`/admin/supply/details/${params.id}`).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
const supplySlice = createSlice({
  name: "suppky",
  initialState: {
    isLoading: false,
    supplies: {},
    supplyDetails: {},
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSupplies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplies = action.payload;
    });
    builder.addCase(getSupplies.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(getSupplyDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSupplyDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplyDetails = action.payload;
    });
    builder.addCase(getSupplyDetails.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default supplySlice.reducer;
