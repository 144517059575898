import { Box } from "@mui/material";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { ReactComponent as FoodBar } from "../../assets/images/FoodBar.svg";
import moment from "moment";

export const generateColumns = () => {
  return [
    {
      id: "image",
      label: "Image",
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img src={item?.image} alt="image" />
          </Box>
        );
      },
    },
    {
      id: "supplyCode",
      numeric: false,
      disablePadding: true,
      label: "Supply Code",
    },
    {
      id: "supplyName",
      numeric: false,
      disablePadding: true,
      label: "Supply Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: true,
      label: "Rating",
      renderCell: (item) => {
        return (
          <Box className="flex items-center">
            <MdOutlineStarPurple500 className="text-yellow-500 w-5 h-5 me-2" />
            <p>{item?.rating}</p>
          </Box>
        );
      },
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: "hostBusiness",
      numeric: false,
      disablePadding: true,
      label: "Host Business",
      renderCell: (item) => {
        return (
          <Box className="flex">
            <img
              src="/images/Resturent.png"
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{item.hostBusiness.hostBusinessName}</p>
              <p className="text-zinc-400 text-sm">
                {item.hostBusiness.hostBusinessPlace}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "sharer",
      numeric: false,
      disablePadding: true,
      label: "Sharer",
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex">
            <img
              src="/images/dummy-user.jpg"
              alt=""
              className="rounded-full"
              style={{ width: "35px", height: "35px" }}
            />
            <Box className="ms-1">
              <p className="text-md">{item.supplier.supplierName}</p>
              <p className="text-zinc-400 text-sm">
                {item.supplier.supplierCompany}
              </p>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "totalOrders",
      numeric: false,
      disablePadding: true,
      label: "Total Orders",
      renderCell: (item) => {
        return `$${item?.totalOrders}`;
      },
    },
  ];
};

export const SupplyData = [
  {
    image: "/images/Cups.png",
    supplyCode: "S001",
    supplyName: "Supply Name",
    category: "Category",
    rating: "5.3",
    status: "Active",
    hostBusiness: {
      hostBusinessName: "Tony’s Cafe",
      hostBusinessPlace: "New York",
    },
    supplier: {
      supplierName: "Jeff Anderson",
      supplierCompany: "The Print Shop",
    },
    totalOrders: 1256,
  },
];

export const generateBusinessWiseColumns = () => {
  return [
    {
      id: "image",
      label: "Image",
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <Box>
            <img src={item?.image} alt="image" />
          </Box>
        );
      },
    },
    {
      id: "supplyCode",
      numeric: false,
      disablePadding: true,
      label: "Supply Code",
    },
    {
      id: "supplyName",
      numeric: false,
      disablePadding: true,
      label: "Supply Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
      renderCell: (item) => {
        return (
          <Box
            className="flex p-1 rounded-md"
            style={{ backgroundColor: "#FCF0C6" }}
          >
            <FoodBar className="me-2" id="food" />
            <p>{item?.category}</p>
          </Box>
        );
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: true,
      label: "Create Date",
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: true,
      label: "Update Date",
    },
    {
      id: "owner",
      numeric: false,
      disablePadding: true,
      label: "Owner",
      renderCell: (item) => {
        console.log(item, "in renderCell");
        return (
          <Box className="flex items-center">
            <img
              src="/images/dummy-user.jpg"
              alt=""
              className="rounded-full me-2"
              style={{ width: "35px", height: "35px" }}
            />
            <p className="text-md">{item.owner.ownerName}</p>
          </Box>
        );
      },
    },
  ];
};

export const BusinessWiseData = [
  {
    image: "/images/Resturent.png",
    supplyCode: "BU19237",
    supplyName: "Tony’s Cafe",
    category: "Cafe",
    createDate: "2023-08-02 12:03:45",
    updateDate: "2023-08-22 20:36:12",
    owner: {
      ownerName: "Ben Parker",
    },
  },
];

export const orderDetailsColumns = () => {
  return [
    {
      title: "Order No.",
      field: "orderNo",
    },
    { title: "Order Date", field: "orderDate" },
    {
      title: "Surface",
      field: "surface",
    },
    {
      title: "Seeker",
      field: "seeker",
    },
    {
      title: "Sharer",
      field: "sharer",
    },
    {
      title: "Supplier",
      field: "supplier",
    },
    {
      title: "Prep Period",
      field: "prepPeriod",
    },
    {
      title: "Total",
      field: "total",
    },
    // {
    //   title: "Action",
    //   renderCell: (item) => (
    //     <div>
    //       <ButtonComponent
    //         btnText="Edit"
    //         btnClass={"btn-warning"}
    //         onClick={() => {}}
    //       />
    //       <ButtonComponent
    //         btnText="Delete"
    //         btnClass={"btn-danger"}
    //         onClick={() => {}}
    //       />
    //     </div>
    //   ),
    // },
  ];
};

export const orderDetailsData = [
  {
    id: 1,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    surface: "Paper Cups",
    seeker: "Ben Parker",
    sharer: "Maria Joyce",
    supplier: "Tony  Stark",
    prepPeriod: "4 Sept - 8 Sept, 2023",
    total: "$120.05",
  },
  {
    id: 2,
    orderNo: 10223,
    orderDate: "Oct 28, 2023",
    surface: "Paper Cups",
    seeker: "Ben Parker",
    sharer: "Maria Joyce",
    supplier: "Tony  Stark",
    prepPeriod: "4 Sept - 8 Sept, 2023",
    total: "$120.05",
  },
];

export const fontAndborder = {
  Pending: {
    color: "#85ABBD",
    background: "#F3F8FB",
  },
  Resolved: {
    color: "#6DC476",
    background: "#F1FFF3",
  },
  "In Progress": {
    color: "#FFAE00",
    background: "#FFFAEF",
  },
};

export const helpSupportColumn = () => {
  return [
    {
      label: "Ticket #",
      id: "ticketNumber",
      renderCell: (item) => {
        return <span>{item?.ticketNumber}</span>;
      },
    },
    {
      label: "Date",
      id: "createdAt",
      renderCell: (item) => {
        console.log(item, "item");
        return <span>{moment(item?.createdAt).format("DD MMM, YYYY")}</span>;
      },
    },
    {
      label: "Topic",
      id: "topic",
    },
    {
      label: "Status",
      id: "status",
      width: "100px",
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <span
            className="px-3 py-1 font-semibold rounded-lg flex justify-center items-center"
            style={{
              border: `2px solid ${fontAndborder[item?.status]?.color}`,
              color: fontAndborder[item?.status]?.color,
              backgroundColor: fontAndborder[item?.status]?.background,
            }}
          >
            {item?.status}
          </span>
        );
      },
    },
    {
      label: "User",
      id: "createdBy.fname",
      // disableSorting: true,
      renderCell: (item) => {
        console.log(item, "item");
        return (
          <span>{`${item.createdBy?.fname} ${item.createdBy?.lname}`}</span>
        );
      },
    },
    {
      label: "Last Updated",
      id: "updatedAt",
      renderCell: (item) => {
        console.log(item, "item");
        return <span>{moment(item?.updatedAt).format("DD MMM, YYYY")}</span>;
      },
    },
  ];
};

export const helpSupportData = [
  {
    id: 1,
    ticket: 10223,
    date: "Oct 28, 2023",
    topic: "Paper Cups",
    status: "Pending",
    user: "BenPark",
    lastupdated: "8 Sept, 2023",
  },
  {
    id: 2,
    ticket: 10223,
    date: "Oct 28, 2023",
    topic: "Paper Cups",
    status: "Pending",
    user: "BenPark",
    lastupdated: "8 Sept, 2023",
  },
];
