import * as React from "react";
import { Box, Switch } from "@mui/material";

export default function SwitchComp({ checked, changeHandler }) {
  return (
    <Switch
      checked={checked}
      onChange={changeHandler}
      sx={{
        "& .MuiSwitch-thumb": { backgroundColor: "#263238" },
        "& .MuiButtonBase-root .MuiSwitch-switchBase .Mui-checked": {
          color: "#85ABBD",
        },
      }}
    />
  );
}
