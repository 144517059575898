import styled from "@emotion/styled";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import React from "react";

const LinearProgressBar = ({ backgroundColor, color, value }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor || "white",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color || "blue",
    },
  }));
  return (
    <Box>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
};

export default LinearProgressBar;
