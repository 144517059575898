import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import TableContainerComp from "../common/Table/TableContainer";
import {
  AccountData,
  generateColumns,
  orderDetailsColumns,
  orderDetailsData,
} from "./helper";
import CustomTableContainer from "../common/Table/CustomTableContainer";
import ButtonComponent from "../common/Button";
import SearchInput from "../common/Input/TextInput";
import { IoChevronBackOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getReservations,
  getReservationsCount,
} from "../../store/reservation/reservation.slice";
import { useDispatch, useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";

const SurfaceOrdersDetails = () => {
  const dispatch = useDispatch();
  const {
    reservations: { data: Reservation, total_data },
    reservationCounts,
    isLoading,
  } = useSelector((state) => state.reservation);
  const { generalInfoDetails } = useSelector(
    (state) => state.settings.generalInfo
  );
  const columns = orderDetailsColumns(generalInfoDetails?.dateFormat);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("allOrders");
  const [searchState, setSearchState] = useState("");
  const [sorting, setSorting] = useState({
    sortBy: "orderNo",
    sortType: "asc",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const sortingHandler = (orderByCol) => {
    const isAsc = sorting?.sortBy === orderByCol && sorting?.sortType === "asc";
    setSorting({ sortType: isAsc ? "desc" : "asc", sortBy: orderByCol });
    getAllReservations({ order: isAsc ? "desc" : "asc", orderBy: orderByCol });
  };

  const getAllReservations = (params) => {
    // setSearchState(params?.search || "");
    const payload = {
      limit: params?.pageSize || pagination?.pageSize,
      page: params?.page || pagination?.page,
      sortby: params?.orderBy || sorting?.sortBy,
      sorttype: params?.order || sorting?.sortType,
      orderType: params?.orderType || selectedTab,
      surfaceId: params?.surfaceId || location?.state?._id,
      q: params?.q || "",
    };
    dispatch(getReservations(payload));
  };

  const searchHandler = (e) => {
    console.log(e, "value");
    getAllReservations({ page: 1, q: e?.target?.value || "" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    setSearchState(e?.target?.value || "");
  };

  const pageHandler = (page, pageSize) => {
    console.log(page, pageSize, "details");
    setPagination({ page: page, pageSize: pageSize });
    getAllReservations({ page: page, pageSize: pageSize });
  };

  useEffect(() => {
    getAllReservations();
    dispatch(getReservationsCount({ surfaceId: location?.state?._id }));
  }, []);

  console.log(reservationCounts, "reservationCounts");
  return (
    <Box>
      <BreadCrumb
        title="Orders"
        parents={["Surface Management", `${location.state?.name}`]}
        parentsURL={["/surface", "/surface/surfacewise-profile"]}
        parentsData={[{}, location.state]}
      />
      <Box className="mx-2">
        <Card className="p-3">
          <Box className="flex justify-between items-center mb-4">
            <h1 className="flex items-center text-start mb-4 font-semibold md:text-lg xl:text-xl">
              <span className="me-2">
                {" "}
                <IoChevronBackOutline
                  className="w-6 h-6 cursor-pointer"
                  onClick={() =>
                    navigate("/surface/surfacewise-profile", {
                      state: location.state,
                    })
                  }
                />{" "}
              </span>
              Order List
            </h1>
            <SearchInput
              placeholder="Search by text"
              isShowError={false}
              value={searchState}
              onChange={searchHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
              inputClassName="rounded-lg"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  border: 0,
                },
              }}
            />
          </Box>
          {/* <Grid container className="mb-3">
            <Grid item xs={4}>
              <h2
                className="py-3 font-semibold"
                style={{
                  backgroundColor: "#F7F8FA",
                  borderBottom: "3px solid #85ABBD",
                  borderRadius: "10px 0px 0px 10px",
                }}
              >
                Seeker
              </h2>
            </Grid>
            <Grid item xs={4}>
              <h2
                className="py-3 font-semibold"
                style={{
                  backgroundColor: "#F7F8FA",
                }}
              >
                Sharer
              </h2>
            </Grid>
            <Grid item xs={4}>
              <h2
                className="py-3 font-semibold"
                style={{
                  backgroundColor: "#F7F8FA",
                  borderRadius: "0px 10px 10px 0px",
                }}
              >
                Supplier
              </h2>
            </Grid>
          </Grid> */}
          <Box
            className="p-3 rounded-md"
            style={{ backgroundColor: "#F7F8FA" }}
          >
            <Grid container>
              <Grid
                item
                xs={3}
                xl={3}
                className="cursor-pointer"
                style={{
                  borderBottom:
                    selectedTab === "allOrders" ? "2px solid #F6CA30" : "",
                }}
                onClick={() => {
                  setSelectedTab("allOrders");
                  getAllReservations({ orderType: "allOrders" });
                }}
              >
                <h3
                  className="py-1 font-semibold text-medium mb-1"
                  style={{
                    color: selectedTab === "allOrders" ? "#F6CA30" : "#000000",
                  }}
                >
                  All Orders
                  <span
                    className="py-1 px-2 rounded-full ms-1"
                    style={{
                      backgroundColor:
                        selectedTab === "allOrders" ? "#F6CA30" : "#F7F8FA",
                      color: selectedTab === "allOrders" ? "white" : "black",
                      border:
                        selectedTab === "allOrders" ? "" : "1px solid #000000",
                    }}
                  >
                    {reservationCounts?.allOrders || 0}
                  </span>
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                className="cursor-pointer"
                style={{
                  borderBottom:
                    selectedTab === "active" ? "2px solid #F6CA30" : "",
                }}
                onClick={() => {
                  setSelectedTab("active");
                  getAllReservations({ orderType: "active" });
                }}
              >
                <h3
                  className="py-1 font-semibold text-medium mb-1"
                  style={{
                    color: selectedTab === "active" ? "#F6CA30" : "#000000",
                  }}
                >
                  Active
                  <span
                    className="py-1 px-2 rounded-full ms-1"
                    style={{
                      backgroundColor:
                        selectedTab === "active" ? "#F6CA30" : "#F7F8FA",
                      color: selectedTab === "active" ? "white" : "black",
                      border:
                        selectedTab === "active" ? "" : "1px solid #000000",
                    }}
                  >
                    {reservationCounts?.active || 0}
                  </span>
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                xl={2}
                className="cursor-pointer"
                style={{
                  borderBottom:
                    selectedTab === "completed" ? "2px solid #F6CA30" : "",
                }}
                onClick={() => {
                  setSelectedTab("completed");
                  getAllReservations({ orderType: "completed" });
                }}
              >
                <h3
                  className="py-1 font-semibold text-medium mb-1"
                  style={{
                    color: selectedTab === "completed" ? "#F6CA30" : "#000000",
                  }}
                >
                  Completed
                  <span
                    className="py-1 px-2 rounded-full ms-1"
                    style={{
                      backgroundColor:
                        selectedTab === "completed" ? "#F6CA30" : "#F7F8FA",
                      color: selectedTab === "completed" ? "white" : "black",
                      border:
                        selectedTab === "completed" ? "" : "1px solid #000000",
                    }}
                  >
                    {reservationCounts?.completed || 0}
                  </span>
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                className="cursor-pointer"
                style={{
                  borderBottom:
                    selectedTab === "upcoming" ? "2px solid #F6CA30" : "",
                }}
                onClick={() => {
                  setSelectedTab("upcoming");
                  getAllReservations({ orderType: "upcoming" });
                }}
              >
                <h3
                  className="py-1 font-semibold text-medium mb-1"
                  style={{
                    color: selectedTab === "upcoming" ? "#F6CA30" : "#000000",
                  }}
                >
                  Upcoming
                  <span
                    className="py-1 px-2 rounded-full ms-1"
                    style={{
                      backgroundColor:
                        selectedTab === "upcoming" ? "#F6CA30" : "#F7F8FA",
                      color: selectedTab === "upcoming" ? "white" : "black",
                      border:
                        selectedTab === "upcoming" ? "" : "1px solid #000000",
                    }}
                  >
                    {reservationCounts?.upcoming || 0}
                  </span>
                </h3>
              </Grid>
              <Grid
                item
                xs={2}
                lg={2}
                className="cursor-pointer"
                style={{
                  borderBottom:
                    selectedTab === "cancelled" ? "2px solid #F6CA30" : "",
                }}
                onClick={() => {
                  setSelectedTab("cancelled");
                  getAllReservations({ orderType: "cancelled" });
                }}
              >
                <h3
                  className="py-1 font-semibold text-medium mb-1"
                  style={{
                    color: selectedTab === "cancelled" ? "#F6CA30" : "#000000",
                  }}
                >
                  Cancelled
                  <span
                    className="py-1 px-2 rounded-full ms-1"
                    style={{
                      backgroundColor:
                        selectedTab === "cancelled" ? "#F6CA30" : "#F7F8FA",
                      color: selectedTab === "cancelled" ? "white" : "black",
                      border:
                        selectedTab === "cancelled" ? "" : "1px solid #000000",
                    }}
                  >
                    {reservationCounts?.cancelled || 0}
                  </span>
                </h3>
              </Grid>
            </Grid>
            <Divider />
            <Box className="my-3">
              <CustomTableContainer
                columns={columns}
                data={Reservation}
                sortingHandler={sortingHandler}
                sortingDetails={sorting}
                pagination={true}
                paginationDetails={{
                  ...pagination,
                  total_data,
                  pageHandler: pageHandler,
                }}
              />
              {/* <TableContainerComp
                columns={columns}
                rows={AccountData}
                pagination={true}
                hasLink={true}
                navigateURL={"/account-profile"}
              /> */}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default SurfaceOrdersDetails;
