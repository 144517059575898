import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Grid } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import TextInputField from "./Input/TextInput";
import ButtonComponent from "./Button";

export default function ModalComp({
  open,
  toggleHandler,
  title,
  children,
  width,
  padding,
  isDividerNeeded = true,
  modalClass = "",
}) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width || 1000,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: padding || 2,
  };

  return (
    <Modal
      open={open}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiBox-root": {
          border: 0,
          borderRadius: "5px",
          fontFamily: "Inter, sans-serif",
        },
      }}
    >
      <Box sx={style} className={modalClass}>
        <Box className="flex justify-between items-center mb-2">
          <h1
            className="!font-semibold md:text-lg xl:text-xl"
            // sx={{ fontFamily: "Inter, sans-serif" }}
          >
            {title}
          </h1>
          <IoMdClose
            className="w-6 h-6 cursor-pointer"
            onClick={toggleHandler}
          />
        </Box>
        {isDividerNeeded && <Divider />}
        {children}
      </Box>
    </Modal>
  );
}
