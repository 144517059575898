import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/methods";

export const getDashboardDetails = createAsyncThunk(
  "dashboard/getDashboard",
  async (params) => {
    const getData = async () => {
      return GET("/admin/dashboard").then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    dashboardDetails: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardDetails = action.payload;
    });
    builder.addCase(getDashboardDetails.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default dashboardSlice.reducer;
