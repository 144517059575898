import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/auth.slice";
import accountReducer from "./account/account.slice";
import commonReducer from "./common/common.slice";
import dashboardReducer from "./dashboard/dashboard.slice";
import reservationReducer from "./reservation/reservation.slice";
import supplyReducer from "./supply/supply.slice";
import categoriesReducer from "./settings/categories.slice";
import taxCommissionsReducer from "./settings/taxcommission.slice";
import appArticlesReducer from "./settings/apparticle.slice";
import generalInfoReducer from "./settings/generalInfo.slice";
import emailTemplateReducer from "./settings/emailTemplate.slice";
import rulesReportingReducer from "./settings/rulesReporting";
import helpSupportReducer from "./settings/helpsupport.slice";
import surfaceReducer from "./surface/surface.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    common: commonReducer,
    dashboard: dashboardReducer,
    surface: surfaceReducer,
    reservation: reservationReducer,
    supply: supplyReducer,
    settings: combineReducers({
      categories: categoriesReducer,
      taxCommissions: taxCommissionsReducer,
      apparticles: appArticlesReducer,
      generalInfo: generalInfoReducer,
      emailTemplateInfo: emailTemplateReducer,
      rulesAndReportingInfo: rulesReportingReducer,
      helpSupportInfo: helpSupportReducer,
    }),
  },
});
