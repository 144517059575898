import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const loginUser = createAsyncThunk(
  "account/loginUser",
  async (payload) => {
    const postData = async () => {
      return POST("/user/login", payload).then((res) => {
        console.log(res, "res?.data in loginuser");
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);

export const userDetails = createAsyncThunk("account/userDetails", async () => {
  const getData = async () => {
    return GET("/user/details").then((res) => {
      console.log(res?.data, "res?.data");
      return res?.data;
    });
  };
  try {
    return await getData();
  } catch (error) {
    return [];
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    loginUserDetails: {},
    isAuthenticated: false,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      console.log(action.payload?.data?.data, "action.payload");
      state.isLoading = false;
      state.loginUserDetails = action.payload?.data?.data || {};
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loginUserDetails = action.payload;
      state.isAuthenticated = true;
      console.log(state.isAuthenticated, "action.payload");
    });
  },
});

export default authSlice.reducer;
