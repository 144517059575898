import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { get } from "lodash";

// function Label({ componentName, valueType, isProOnly }) {
//   const content = (
//     <span>
//       <strong>{componentName}</strong> for {valueType} editing
//     </span>
//   );

//   if (isProOnly) {
//     return (
//       <Stack direction="row" spacing={0.5} component="span">
//         {content}
//       </Stack>
//     );
//   }

//   return content;
// }

export default function DateInput({
  isShowError = true,
  errorMessage,
  name,
  formik,
  isDisabled = false,
  ...props
}) {
  const { values, touched, errors, setFieldValue, handleBlur } = formik || {};
  console.log(errors, "errors in date input");
  const isError =
    !isDisabled && !!get(touched, name, "") && !!get(errors, name, "");
  return (
    <div>
      <LocalizationProvider className="w-full" dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{ padding: 0, backgroundColor: "#F7F8FA" }}
          className="w-full"
          components={["DatePicker"]}
        >
          <DatePicker
            className="text-medium"
            value={dayjs(values[name] || null)}
            onChange={(e) => setFieldValue(name, e?.$d || "")}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                error: !!errorMessage,
              },
            }}
            disabled={isDisabled}
            format="DD/MM/YYYY"
            // maxDate={new Date()}
            {...props}
          />
        </DemoContainer>
      </LocalizationProvider>
      {isShowError && (isError || !!errorMessage) && (
        <div className="h-2 text-xs text-red-500">
          {get(touched, name, "") ? get(errors, name, "") || errorMessage : ""}
        </div>
      )}
    </div>
  );
}
