import { Box, Card, Grid, InputAdornment, IconButton } from "@mui/material";
import React from "react";
import SwitchComp from "../common/SwitchComp";
import SupplyWiseComp from "./SupplyWiseComp";
import BusinessWiseComp from "./BusinessWiseComp";

const Supply = () => {
  const [filter, setFilter] = React.useState("supplyWise");
  const changeHandler = (event) => {
    setFilter(event.target.checked ? "businessWise" : "supplyWise");
  };
  return (
    <>
      {/* <BreadCrumb title="Supply Management" /> */}
      <Box className="flex ms-2">
        <h1 className="text-2xl font-bold text-start me-3">
          Supply Managements
        </h1>
        <Box className="flex justify-center items-center">
          <p
            className={`${
              filter === "supplyWise" ? "font-semibold" : "text-zinc-500"
            }`}
          >
            Supply wise
          </p>
          <SwitchComp
            checked={filter === "supplyWise" ? false : true}
            changeHandler={changeHandler}
          />
          <p
            className={`${
              filter === "businessWise" ? "font-semibold" : "text-zinc-500"
            }`}
          >
            Business wise
          </p>
        </Box>
      </Box>
      {filter === "supplyWise" && <SupplyWiseComp />}
      {filter === "businessWise" && <BusinessWiseComp />}
    </>
  );
};

export default Supply;
