import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getReservations = createAsyncThunk(
  "account/getReservations",
  async (params) => {
    const getData = async () => {
      return GET("/admin/reservation", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getReservationsCount = createAsyncThunk(
  "account/getReservationsCount",
  async (params) => {
    const getData = async () => {
      return GET("/admin/reservationCounts", { params }).then((res) => {
        console.log(res, "res abc");
        if (res.success) {
          return res.counts;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getReservationDetails = createAsyncThunk(
  "account/getReservationDetails",
  async (params) => {
    const getData = async () => {
      return GET(`/surfacerequest/get/${params.id}`).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    isLoading: false,
    reservations: {},
    reservationDetails: {},
    reservationCounts: {},
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReservations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReservations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reservations = action.payload;
    });
    builder.addCase(getReservations.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getReservationsCount.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload.counts");
      state.isLoading = false;
      state.reservationCounts = action.payload;
    });
    builder.addCase(getReservationDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReservationDetails.fulfilled, (state, action) => {
      state.reservationDetails = action.payload;
    });
    builder.addCase(getReservationDetails.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default reservationSlice.reducer;
