import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TbMailPlus } from "react-icons/tb";
import TextInputField from "../../common/Input/TextInput";
import RadioInput from "../../common/Input/RadioInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonComponent from "../../common/Button";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPlusCircle } from "react-icons/ai";
import { GoDotFill, GoPencil } from "react-icons/go";
import { ReactComponent as PaperCup } from "../../../assets/images/PaperCups.svg";
import DeleteTemplateConfirmationModal from "../DeleteTemplateConfirmationModal";
import { IoChevronBack } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import AddFAQModal from "./AddFAQ";
import {
  deleteFAQ,
  getHelpSupport,
} from "../../../store/settings/helpsupport.slice";
import DeleteFAQConfirmation from "./DeleteFAQConfirmation";
import { toast } from "react-toastify";

const FAQpage = ({ tabChangeHandler }) => {
  const dispatch = useDispatch();
  const [activeComponent, setActiveComponent] = useState("General");
  const [searchState, setSearchState] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState({
    sortType: "asc",
    sortBy: "createdAt",
  });
  const [addFAQModal, setAddFAQModal] = useState({
    showModal: false,
    data: undefined,
  });
  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    showModal: false,
    id: undefined,
  });
  const { helpSupportTickets } = useSelector(
    (state) => state.settings.helpSupportInfo
  );

  const getHelpSupportTickets = () => {
    const params = {
      faqType: activeComponent,
    };
    dispatch(getHelpSupport(params));
  };

  const deleteHandler = () => {
    dispatch(deleteFAQ({ id: removeConfirmationModal.id })).then((resp) => {
      if (resp.payload?.success) {
        toast.success("FAQ deleted successfully!");
        setRemoveConfirmationModal({
          showModal: false,
          data: undefined,
        });
        dispatch(getHelpSupport({ faqType: activeComponent }));
      }
    });
  };

  useEffect(() => {
    getHelpSupportTickets();
  }, [activeComponent]);

  return (
    <>
      <Box className="mt-3">
        <Box className="my-4">
          <h1 className="text-2xl font-semibold text-start flex items-center">
            <IoChevronBack
              className="me-3 cursor-pointer"
              onClick={() => tabChangeHandler("SupportTickets")}
            />
            Help & Support
          </h1>
        </Box>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <Box
              className="p-4 text-start bg-white rounded-lg"
              style={{ height: "100%" }}
            >
              <h1 className="md:text-lg xl:text-xl font-semibold mb-3">
                FAQ Editor
              </h1>
              <Box>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "General" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("General")}
                >
                  General
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "Seeker" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("Seeker")}
                >
                  Seeker
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "Sharer" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("Sharer")}
                >
                  Sharer
                </h3>
                <h3
                  className="md:text-lg xl:text-xl p-2 rounded-lg flex items-center justify-between cursor-pointer"
                  style={{
                    backgroundColor:
                      activeComponent === "Supplier" ? "#F9DD7B" : "white",
                  }}
                  onClick={() => setActiveComponent("Supplier")}
                >
                  Supplier
                </h3>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box className="p-3 text-start bg-white rounded-lg app-article-wraper">
              <Box className="flex justify-between py-2">
                <h1 className="md:text-lg xl:text-xl font-semibold">
                  {activeComponent}
                </h1>
                <ButtonComponent
                  btnText="Create"
                  btnClass="!text-medium"
                  style={{
                    padding: "7px 20px",
                    backgroundColor: "#263238",
                    "&:hover": { backgroundColor: "#263238" },
                  }}
                  onClick={() => {
                    setAddFAQModal((prev) => ({ ...prev, showModal: true }));
                    // setTab("FAQEditor");
                  }}
                />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  {helpSupportTickets?.map((help, index) => {
                    return (
                      <Grid item md={12} xl={6} key={index}>
                        <Box
                          className="flex justify-between p-4 rounded-lg"
                          style={{ backgroundColor: "#F7F8FA" }}
                        >
                          <Box className="flex items-center">
                            {/* <span>
                              <img
                                src={
                                  article?.articleImage ||
                                  "/images/ArticlePic.png"
                                }
                                alt=""
                                className="app-article-img"
                              />
                            </span> */}
                            <Box className="ms-3 flex-1 text-medium flex-wrap">
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  <p>Order</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <p>{help?.order}</p>
                                </Grid>
                                <Grid item xs={4}>
                                  <p>Topic</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <p className="flex items-center">
                                    {help?.topic}
                                  </p>
                                </Grid>
                                <Grid item xs={4}>
                                  <p>Description</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: help?.description,
                                    }}
                                  ></p>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                          <Box className="flex">
                            <span className="me-2">
                              <Box className="p-1 bg-black rounded-lg cursor-pointer">
                                <GoPencil
                                  color="white"
                                  className="md:w-5 md:h-5 xl:w-6 xl:h-6"
                                  onClick={() =>
                                    setAddFAQModal((prev) => ({
                                      ...prev,
                                      showModal: true,
                                      data: help,
                                    }))
                                  }
                                />
                              </Box>
                            </span>
                            <span>
                              <Box className="p-1 bg-black rounded-lg cursor-pointer">
                                <RiDeleteBin6Line
                                  color="white"
                                  className="md:w-5 md:h-5 xl:w-6 xl:h-6"
                                  onClick={() =>
                                    setRemoveConfirmationModal((prev) => ({
                                      ...prev,
                                      showModal: true,
                                      id: help?._id,
                                    }))
                                  }
                                />
                              </Box>
                            </span>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AddFAQModal
        width={700}
        title={`${activeComponent} FAQ ${addFAQModal?.data ? "Edit" : "New"}`}
        activeComponent={activeComponent}
        open={addFAQModal.showModal}
        data={addFAQModal.data}
        toggleHandler={() => {
          setAddFAQModal((prev) => ({
            showModal: !prev?.showModal,
            id: undefined,
          }));
        }}
      />
      <DeleteFAQConfirmation
        open={removeConfirmationModal?.showModal}
        submitHandler={deleteHandler}
        toggleHandler={() => {
          setRemoveConfirmationModal((prev) => ({
            ...prev,
            showModal: false,
            data: undefined,
          }));
        }}
      />
    </>
  );
};

export default FAQpage;
