const { getDocs, collection } = require("firebase/firestore");
const { db } = require("./firebaseConfig");

exports.getSeekerDetails = (data) => {
  const finalOutput = {
    currentSeeker: {},
    listOfSeeker: [],
  };
  if (data?.currentSeeker?.length > 0) {
    finalOutput.currentSeeker = data?.currentSeeker[0];
    finalOutput.listOfSeeker = data?.previousSeeker;
    return finalOutput;
  } else {
    if (data?.previousSeeker?.length > 0) {
      finalOutput.currentSeeker = data?.previousSeeker[0];
      finalOutput.listOfSeeker = data?.previousSeeker?.slice(1) || [];
    }
    return finalOutput;
  }
};

exports.convertNumber = (val = 0) => {
  // Thousands, millions, billions etc..
  let s = ["", "k", "m", "b", "t"];

  // Dividing the value by 3.
  let sNum = Math.floor(("" + val).length / 3);

  // Calculating the precised value.
  let sVal = parseFloat(
    (sNum != 0 ? val / Math.pow(1000, sNum) : val).toPrecision(2)
  );

  if (sVal % 1 != 0) {
    sVal = sVal.toFixed(2);
  }

  // Appending the letter to precised val.
  return sVal + " " + s[sNum];
};
const hashCode = (str) => {
  var hash = 0;
  let i, chr;
  if (str?.length === 0) return hash;
  for (i = 0; i < str?.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

exports.getListener = (user1, user2) => {
  return hashCode(user1) >= hashCode(user2)
    ? `${user2}-${user1}`
    : `${user1}-${user2}`;
};
