import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getSurfaces = createAsyncThunk(
  "surfaces/getSurfaces",
  async (params) => {
    const getData = async () => {
      return GET("/admin/surface/list", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const downloadSurfaces = createAsyncThunk(
  "surfaces/downloadSurfaces",
  async (params) => {
    const getData = async () => {
      return GET("/admin/surface/listCSV", { params }).then((res) => {
        console.log(res, "Response");
        return res;
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getSurfacesBusinessWise = createAsyncThunk(
  "surfaces/getSurfacesBusinessWise",
  async (params) => {
    const getData = async () => {
      return GET("/business/all", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const getSurfaceDetails = createAsyncThunk(
  "account/accountDashboardDetails",
  async (params) => {
    const getData = async () => {
      return GET(`/admin/surface/details/${params.id}`).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

const surfaceSlice = createSlice({
  name: "surfaces",
  initialState: {
    isLoading: false,
    surfaces: {},
    surfaceDetails: {},
    businesses: {},
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurfaces.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSurfaces.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");
      state.isLoading = false;
      state.surfaces = action.payload;
    });
    builder.addCase(getSurfaces.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(getSurfacesBusinessWise.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSurfacesBusinessWise.fulfilled, (state, action) => {
      state.isLoading = false;
      state.businesses = action.payload;
    });
    builder.addCase(getSurfacesBusinessWise.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(getSurfaceDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSurfaceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.surfaceDetails = action.payload;
    });
    builder.addCase(getSurfaceDetails.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default surfaceSlice.reducer;
