import React from "react";
import ModalComp from "../common/ModalComp";
import ButtonComponent from "../common/Button";
import { Box } from "@mui/material";

const ViewDocumentModal = ({ open, toggleHandler, data, submitHandler }) => {
  return (
    <ModalComp
      open={open}
      width={500}
      isDividerNeeded={false}
      toggleHandler={toggleHandler}
    >
      <Box className="flex flex-col items-center justify-center">
        <img
          src={data?.url}
          alt=""
          style={{ width: "400px", height: "400px" }}
        />
        <Box className="mt-3">
          <ButtonComponent
            btnText="Verify"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid black",
              borderRadius: "10px",
              color: "black",
              "&:hover": { backgroundColor: "#FFFFFF" },
              boxShadow: "none",
              width: "9rem",
            }}
            onClick={() => submitHandler("accept", data?._id)}
          />
          <ButtonComponent
            btnText="Reject"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid black",
              borderRadius: "10px",
              color: "black",
              "&:hover": { backgroundColor: "#FFFFFF" },
              boxShadow: "none",
              width: "9rem",
            }}
            onClick={() => submitHandler("reject", data?._id)}
          />
        </Box>
      </Box>
    </ModalComp>
  );
};

export default ViewDocumentModal;
