import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/methods";

export const getAccounts = createAsyncThunk(
  "account/getAccounts",
  async (params) => {
    const getData = async () => {
      return GET("/user/all", { params }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);
export const addAccount = createAsyncThunk(
  "account/addAccount",
  async (payload) => {
    const postData = async () => {
      return POST("/user/create", payload).then((res) => {
        return res;
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
export const getAccountDashboardDetails = createAsyncThunk(
  "account/accountDashboardDetails",
  async (params) => {
    const getData = async () => {
      return GET(`/user/details/${params.id}`).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      });
    };
    try {
      return await getData();
    } catch (error) {
      return [];
    }
  }
);

export const approveRejectDocument = createAsyncThunk(
  "account/approveRejectDocument",
  async (data) => {
    const postData = async () => {
      return POST(
        `/user/business/document/${data?.params?.status}`,
        data?.payload
      ).then((res) => {
        if (res.success) {
          return res;
        } else {
          return [];
        }
      });
    };
    try {
      return await postData();
    } catch (error) {
      return [];
    }
  }
);
const accountSlice = createSlice({
  name: "account",
  initialState: {
    isLoading: false,
    accounts: [],
    accountDetails: {},
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accounts = action.payload;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(getAccountDashboardDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccountDashboardDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accountDetails = action.payload;
    });
    builder.addCase(getAccountDashboardDetails.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default accountSlice.reducer;
